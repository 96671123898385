.single-promo {
  background-color: $grey-lighter;
  margin: 0 auto;
  position: relative;

  .single-promo__container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: $max-width;

    .single-promo__image {
      display: flex;
      width: 50%;
      height: calc(1000px / 2);
      position: relative;
      overflow: hidden;
      align-content: center;

      .single-promo__image--updatable {
        height: 100%;
        object-fit: cover;
        display: block;
        width: 100%;
        &.fit-bottom {
          object-position: bottom;
        }
        &.fit-top {
          object-position: top;
        }
      }
    }

    .single-promo__cta-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .single-promo__cta-inner-container {
        margin-left: $base-margin*2;
        width: calc(100% - 48px);

        .single-promo__text {
          font-size: 34px;
          font-weight: 200;
          padding-bottom: $base-padding;
        }

        .single-promo__text--bold {
          font-weight: 700;
          padding-bottom: $base-padding*2;
        }

        a {
          .single-promo__cta-button {
            max-width: 75%;
            background-color: $black;
            box-shadow: none;
            color: $white;
            font-weight: 400;
          }
        }
      }
    }
  }
  .single-promo__absolute-image {
    position: absolute;
    bottom: 0;
    right: 1%;
    width: 150px;
    height: auto;
  }
}

@media only screen and (max-width: 960px) {
  .single-promo {
    .single-promo__container {
      .single-promo__cta-container {
        .single-promo__cta-inner-container {
          .single-promo__text {
            font-size: 30px;
          }
        }
      }
    }
    .single-promo__absolute-image {
      width: 120px;
    }
  }
}

@media only screen and (max-width: 824px) {
  .single-promo {
    .single-promo__container {
      .single-promo__cta-container {
        .single-promo__cta-inner-container {
          .single-promo__text {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $viewport-s) {
  .single-promo {
    .single-promo__container {
      flex-direction: column-reverse;
      padding-bottom: $base-padding*2;

      .single-promo__image {
        width: calc(100% - 48px);
        height: calc(1000px / 3);
        background-position: bottom;
        margin-left: auto;
        margin-right: auto;
      }

      .single-promo__cta-container {
        margin: $base-margin auto;
        width: 100%;

        .single-promo__cta-inner-container {
          margin-top: 0;
          width: calc(100vw - 48px);

          .single-promo__text {
            font-size: 24px;
          }

          .ssingle-promo__text--bold {
            padding-bottom: $base-padding;
          }

          a {
            .single-promo__cta-button {
              max-width: 100%;
            }
          }
        }
      }
    }

    .single-promo__absolute-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 480px) {
  .single-promo {
    .single-promo__container {
      .single-promo__image {
        height: calc(1000px / 4);
      }
    }
  }
}
