.contact-form {
  margin-top: $base-margin*2;
  padding: $base-margin*2;

  .form-control {
    position: relative;
    min-height: 60px;
    margin-bottom: $base-margin * 0.25;
  }

  .form-header { padding-bottom: $base-margin; }
  h6 { font-weight: bold; }

  input:not([type=checkbox]):not([type=submit]):not([type=radio]),
  textarea {
    height: $base-margin*4;
    padding: $base-margin*0.5 $base-margin;
    border: none;
    background: #EEE;
    border-radius: 6px;
    &::placeholder {
      color: black;
    }
    &:focus ~ label,
    &:valid ~ label {
      top: $base-margin*0.25;
      @include fonts(normal, 11px);
    }

    &:focus ~ .form-bar:before,
    &:focus ~ .form-bar:after {
      width: 50%;
    }
  }

  textarea {
    min-height: 22px*5 + $base-margin*0.5;
    resize: vertical;
    margin-bottom: $base-margin - 3px;
  }

  label{ display: block; font-weight: bold; padding-bottom: $base-margin*0.5; }

  // To style file uploads, we hide the inner button, re-used the input text and
  // provide a custom button next to it wrapper in a <label>
  label.file-control{
    display: flex;
    input[type="file"] {
      height: initial !important;
      line-height: 32px;
      &::file-selector-button{ display:none; }
    }
    .button{ width:180px; flex-shrink:0; margin: 0 0 3px $base-margin; }
  }

  .form-bar {
    display: block;
    width: 100%;

    &:before,
    &:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: $primary;
      transition: .2s ease all;
      -moz-transition: .2s ease all;
      -webkit-transition: .2s ease all;
    }

    &:before { left: 50%; }
    &:after { right: 50%; }
  }


  button[type=submit]{ font-weight: bold; }
}
