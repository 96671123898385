// Deck Navigation arrows for mobile
.deck-left, .deck-right{
  position: absolute;
  background: $action;
  color: white;
  width: 40px;
  height: 40px;
  text-align:center;
  z-index: 1;
  transition: opacity 0.3s ease;
  touch-action: manipulation;
  cursor: pointer;
  bottom: 180px;
  @media (min-width: 414px){
    bottom: 210px;
  }

  &:before{
    font: 600 20px / 40px 'icomoonbss';
  }
  @media screen and (min-width: $viewport-s + 1){
    display: none;
  }
  &.hidden{
    visibility: unset;
    opacity: 0;
  }
  &:active{
    outline:none;
  }
}

.deck-left{
  @extend .bss-icon-arrow-left;
  border-radius: 0 5px 5px 0;
  left: 0;
}
.deck-right{
  @extend .bss-icon-arrow-right;
  border-radius: 5px 0 0 5px;
  right: 0;
}
