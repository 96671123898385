.detail-header {
  width: 100%;
  padding: $base-padding * 2 $base-padding * 2 0;
  .price {
    font-size: $base-font-size+2;
    margin-top: -($base-margin)+2;
    margin-bottom: $base-margin;
  }
}

.bss .detail-header .title {
  width: calc(100% - 135px); // allow for three share icons
}

.tributes .detail-header .title {
  width: calc(100% - 103px); // allow for two share icons
}

.listing-detail-icons > span {
  margin-right: $base-margin;
}

.listing-detail-icons {
  font-size: $base-font-size - 2;
}

.listing {
  background: $background-secondary;
  margin-bottom: $base-margin;
  border-radius: $base-radius;
  @include drop-shadow();
  margin-top: 0;
  .listing-description, .rspca-help {
    padding: 0 $base-padding*2 $base-padding*2 $base-padding*2;
    p {
      margin-top: $base-margin;
    }

    strong {
      font-family: $font-bold;
    }

    a {
      color: $secondary;
      &:hover {
        color: darken($secondary, 10%);
      }
    }
  }

  .rspca-help {
    padding-top: 0;
  }

  .listing-description {
    .buttoncolumn {
      a {
          color: $white;
      }
    }
  }
}

// RELATED CONTENT GLIDER

.related__card-container {
  text-decoration: none;
  color: inherit;
  height: 100%;
  max-width: 318px;
}

.related__card {
  background-color: $white;
  overflow-x: hidden;
  transition: all .3s;
  backface-visibility: hidden;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  border-radius: $base-radius;
  &.obituary-card {
    box-shadow: 0 1px 6px rgba(0,0,0,.1);
  }
}

.related__card:hover {
  //transform: translateY(-5px);
  box-shadow: 0 3px 12px rgba(0,0,0,.2);
  cursor: pointer;
}

.related__card:active {
  animation-name: cardClick;
  animation-delay: 0;
  animation-direction: forward;
  animation-duration: .1s;
}

@keyframes cardClick {
  0%  {
    transform: translateY(0px);
    box-shadow: 0 3px 12px rgba(0,0,0,0.2);
  }
  50% {
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transform: translateY(2px);
  }
  100%  {
    transform: translateY(-5px);
    box-shadow: 0 3px 12px rgba(0,0,0,0.2);
  }
}

.related__card-content {
  //display: flex;
  //flex-direction: column;
  width: 100%;
  //height: 40%;
  justify-content: center;
  .location {
    position: relative;
    @include fonts(normal, ($base-font-size)-4);
    padding: 0 $base-padding $base-padding*0.5 $base-padding;
    margin-top: -5px;
    margin-left: $base-margin + 4;
    .bss-icon-location {
      position: absolute;
      top: -2px;
      left: -8px;
      font-size: ($base-font-size)+2;
    }
  }
  .description-text {
    padding: 0 $base-padding;
    margin-bottom: $base-padding;
    font-size: ($base-font-size)-2;
    overflow: hidden;
    display: none;
  }
  .obituary-text {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-top: $base-margin;
    visibility: visible;
    word-wrap:break-word;
    min-height: 91px;
  }
  .contact-bar {
    overflow: hidden;
    padding: $base-margin*0.5;
    margin-top: $base-margin*0.5;
    font-size: ($base-font-size)-4;
    width: 100%;

    * {
      color: $secondary;
    }

    .button {
      width: calc(50% - #{$base-margin*0.5});
      float: left;
      font-size: ($base-font-size)-2;
      background: rgba($secondary, .1);
      border-radius: $base-radius;
      padding: $base-padding*0.5;
      margin-right: $base-margin;
      @include transition;
      @include hover($important: false);
      line-height: 28px;
      i {
        transform: scale(1.3);
        font-size: ($base-font-size)+2;
        padding-left: $base-padding*0.5;
        top: 2px;
        &.bss-icon-email {
          top: 3px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.only-child,
      &:only-child.email {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.related__card-title {
  padding-top: $base-padding;
  padding-right: $base-padding;
  padding-left: $base-padding;
  margin-top: 0;
  margin-bottom: $base-padding;
  font-size: $base-font-size;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  min-height: 53px;
}

.obituary-card .related__card-title {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: initial;
}

.related__card-price {
  padding-bottom: $base-padding*2;
  font-size: $base-font-size - 2px;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: $base-padding;
  padding-left: $base-padding;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.glider__cards-grid {
  width: 100%;
  // Is the height of the mrec plus the padding on top and bottom
  height: 648px;
  margin-right: $base-margin*2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.related__card-desktop {
  width: 100%;
}

.glider__mrec{
  height: 648px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: $fixed-width + (2*$base-padding);
  width: $fixed-width + (2*$base-padding);

  #adSpace1 {
    margin-bottom: 0;
  }
}

.glider__container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pane-mytributes_featured {
  background-color: $grey-lightest;
  .glider__container-header {
    top: 0;
    position: relative;
    padding: $base-padding 0;
  }
}

.glider__view-all {
  margin-right: $base-margin;
  font-size: $base-font-size;
  border-bottom: 1px solid $grey-light;
  padding-bottom: 4px;
  top: 2px;
  display: block;
  text-align: right;
}

.glider__container-title {
  font-weight: 600;
  padding-left: $base-padding*2;
}

button.glider__controller {
  width: $base-margin*3;
  height: $base-margin*3;
  padding: 0;
  background-color: $pink;
  &.obituary-control {
    background-color: $primary;
  }
}

.glider__controller-icon {
  padding-right: 0;
  font-weight: 700;
  font-size: $base-font-size;
  color: $white;
}

.glider__content-wrapper {
  display: flex;
  max-width: $max-width + ($base-margin*2);
  margin-left: auto;
  margin-right: auto;
  padding: $base-padding*4 $base-padding;
}

.related__image-container {
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: $base-radius;
  border-top-right-radius: $base-radius;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .related__background-image {
    background-size: auto 150%;
    background-position: center;
    display: block;
    opacity: .9;
    overflow: hidden;
    width: 100%;
    height: 100%;
    filter: blur(20px);
    z-index: 1;
    position: absolute;
    left: -40px;
    right: -40px;
    top: -40px;
    bottom: -40px;
    width: calc(100% + 80px);
    height: calc(100% + 80px);
    pointer-events: none;
    &.show {
      display: block!important;
    }
  }

  .related__image {
    width: 100%;
    height: 100%;
    position: absolute;
    a {
      z-index: 2;
      img {
        object-fit: contain;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 120%;
        pointer-events: none;
      }
    }

    .related__classification {
      position: absolute;
      top: 0;
      left: 0;
      padding: $base-padding*0.5 $base-padding;
      background: rgba($grey-dark,.8);
      border-right: 1px solid $grey-dark;
      border-bottom: 1px solid $grey-dark;
      border-bottom-right-radius: $base-radius;
      z-index: 9;
      font-size: $base-font-size - 4;
      font-weight: 500;
      color: $white;
      @include box-shadow;
      pointer-events: none;
      max-width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.obituary-image .related__image img {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}

.listing-details {
  margin-bottom: $base-margin;
  .map {
    margin-top: $base-margin;
    .full-address, .map-address {
      @include fonts(normal, ($base-font-size)-2);
      line-height: $base-margin*1.5;
      margin-bottom: $base-margin*0.5;
    }
    .ad-text-title {
      margin-left: $base-margin;
      line-height: $base-margin*1.5;
    }
    .map-wrapper {
      padding-bottom: ($base-margin*2);
      aside {
        background-color: $background-secondary;
        border-bottom-left-radius: $base-radius;
        border-bottom-right-radius: $base-radius;
        @include drop-shadow();
        height: $fixed-width;
        div {
          height: 100%;
          width: 100%;
        }
      }
      iframe {
        border-radius: $base-radius;
        overflow: hidden;
      }
      .ad-text-title,
      .ad-text-title * {
        margin-top: $base-margin;
        font-size: ($base-font-size)-4;
        line-height: 1.3;
      }
      .inspection-times {
        margin-top: $base-margin;
        h4 {
          margin-bottom: $base-margin;
        }
        .inspection-field {
          text-align: justify;
          margin: $base-margin*0.5 0;
        }
        a {
          color: $primary;
          font-size: ($base-font-size)-4;
        }
      }
    }
  }
  .map:not(.detailMap) {
    display: inline-block;
    width: 100%;
    @include drop-shadow();
    .map-wrapper aside {
      margin-bottom: 0;
    }
    .inspection-times {
      border-bottom: 1px solid $grey-light;
      padding: $base-padding;
      h4 {
        font-size: $base-font-size+2;
        border-bottom: 1px solid $grey-light;
        line-height: $base-margin*2;
        font-family: $font-bold;
        font-weight: bold;
        margin-bottom: $base-margin;
      }
      .inspection-field {
        font-size: $base-font-size - 2;
        margin-bottom: $base-margin;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          font-size: $base-font-size - 2;
          color: $secondary-dark;
        }
      }
    }
    .ad-text-title {
      padding: $base-padding;
    }
    .ad-text-title * {
      font-size: $base-font-size - 2;
      line-height: $base-margin*1.5;
    }
  }
  .glider__content-wrapper {
    padding: $base-padding 0;
    .glider-toggle {
      padding-right: 0;
      .flex-left {
         .related__card-container {
           width: calc(25% - #{$base-margin*0.5});
           height: auto;
           margin-right: 8px;
           &:last-of-type {
            margin-right: $base-margin*2;
          }
        }
      }
    }
  }
  .rspca-concerns {
    margin-bottom: $base-margin;
  }
}

.contents {
  width: 50%;
  text-align: right;
}

.listing-date {
  margin-bottom: $base-margin;
}

.real-estate-title {
  font-size: ($base-font-size)-2;
  font-family: $font-bold;
  font-weight: bold;
}

.share-tools {
  margin-left: $base-margin*0.5;
  a {
    margin-right: 0; /* Counter excessive spacing on WL */
    font-size: $base-font-size - 2;
    padding: 0px !important;
    span {
      color: $grey-light;
      transition: color .1s ease-out;
      font-size: $base-font-size - 2;
      * {
        color: $grey-light;
      }
    }

    span[class^="bss-icon-"] {
      font-size: $base-font-size + 12;
    }

    .bss-icon-facebook:hover {
      color: $facebook;
    }

    .bss-icon-twitter:hover {
      color: $twitter;
    }

    .report:hover {
      * {
        color: $red;
      }
      color: $red;
    }
  }
}

.advertiser-details {
  .contact-details {
    background: rgba($primary, .2);
    padding: $base-padding*2;
    margin-bottom: $base-margin;
    border-radius: $base-radius;

    h6 {
      font-size: $base-font-size+2;
      font-family: $font-bold;
      font-weight: bold;
      line-height: $base-margin*1.5;
      margin-bottom: $base-margin;
    }

    a.enquire, a.manage-contact-details {
      font-size: $base-font-size - 2;
      line-height: 24px; // Icon line height
      padding: ($base-padding - 2) $base-padding;
      i.larger{vertical-align:bottom;}
    }

    a.manage-contact-details {
      margin-bottom: 0;

      span {
        font-size: $base-font-size - 2;
        transform: unset;
        margin: 0;
        padding: 0;
        top: 0;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }

    .ad-contact {
      background: rgba($white, .5);
      height: auto;
      padding: $base-padding;
      margin-top: $base-margin;

      h5 {
        margin-bottom: $base-margin;
      }

      .contact-name {
        font-size: $base-font-size+2;
        line-height: $base-margin*2;
      }

      .website {
        @include dont-break-text();
        display: inline-block;
        width: calc(100% - 21px);
        margin-bottom: 0;
      }
      p:last-of-type {
        margin-bottom: 0;
        .bss-icon-earth {
         top: -3px;
        }
      }
    }
    .advertiser-logo {
      padding: 0;
      margin-top: $base-margin;
      margin-bottom: 0;
    }
  }
}

.listing-details {

  .price {
    color: $secondary;
    font-size: $base-font-size*1.5;
    line-height: $base-margin*2;
    margin-top: 0;
  }

  .listing-date {
    top: 4px;
  }

  .listing-detail-icons.real-estate * {
    font-size: $base-font-size - 2;
  }

  .galleryDisplay, .center-align {
    margin-bottom: $base-margin;
  }

  .center-align img {
    margin: 0;
    border-radius: $base-radius;
  }

  .listing {

    .hiddenPhone {
      font-weight: bold;
      white-space: nowrap;
      user-select: all;
    }

    .listing-description {
      margin: 0;
      display: inline-block;
      width: 100%;
    }

    .real-estate-title {
      font-size: $base-font-size;
    }
  }
}

@media screen and (max-width: $viewport-m) {
  .detail-widget.md-50 {
    width: 100%;
  }
  .listing-details {
    .map {
      .map-wrapper {
        aside {
          margin-bottom: 0;
        }
      }
    }
  }
}

////////////
// MOBILE //
////////////

@media screen and (max-width: $viewport-s) {
  .listing {
    margin-top: $base-margin*2;
    .listing-description, .rspca-help {
      padding: $base-margin;
    }
    .detail-header {
      padding: $base-margin $base-margin 0 $base-margin;
    }
  }
  .bss .detail-header .title,
  .tributes .detail-header .title  {
    width: 100%;
    }
  .share-tools {
   margin-left: 0;
  }
  .report {
   margin-right: $base-margin*0.5;
  }
  .real-estate .responsive-text {
   display: none;
  }
  .listing-details {
    flex-flow: row wrap;
    display: flex;
    margin-bottom: $base-margin;
    .listing-detail-icons {
      margin-top: $base-margin*0.5;
      font-size: $base-font-size - 3;
      > span {
      margin-right: $base-margin*0.5;
      &:last-of-type {
        margin-right: 0;
      }
        span {
          padding-right: 0;
        }
      }
    }

    .listing{
      margin-bottom: $base-margin * 2;
    }

    .center-align img {
      max-height: 300px;
    }
    .map {
      .map-wrapper {
        aside {
          margin-bottom: 0;
        }
      }
    }
    .glider__content-wrapper {
      width: calc(100% + #{$base-margin*4});
      padding: $base-padding 0;
      margin-left: -$base-margin*2;
    }
  }
}

////////////
// MOBILE //
////////////

@media screen and (max-width: $viewport-s) {
  .listing.desktop-bg {
   background: none;
   border: 0;
  }
  .glider {
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
  }
  .glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
  }
  .glider.draggable {
    user-select: none;
    cursor: grab!important;
  }
  .glider.draggable .glider-slide img {
    user-select: none;
    pointer-events: none;
  }
  .glider.drag {
    cursor: grabbing!important;
  }
  .glider__content-wrapper {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
    padding-top: $base-padding*2;
    margin-top: 0;
    .related__image {
      img {
        min-width: 120%;
      }
    }
  }
  .related__card-title {
    -webkit-line-clamp: 3;
    min-height: 72px;
  }
  .glider-toggle {
    margin-bottom: $base-margin;
  }
  .glider__view-all {
    margin-right: $base-margin*2;
  }
  .glider-desktop {
    display: flex;
    z-index: 1;
    width: 100%;
    height: auto;
    padding-bottom: $base-padding;
  }
  .glider__container-title {
    padding-left: $base-padding*2;
    top: -($base-margin*0.5);
  }
  .related__card-container:first-child {
    margin-left: $base-margin*2;
  }
  .related__card-container:last-child {
    margin-right: $base-margin*2;
  }
  .related__card-container {
    margin: $base-margin * 0.5;
    height: auto;
    width: 32%;
  }
  .glider__view-all {
    font-size: $base-font-size - 2px;
  }
  .related__card-content {
    .location {
      padding: 0 $base-padding $base-padding $base-padding;
    }
  }
}

/////////////
// Desktop //
/////////////

@media screen and (min-width: $viewport-s+1) {
  div.glider-contain {
    &.desktop-glider {
      overflow: hidden;
     .glider {
       margin: 0 auto;
       position: relative;
       overflow-y: hidden;
       -webkit-overflow-scrolling: touch;
       -ms-overflow-style: none;
       transform: translateZ(0);
     }
    .glider-prev {
       border-top-left-radius: 0;
       border-bottom-left-radius: 0;
     }
    .glider-next {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
     .glider-track {
       transform: translateZ(0);
       width: 100%;
       margin: 0;
       padding: 0;
       display: flex;
       z-index: 1;
     }
     .glider.draggable {
       user-select: none;
       cursor: grab!important;
     }
     .glider.draggable .glider-slide img {
       user-select: none;
       user-drag: none;
       pointer-events: none;
     }
     .glider.drag {
       cursor: grabbing!important;
     }
     .glider__content-wrapper {
       flex-direction: column-reverse;
       padding-left: 0;
       padding-right: 0;
       padding-top: $base-padding*2;
       margin-top: 0;
       .related__image {
         img {
           min-width: 120%;
         }
         &.obituary-image img {
           min-width: 100%;
         }
       }
     }
     .related__card-title {
       -webkit-line-clamp: 3;
       min-height: 72px;
     }
     .obituary-card .related__card-title {
       display: block;
       min-height: initial;
     }
     /* Swap the order of Recent Notices and Obituaries panels */
     .pane-myt_obituaries .glider__content-wrapper {
       flex-direction: column-reverse;
       margin-top: 0;
       .recent-notices {
         margin-bottom: $base-margin * 5;
       }
     }
     .glider-toggle {
       margin-bottom: $base-margin;
     }
     .glider__view-all {
       margin-right: $base-margin*2;
     }
     .glider-desktop {
       display: flex;
       z-index: 1;
       width: 100%;
       height: auto;
       padding-bottom: $base-padding;
     }
     .glider__container-title {
       padding-left: 0;
       margin-left: $base-margin*2;
       font-size: $base-font-size + 8;
     }
     .related__card-container:first-child {
       margin-left: $base-margin*2;
     }
     .related__card-container:last-child {
       margin-right: $base-margin*2;
     }
     .related__card-container {
       margin: $base-margin * 0.5;
       height: auto;
       width: 32%;
     }
     .glider__view-all {
       font-size: $base-font-size - 2px;
     }
     .related__card-content {
       .location {
         padding: 0 $base-padding $base-padding $base-padding;
       }
     }
    }
    &:not(.desktop-glider) {
      .glider {
        overflow-y: visible;
      }
      .glider-desktop {
        height: calc(100% - 99px);
      }

      .related__card-price {
        padding-bottom: $base-padding*2;
      }

      button.glider__controller {
        display: none;
      }

      .glider-desktop {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        -webkit-box-pack: justify;
      }

      .related__card {
        min-height: 359px;
      }

      .related__card-container{
        margin-bottom: 0;
        width: calc(33% - 6px);
        // height is half mrec - $base-margin * 0.5 which is used for spacing
        height: calc(50% - 6px);
        margin-top: $base-margin;
        .related__image-container {
          height: 50%;
        }
      }
      .glider-toggle {
        //height: 100%;
        display: flex;
        justify-content: center;
        padding-right: $base-padding;
      }

      .glider__container-title {
        padding-left: 0;
      }

      .recent-notices{
        min-width: $fixed-width + (2*$base-padding);
        width: $fixed-width + (2*$base-padding);
      }

      .glider-dots {
        display: none;
      }
    }
  }
}

