.archive.content-wrapper {
  margin-bottom: $base-margin*4;
  padding: 0 $base-margin*2;

  .secodary-color {
    color: $secondary;
  }

  .headline-list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $base-padding*2;
  }

  .brand-header {
    max-width: $max-width;
    margin: auto;
  }

  .polaroid:hover {
    opacity: .8;
  }

  .imgLink {
    width: 100%;
    overflow: hidden;
    margin: 0;
    max-height: 476px;
    border-top-right-radius: $base-radius;
    border-top-left-radius: $base-radius;
  }

  .imgLink img {
    display: block;
    max-width: 100%;
    height: auto;
    border-top-right-radius: $base-radius;
    border-top-left-radius: $base-radius;
  }

  .item {
    width: 31%;
    left: -2px;
    background-color: $blue;
    box-shadow: 6px 6px 5px 0 rgba(0, 0, 0, .125);
    margin-bottom: 25px;
    border-radius: $base-radius;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    transform: scale(.98, .98);
    @include transition;

    .item-details {
      padding: $base-padding*2;
      color: $white;

      * {
        color: $white;
      }

      h3 {
        font-size: $base-font-size+4;
        margin-bottom: $base-margin;
      }
    }

    &:hover {
      transform: translateZ(0)scale(1.0, 1.0);
      box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, .1);
    }
  }

  [class^=bss-icon-] {
    padding-right: 0;
    top: 2px;
  }

  a {
    @include transition;
    &:hover,
    &:hover i {
      color: $primary;
    }
  }

  @media screen and (max-width: $viewport-s) {
    .item {
      width: calc(100% - #{$base-padding*2});
      margin: $base-margin;
    }

    .headline-list {
      display: block;
    }

    .section-name {
      font-size: $base-font-size + ($base-font-size * 0.5);
      margin-top: $base-margin * 2;
      padding: 0 $base-padding 0 $base-padding;
      .secondary-color {
        font-size: inherit;
      }
    }
  }
}
