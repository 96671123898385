footer#bss-footer, footer#tributes-footer {
  background-color: $footer-bg-color;
  color: $white;
  margin-top: $base-margin*2;
  border-top: none;
  padding-top: $base-margin*4;
  padding-bottom: $base-margin*4;

  a,
  p {
    @include fonts(normal, ($base-font-size)-2);
    color: $white;
    text-align: left;
  }
  a.btn, a.button{
    color: $black;
    text-align: center;
  }

  .disclaimer {
    font-size: $base-font-size - 6px;
    line-height: 14px;
  }

  .faq {
    margin: $base-margin*2 0;
    display: flex;
    align-items: center;
    font-size: $base-font-size - 3;
    white-space: nowrap;
  }

  a.footer-phone {
    margin-bottom: $base-margin*2;
    display: block;
  }

  h3 {
    font-family: $font-bold;
    font-weight: bold;
    font-size: $base-font-size;
    line-height: 1.25;
    padding: $base-padding 0 $base-padding*2 0;
    display: block;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
    color: $white;
  }

  .accordion {
    .accordion-title {
        font-family: $font-bold;
        font-weight: bold;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
		li {
      a {
        text-decoration: none;
        display: block;
        font-size: ($base-font-size)-2;
        font-family: $font;
        font-weight: 200;
        line-height: 24px;
        padding-top: $base-padding*0.5;
        padding-bottom: $base-padding*0.5;
        color: $white;
      }
    }
    &.contact-social {
      margin-top:12px;
      li:first-child {
        float: left;
      }
      li:last-child {
        float: right;
      }
    }
  }

  .newscorp p {
    font-size: ($base-font-size)-6;
    line-height: 14px;
  }

  .footer-logo {
    width: 100%;
    height: auto;
  }

  .content-wrapper {
    text-align: justify;
    max-width: $max-width;
    padding: 0 $base-margin*2;
    margin: 0 auto;
  	&:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0;
      clear: both;
    }
  }

  .col {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 17%;
    color: $white;
    &.newscorp {
      width: 17%;
    }
    &.wide-col {
      width: calc(30% - #{$base-margin});
      padding-left: $base-margin;
    }

    img.newscorp-logo {
      width: 75%;
      top: 2px;
      margin: $base-margin*2 0 $base-margin;
    }
  }

  .wl-only{
    display: none;
  }

  .useful-links {
    width: 80%;
  }
}

.page-404 {
  footer#bss-footer, footer#tributes-footer {
    margin-top: 0;
  }
}


@media screen and (min-width: ($viewport-s)+1) {
  .accordion {
    .accordion-title {
      background: none;
    }
  }
}

@media screen and (min-width: $viewport-s) and (max-width: $viewport-m) {
	footer#bss-footer, footer#tributes-footer {
    .faq {
      font-size: $base-font-size - 5;
    }
    ul li a {
      font-size: $base-font-size - 4;
    }
  }
}

@media screen and (max-width: $viewport-s) {
  footer#bss-footer, footer#tributes-footer {
    padding: 0;
    .button { margin: $base-margin 0; }
    @at-root{
      .adcore footer{padding-bottom: $base-margin*6;}
    }
    .content-wrapper {
      padding: $base-margin;
      margin: 0;
    }
    .useful-links {
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: $base-margin 0 $base-margin;
    }

    .footer-logo {
      margin: $base-margin 0;
      width: 100%;

      img {
        max-width: 50%;
      }
    }

    h3 {
      position: relative;
      cursor: pointer;
      pointer-events: auto;
      padding: $base-padding 0;
      font-size: $base-font-size;
      font-family: $font;
      font-weight: normal;
    }

    &:before {
      display: none;
    }

    .col,
    .col:last-of-type {
      display: block;
      width: 100%;
      padding-left: 0;
      border-bottom: none;

      img.newscorp-logo {
        padding: 0;
      }

      &.newscorp {
        padding-bottom: $base-padding*2;
        width: 100%;
        border: none;
        p {
          max-width: 100%;
          padding: 0;
          margin-top: 0;
        }
      }
    }

    section {
      margin: 0;
    }

    .accordion {
      .accordion-body.show {
        margin-top: 0;
      }
      .accordion-title.hidden {
          display: none;
      }
    }
    .desktop-inline {
      display: none;
    }
  }
}

// Whitelabel specific tweaks

.finda-wl, .finda-wl-mobile {
  footer#bss-footer, footer#tributes-footer{
    .newscorp, .legal-col, .standalone-only{
      display:none !important;
    }
    .wl-only{
      display: inline-block;
    }
  }
}
footer.footer {
  background-color: $footer-bg-color;
  padding-top: 1px;
  margin-top: 0px;
}
