// @TODO: We didn't have styles for this page/component and it seems like a hack to start now.
//
// It seems like the optimal solution would possibly be to either remove the z-indexing from all form elements, or add z-index to the ico-bg class.
//
// Either way I'm not keen for that today so it's a hack until we get around to it.
#id_select_state_form .ico-bg{
  position: relative;
  &:before{
    z-index: 2;
    right: 12px;
  }
}

// @HACK: Adobe Ad Taret (at.js) Injects a style tag into our head early on that
// tries to "prevent flickering" but since it doesnt really change much and the page
// is typically alreadyy rendered, this just leads to a flicker effect that is more
// noticable on slower connections.
body#bss {
    opacity: 1 !important;
}
