// @TODO: Roll this into refinement-filter.scss
@media screen and (max-width: $viewport-s) {
  .sm-filter {
    .mobile-sort {
      background: unset;
      pointer-events: none;
      opacity: .3;
    }

    .refine-button {
      color: $white;
      * {
        color: $white;
      }
    }
  }
}
