.videoInline {
    margin-bottom: 10px;

    &.right {
        float: right;
        margin: 5px 0 5px 10px;
    }

    &.small {
        width: 300px;
    }

    &.medium {
        width: 460px;
        clear: left;
    }

    &.large {
        width: 620px;
        clear: both;
    }

    &.fullWidth {
        width: 100%;
        clear: both;
    }

    .inlineVideoWrapper {
        width: 100% !important;
        background: black !important;
    }

    figcaption {
        margin: 0 !important;
        padding: 5px 10px 8px 10px;
    }

    &.medium figcaption h5,
    &.large figcaption h5 {
        display: none;
    }

    iframe {
        width: 100%;
    }
}
