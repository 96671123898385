.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.form-control {
  margin-right: $base-margin*2;
  width: 100%;
  // resets to cater for m. RNN when boostrap is installed
  height: initial;
  background-color: initial;
  box-shadow: none;
  border-radius: initial;
  padding: initial;
  // end resets

  &:last-child { margin-right: 0; }
  &.error {
    label { color: $error-colour; }
    input { border-bottom-color: $error-colour !important; }
  }
  input[type=checkbox]{
    margin: 0 4px 0 0;
    vertical-align: middle;
  }
}

// @HACK: Should consider removing this, a bit dirty
.error { color: $error-colour; }
.form-control-desktop {
  @extend .form-control, .desktop;
}

.form-section-search input[type=text] {
  top: 1px!important;
}

.mobile-sort select {
  border: none;
}

.ranges input:not([type=checkbox]):not([type=submit]):not([type=radio]) {
  width: 34%;
}

.ranges input:not([type=checkbox]):not([type=submit]):not([type=radio]):last-of-type {
  text-align: right;
}

.ranges div {
  display: inline-block;
  padding: 0 $base-margin*2;
  margin: auto;
}
