// Articles Pane
//
// Used for displaying a small handful of articles for the sidebar or fullpage.
.articles-pane {
  display: flex;

  // We want the title and the CTA link to appear side by side
  flex-wrap: wrap;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    a.view-all {
      text-align: right;
    }
  }

  // Standard Styling
  .articles-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    // Article container
    article {
      display: block;
      border-bottom: 2px solid $primary;
      padding: $base-margin 0;

      // Inner Elements
      img {
        transition: all 0.3s ease-in-out !important;
        object-fit: cover;
      }

      h4 {
        transition: all 0.3s ease-in-out !important;
        font-size: 18px;
        font-weight: bold;
        color: $black;
        margin-bottom: $base-margin;
      }

      &:hover,
      &:active {
        color: $black;

        img {
          opacity: 0.9;
        }
      }
    }
  }

  // FullPage Variant
  &:not(.sidebar) {
    // @NOTE Content wrapper
    margin: 0 24px;
    padding-top: $base-margin;

    .view-all {
      padding-top: 4px;
    }

    .articles-body {
      h4 {
        font-size: 22px;
      }

      article {
        visibility: visible;

        img {
          float: right;
          // @NOTE: The effect on floated images with height 100% within a grid is
          // interesting to say the least. We should maybe just use flex instead of
          // float but for now, we will just use a max-width on mobile to prevent
          // image image height locking the <a> tag height
          height: 100%;
          max-width: 120px;
          margin-left: $base-margin;

          @media screen and (max-width: $viewport-s) {
            max-height: 120px;
          }
        }

        p {
          display: block;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      // Featured Article
      article:first-child img {
        width: 100%;
        max-width: 100%;
        float: none;
        margin-left: 0;
        margin-bottom: $base-margin;
      }

      &.items-2,
      &.items-1 {

        // Featured Article
        article {
          grid-row: 1 !important;

          img {
            width: 100%;
            max-width: 100% !important;
            max-height: 360px;
            float: none;
            margin-left: 0;
            margin-bottom: $base-margin;
          }
        }
      }
    }


    // Tablet
    @media screen and (min-width: $viewport-s+1) {

      // We actually have more Vertical width on tablet since we are 1 col
      .articles-body article img {
        max-width: 180px;
      }

      .articles-body article:first-child img {
        max-height: 41vw;
      }
    }

    // Fullpage Desktop Variant - Seperate the first article as featured, it will use 3x
    // height for odd numbered articles, 2x for even numbered articles
    @media screen and (min-width: $max-width+1) {
      max-width: $max-width;
      margin: 0 auto;

      // Change from flexbox to a grid layout
      .articles-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 0 $base-margin*4;
        margin: $base-margin 0 ($base-margin * 3) 0;

        // Featured Article
        article:first-child {
          grid-row: 1/4;

          img {
            max-height: 360px;
            width: 100%;
          }
        }

        article:not(:first-child) img {
          max-width: 120px;
        }
      }
    }

    @media screen and (max-width: $viewport-s) {
      .articles-pane-wrap {
        margin-bottom: $base-margin*2;
      }
    }
  }

  // Sidebar Variant
  &.sidebar {
    flex-direction: column;

    @media screen and (max-width: $viewport-s) {
      // @NOTE: Mobile requires some top spacing to avoid clashing with the
      // refinement filters, but ideally that should already have it's margin?
      margin-top: $base-margin;

      // Keep images around the golden ratio on mobile
      img {
        max-height: 59vw;
      }
    }

    // Heading
    h3 {
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 8px;
    }

    .articles-body {
      margin-bottom: 8px;
      border-top: 1px solid $grey-light;

      article {
        border-bottom: 1px solid $grey-light;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    // Move the CTA link to the bottom of the body
    a.view-all {
      order: 3;
    }

    // Tablet
    @media screen and (min-width: $viewport-s+1) {
      .articles-body {
        img {
          float: right;
          max-width: 40%;
          height: 140px;
          margin-left: $base-margin; // To prevent text from coming too close
        }

        h4 {
          font-size: 15px;
          line-height: 18px;
          font-weight: bold;
          margin-bottom: 4px;
          // Clamp to 4 lines
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        p {
          font-size: 14px;
          line-height: 16px;
          // Clamp to 4 lines
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

// @HACK BSS Only homepage hack
.bss>.articles-pane-wrap,
#bss>.articles-pane-wrap {
  background: $white;
}

.articles-pane-wrap.mobile {
  border-bottom: 1px $grey-light solid;
  margin-bottom: 18px;
  .articles-pane,
  .articles-body,
  .articles-body p {
    margin-bottom: 0!important;
  }
}

// Add the underline link style used with "View More" and "View All" typically
.btn-underline {
  line-height: inherit;
  font-size: 14px;
  border-bottom: 1px $grey-light solid;
  padding-bottom: 8px;
  transition: all 0.3s ease-in-out !important;

  &:hover,
  &:active {
    color: $primary;
    border-bottom-color: $primary;
    padding-bottom: 6px;
  }
}

.sidebar .btn-underline,
.related .button.tertiary,
.homepage .button.tertiary  {
  display: none!important;
}

// MOBILE
@media screen and (max-width: $viewport-s) {
  .btn-underline,
  .sidebar .articles-pane {
    display: none!important;
  }
  .homepage .button.tertiary,
  .related .button.tertiary {
    display: block!important;
  }
}
