// New Code @TODO Clean this all up into a single module across all sites
@at-root {
  body:not(.adcore) .adblock{
    display:none;
  }

  body.adcore{
    $base-margin: 16px;

    // MREC
    .ad-block.ad-halfpage[data-google-query-id],
    .ad-block.ad-mrec[data-google-query-id],
    .ad-block.ad-m-mrec[data-google-query-id] {
      display: block;
      box-sizing: content-box;
      width: 300px;
      max-width: 300px;
      padding: 6px 6px 4px 6px;
      margin: 0 auto $base-margin auto;
      background-color: #d6d6d6;

      &::after{
        content: 'Advertisement';
        display: block;
        font-size: 14px;
        color: #929292;
        text-transform: uppercase;
        text-align: right;
        padding-top: 4px;
      }
    }

    // Adhesive wrapper for the mobile footer, to slide up on first scroll event
    .ad-adhesive {
      display: none;
      background: #d6d6d6;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999999;
      height: auto;
      min-height: 70px;
      justify-content:center;
      align-items: center;
      transform: translateY(100%);
    }

    .ad-adhesive.ad-adhesive-show {
      transform: translateY(0);
      transition: transform 0.5s ease-out;
    }

    // Top Picks
    &.tributes .top-picks__content-wrapper .ad-block{
      margin-right: 16px;
    }
    // Responsive toggling, show desktop by default and hide mobile in the mixin
    .ad-block.ad-m-mrec{display: none;}

    @media all and (max-width: 736px) {
      .ad-block.ad-m-mrec { display: block; }
      .ad-adhesive { display: flex; }
      .desktop-ad{display: none !important;}
    }

  }

  .adSpace {
    margin: 0 auto;
  }

  .adSpace img.spinner {
    width: $fixed-width * 0.1;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fixed-column .adSpace {
    padding: 0!important;
    text-align: center;
    min-width: $fixed-width;
    min-height: 250px;
  }

  .width728 {
    width: 729px;
    margin: $base-margin*2 auto;
  }

  .width320 {
    width: 320px;
  }

  .width300 {
    width: 300px;
  }

  // for the banner at the bottom
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $grey-light;
    z-index: 2;
  }

  //mobileAd3 is the mrec for mobile
  #mobileAd3 {
    margin-bottom: $base-margin * 2;
  }

  // MREC for desktop if it appears we want the refinement to not sit flush
  // but if the ad is not showing we want the refinement to sit flush
  #adSpace1 {
    margin-bottom: $base-margin * 2;
  }

  .bz-page-wrapper > * {
    z-index: 5;
  }

  .bz-page-wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }

  .ad-block, .ad-billboard {
    margin: 0 auto;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $base-margin;
    margin-bottom: $base-margin;
  }

  .ad-m-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: 100%;
  }

  .mobile.ad-block.ad-m-mrec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-height: 563px) {
    .ad-m-banner {
      z-index: -1 ;
    }
  }
}
