#bss_article.content-wrapper,
#myt_article.content-wrapper {
  background-color: $white;
  padding-top: $base-padding*2;
  margin-top: $base-padding;
  padding-bottom: $base-padding*2;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  display: flex;

  .story-container {
    min-width: calc(100% - 324px);
    max-width: calc(100% - 324px);

    .story-headline {
      h1 {
        color: $black;
      }
      .publish-date {
        padding-bottom: $base-padding * 0.5;
        padding-bottom: $base-padding * 0.5;
        margin-bottom: $base-margin;
        border-bottom: 1px solid $grey-light;
        display: flex;
        justify-content: center;
        align-items: center;

        .share-icons {
          display: flex;
          margin-left: auto;

          .share-tools {
            a {
              .bss-icon-facebook {
                color: $facebook;
              }

              .bss-icon-twitter {
                color: $twitter;
              }
            }
          }
        }

        p {
          color: $grey-medium;
          font-size: $base-font-size;
        }
      }
    }

    .story-body {
      p, table, aside, div {
        margin-bottom: $base-margin + 6;

        br {
          display: none;
        }
      }

      ul {
        list-style-type: disc;
      }

      ul, ol {
        li {
          margin-bottom: $base-margin * 0.5;
          word-break: break-word;
        }
      }

      sub {
        top: .3em;
      }

      sup {
        top: -.3em;
      }

      blockquote {
        position: relative;
        font-family: georgia;
        font-style: italic;
        font-size: $base-font-size;
        border-top: 4px solid $grey-lighter;
        border-bottom: 4px solid $grey-lighter;
        margin: 5.5em $base-margin;
        padding: 0.5em $base-padding;
        quotes: "\201C""\201D""\2018""\2019";
        color: $grey-light;
        * {
          font-family: georgia;
          font-size: $base-font-size + 4;
          line-height: 1.76em;
        }
      }
      blockquote:before {
        font-style: normal;
        color: $grey-light;
        content: open-quote;
        font-size: 6em;
        line-height: 0.1em;
        position: absolute;
        top: -4px;
        left: 0;
      }
      blockquote:after {
        font-style: normal;
        color: $grey-light;
        content: close-quote;
        font-size: 6em;
        text-align: right;
        line-height: 0.1em;
        position: absolute;
        bottom: -.8em;
        right: 0;
      }
      blockquote p {
        display: inline;
      }

      .article-lead-image {
        .articleImage {
          figure {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .article-lead-video {
          .caption-details {
            padding-top: $base-padding * 0.5;
          }
        }
      }

      figcaption {
        border-bottom: 1px solid $grey-light;
        padding-bottom: $base-padding * 0.5;
        margin-bottom: $base-margin;

        .caption-details {
          span {
            font-family: $font-bold;
            font-weight: bold;
            font-size: $base-font-size - 2px;
          }
        }
      }

      .video-js {
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        width: 100%;
      }
      .video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video-js .vjs-modal-dialog, .vjs-button>.vjs-icon-placeholder:before, .vjs-modal-dialog .vjs-modal-dialog-content {
        position: relative;
      }
    }
    #mobileAd3 {
        margin-top: $base-margin * 2;
    }
  }

  .fixed-column {
    min-width: $fixed-width;
  }

  .side-column-wrapper {
    position: sticky;
    top: 77px + ($base-margin * 2);

    .related-article-headline {
      border-bottom: 1px solid $grey-light;
      font-size: 20px;
      font-family: $font-bold;
      font-weight: bold;
      font-size: $base-font-size + 4px;
      color: $black;
    }

    .related-articles {
      padding-left: 0;
      margin-top: 0;

      .related-story {
        display: flex;
        border-bottom: 1px solid $grey-light;
        padding-bottom: $base-padding;
        padding-top: $base-padding;

        .related-story-details {
          width: 50%;
        }

        .story-title {
          font-family: $font-bold;
          font-weight: bold;
        }
      }

      .related-image {
        margin-left: $base-margin * 0.5;
        width: 50%;
        display: flex;

        img {
          margin-left: auto;
        }
      }
    }
  }

  .galleria-theme-classic .galleria-stage .galleria-fullScrBtn {
    z-index: 10;
  }
}

@media screen and (max-width: 1000px) {
    #bss_article.content-wrapper,
    #myt_article.content-wrapper  {
        margin-top: 0;
        box-shadow: none;
    }
}

@media screen and (max-width: $viewport-s) {
  #bss_article.content-wrapper,
  #myt_article.content-wrapper  {
    flex-direction: column;
    box-shadow: none;

    .story-container {
      max-width: 100%;
      min-width: 100%;
    }

    .side-column-wrapper {
      .related-articles {
        .related-image {
          margin-left: auto;
          width: auto;
        }

        .related-story-details {
          margin-right: auto;
          padding-right: $base-padding * 0.5;
        }
      }
    }
  }
}
