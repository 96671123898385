/* LAZY SUZAN CAROUSEL */

#card-carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;

  #card-carousel {
    height: 45vw;
    max-height: 530px;
    width: 100%;
    margin: $base-margin*4 0 0;
    padding: 0;
    list-style: none;
    position: relative;

    li {
      position: absolute;
      left: 50%;
      top: 0;
      width: 90%;
      height: 100%;
      padding: 0;
      cursor: pointer;
      transition: all 1.9s cubic-bezier(.19, 1, .22, 1);
      z-index: 1;

      &.item-1 {
        z-index: 2;
        transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
        opacity: 1;
      }

      &.item-2 {
        z-index: 1;
        transform: translateX(-50%) scale(.7) translate3d(80%, -30%, 0);
        opacity: .1;
        filter: blur(1rem);
      }

      &.item-3 {
        z-index: 0;
        transform: translateX(-50%) scale(.5) translate3d(60%, -62%, 0);
        opacity: .05;
        filter: blur(1.5rem);
      }

      &.item-4 {
        z-index: 0;
        transform: translateX(-50%) scale(.5) translate3d(-60%, -62%, 0);
        opacity: .05;
        filter: blur(1.5rem);
      }

      &.item-5 {
        z-index: 1;
        transform: translateX(-50%) scale(.7) translate3d(-80%, -30%, 0);
        opacity: .1;
        filter: blur(1rem);
      }

      &:hover {
        section {
          transform: translateZ(-5px);
        }
      }

      &:not(:hover) {
        section {
          transition: transform .075s ease-out;
        }
      }

      section {
        width: 100%;
        height: auto;
        margin-top:0;
        transition: transform .25s ease-in;
        height: 100%;
        .banner-wrapper {
          display: block;
          margin-bottom: 60px;
          height: calc(100% - 60px);
          a.button {
            display: block;
            width: auto;
            height: auto;
            position: absolute;
            top: auto;
            bottom: 0;
            right: 40px;
            left: auto;
            margin-bottom: 0;
          }
        }
      }
    }

    &.items-4 {
      li {
        &.item-1 {
          z-index: 2;
          transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
          opacity: 1;
        }

        &.item-2 {
          z-index: 1;
          transform: translateX(-50%) scale(.7) translate3d(75%, -25%, 0);
          opacity: .1;
          filter: blur(1rem);
        }

        &.item-3 {
          z-index: 0;
          transform: translateX(-50%) scale(.5) translate3d(0, -50%, 0);
          opacity: .05;
          filter: blur(1.5rem);
        }

        &.item-4 {
          z-index: 0;
          transform: translateX(-50%) scale(.5) translate3d(-75%, -25%, 0);
          opacity: .05;
          filter: blur(1.5rem);
        }
      }
    }

    &.items-3 {
      li {
        &.item-1 {
          z-index: 2;
          transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
          opacity: 1;
        }

        &.item-2 {
          z-index: 1;
          transform: translateX(-50%) scale(.7) translate3d(80%, -30%, 0);
          opacity: .1;
          filter: blur(1rem);
        }

        &.item-3 {
          z-index: 1;
          transform: translateX(-50%) scale(.7) translate3d(-80%, -30%, 0);
          opacity: .1;
          filter: blur(1rem);
        }
      }
    }

    &.items-2 {
          li {
            &.item-1 {
              z-index: 2;
              transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
              opacity: 1;
            }

            &.item-2 {
              z-index: 1;
              transform: translateX(-50%) scale(.5) translate3d(0, -50%, 0);
              opacity: .1;
              filter: blur(1rem);
            }
          }
        }

    &.items-1 {
        li {
          &.item-1 {
            z-index: 2;
            transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
            opacity: 1;
          }
        }
      }
  }

  #card-carousel-nav {
    position: static;
    width: 100%;
    .carousel-nav {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      border-top: 2px solid $primary;
      border-right: 2px solid $primary;
      border-bottom: none;
      border-left: none;
      background-color: transparent;
      outline: 0;
      cursor: pointer;
      opacity: .5;
      @include transition;
      @include overlay;

      &.prev {
        left: 20px;
        transform: scale(.6) rotate(-135deg);
      }

      &.next {
        right: 20px;
        transform: scale(.6) rotate(45deg);
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  #carousel-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;

    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 15px;
        width: 10px;
        height: 10px;
        background-color: $grey-light;
        border-radius: 100%;
        cursor: pointer;

        &.active {
          background-color: $primary;
        }
      }
    }
  }
}

/* Featured carousel */

.carousel {
  .section-title {
    @include fonts(normal, 24px);
    margin: $base-margin*2 auto $base-margin;
  }

  .carousel-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .carousel-item {
    width: 135px;
    margin-right: $base-margin*2;

  &:last-child {
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
      max-height: 101px;
      border: 1px solid $grey-dark;
      margin-bottom: $base-margin;
    }

    .caption {
      @include fonts(normal, ($base-font-size)-2);
      height: 36px;
    }
  }
}

.carousel-desktop {
  @extend .carousel, .desktop;
  margin-bottom: $base-margin*2;
}

.carousel-charities {
  margin-bottom: $base-margin * 2;

.section-title {
    margin-bottom: $base-margin * 2;
  }

  .carousel-item {
    max-width: 140px;

    p {
      font-size: $base-font-size - 2;
    }

    img {
      width: 100%;
      height: auto;
      max-height: initial;
    }
  }
}

  // Our old featured carousel from the RNN sites and Finda
  // TODO: when markup is not needed for finda redo the markup
  // and this styling; All of this is a bit of hack with the old markup
  .section-carousel:not(.itemCarousel) {
  overflow: hidden;
  max-width: $max-width - $base-margin*4;
  background: $background-secondary;
  padding: $base-padding;
  height: $feature-carousel-height;
  margin-bottom: $base-margin * 2;
  border-radius: $base-radius;

  .row {
    margin-left: 0;
  }

  h3, h3 a {
    font-size: $base-font-size - 4!important;
    margin: $base-margin*0.5 0;
    line-height: 1.2em;
  }

  h5, h5 * {
    font-size: $base-font-size  !important;
    justify-content: left;
    line-height: 1.2em;
    margin: $base-margin*0.5 0;
  }

  a {
    width: 128px;
    height: auto;
    max-height: 108px;
    margin: 0;
  }

  .items {
    width: 200%;
    overflow: hidden;
    min-height: initial;
  }

  .item {
    display: block;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;

    .imgLink {
      position: relative;
      line-height: 1.2em;
      height: 83px;
      display: block;
      text-align: left;
    }

    img {
      max-height: 83px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: auto!important; /* Override TC */
    }

    &.span2 {
      float: left;
      margin: 0 $base-margin*0.5;
    }
  }

  .container-section-vertical-item {
    min-height: 175px;
  }
}

  .section-carousel {
    position: relative;
    overflow: hidden;

  &:after,
  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: $base-margin;
  }

  &:before {
    background: linear-gradient(to right, $background-secondary, rgba($background-secondary, .001));
    width: $base-margin*0.5;
    left: 0;
  }

  &:after {
    background: linear-gradient(to left, $background-secondary, rgba($background-secondary, .001));
    right: 0;
  }

  /* Reimplement overridden button styles */

  .next,
  .prev {
    display: none;
    outline: none;
    position: absolute;
    top: 0;
    width: $base-margin*4;
    height: 184px;
    @include overlay(3);
    font-size: 16px;
  }

  .prev {
    left: 0px;
    background: linear-gradient(to left, rgba($background-secondary, 0), $background-secondary);
    text-align: left;
  }

  .next {
    right: 0px;
    background: linear-gradient(to right, rgba($background-secondary, 0), $background-secondary);
    text-align: right;
  }

  .show,
  &.hover .next,
  &.hover .prev {
    display: block
  }
}

////////////
// MOBILE //
////////////

@media screen and (max-width: $viewport-s) {
  #card-carousel-container {
    #card-carousel {
      margin: 0;
      height: 390px;
      li {
        position: absolute;
        left: 50%;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        cursor: pointer;
        transition: all 1.3s cubic-bezier(.19, 1, .22, 1);
        z-index: 1;

        &.item-1 {
          transform: translateX(-50%) scale(1) translate3d(0, 0, 0);
        }

        &.item-2 {
          transform: translateX(-50%) scale(.7) translate3d(80%, -15%, 0);
        }

        &.item-3 {
          filter: blur(1.5rem);
        }

        &.item-4 {
          transform: translateX(-50%) scale(.5) translate3d(-60%, -31%, 0);
        }

        &.item-5 {
          transform: translateX(-50%) scale(.7) translate3d(-80%, -15%, 0);
        }

        &:hover {
          section {
            transform: translateZ(5px);
          }
        }

        section {
          margin-top: 0;
          .banner-wrapper {
            a.button {
              right: 25%;
              left: 25%;
              bottom: $base-margin;
            }

             a:first-of-type,
             img {
              max-height: 245px;
              max-width: 100%;
              width: auto;
              height: auto;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              transform: none;
            }

            a:first-of-type {
              top: 65px;
              bottom: 75px;
              left: $base-margin;
              right: $base-margin;
            }
          }
        }
      }

      #card-carousel-nav {
        display: none;
      }

      #carousel-dots ul {
        margin-bottom: 0;
      }
    }
}

  .section-carousel {
    display: none;
  }

  .carousel-charities {
    .content-wrapper {
      padding: 0;
    }
    .carousel-wrapper {
      justify-content: space-between;
      flex-flow: row wrap;

    .carousel-item {
        max-width: calc(50% - #{$base-margin});
        flex: 2 50%;
        margin-right: 0;
        margin-bottom: $base-margin;
      }
    }
  }
}
