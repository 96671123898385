// Spacing around the inside of the card, exluding some fixed elements
$card-padding: $base-padding*0.5;

// Standard card for handling bordered items.
.card {
  position: relative;
  display: block;
  width: 100%;
  background: white;
  padding: $card-padding;
  border-radius: $base-radius;

  transition: 0.3s ease-in-out box-shadow;
  @include drop-shadow();

  // Cards with some kind of bound/interactive functionality
  &[data-click-url], &[href]{
    cursor: pointer;

    &:hover, &:focus{
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
      // @BUG: There is a bug in Blink when applying a zoom effect that causes
      // cache invalidation in the renderer so we will disable it for now.
      // .card-img img{ transform: scale(1.15); }
    }
  }

  .card-tag {
    position: absolute;
    z-index:1;
    top: 0;
    left: 0;
    background: rgba(65, 64, 66, 0.8);
    padding: calc($base-padding / 2) $base-padding;
    font: 500 12px/16px $font;
    color: white;
    border-radius: $base-radius 0 $base-radius 0;
  }

  .card-img {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 0;
    margin: $card-padding * -1;
    // Create and Maintain 1:1 Aspect ratio while adding the extra width
    // from the above negative margin
    padding-top: calc(100% + #{$card-padding*2});
    align-items: center;
    border-radius: $base-radius $base-radius 0 0;
    img {
      transition: 0.25s ease-in-out transform;
      // Bring the image into position without position absolute
      margin-top: -100%;
      width:100%;
      object-fit:contain;
      mask-image: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 95%, rgba(0,0,0,0.1) 100%);
    }

    // Cover variant that instead just uses a single image and focuses on the middle
    // with minimal cropping while pushing out enough to maintain 1:1 ratio
    &.cover img{
      min-height: 240px;
      @media screen and (max-width: $viewport-m){
        min-height: 180px;
      }
      mask-image: none;
      object-fit: cover;
    }
  }


  .card-title{
    font: 600 16px/22px $font;
    margin: $base-padding calc($base-padding / 2) calc($base-padding / 2) calc($base-padding / 2);
    // Always space for 2 lines
    display: -webkit-box;
    height: 44px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-description{
    font-size: $base-font-size - 2px;
    margin: 0 calc($base-padding / 2) calc($base-padding / 2) calc($base-padding / 2);
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    visibility: visible;
    word-wrap: break-word;
    overflow: hidden;
  }

  // Always space for 1 line
  .card-location{
    &:before{
      font: normal 18px/15px 'icomoonbss';
      content: "\e947";
      color: $secondary;
      vertical-align: middle;
    }
    &:empty{
      display: block;
      visibility: hidden;
    }
    font: normal 12px/16px $font;
    margin: 0 calc($base-padding / 2) $base-padding calc($base-padding / 2);
    // @TODO: A mixin for elipsis using webkit box?
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // Tablet
  @media screen and (min-width: 768px){
    .button{
      display: block;
      margin-right: $card-padding;
    }
    .card-location{
      text-align:left;
    }
    .card-buttons{
      display: flex;
      .button:last-child{margin-right:0}
    }
  }

  // Background image blur effect
  .bg-blur{
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(20px);
    transform: scale(2);
  }
}
