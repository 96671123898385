.pagination {

  .pagination-wrapper {
    justify-content: flex-start;
    max-width: 430px;
    margin: 0 auto $base-margin*2 auto;
  }

  .pagination-label {
    float: right;
    position: relative;
    top: $base-margin*0.5;
    font-weight: normal;
    font-size: $base-font-size - 2;
  }

  .pagination-gap {
    margin-right: $base-margin*0.5;
    line-height: 28px;
  }

  ul {
    box-shadow: none;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    li {
      margin: 0 $base-margin*0.5 0 0;
      display: block;
      position: relative;

      &:first-of-type {
        width: 100%;
      }

      &:last-of-type {
        width: 100%;
        margin-right: 0;
      }

	    a,
	    p {
        display: block;
        height: 33px;
        color: $primary;
        text-align: center;
        line-height: 31px;
        padding: 0 10px;
        min-width: 33px;
        font-weight: normal;
        margin: 0;
        border-radius: 0!important;
        white-space: nowrap;
      }

      a {
        border: 1px solid $primary!important;
        background: $white;
	      @include drop-shadow(0, 1px, .5px, rgba($grey-dark, .1));
        @include transition();

	      &:hover {
          background: rgba($primary, .8);
          color: $white;
          span {
            color: $white;
          }
        }

        &.page-left,
	      &.page-right {
          padding: 0;
          width: 100%;
        }

        &.page-left {
          padding-left: $base-padding;
          text-align: left;
        }

        &.page-right {
          padding-right: $base-padding;
          text-align: right;
        }

        * {
          color: $primary;
          line-height: 31px !important;
        }
      }

      &.active {
        a,
        a:hover,
        a:hover span {
          background: $primary;
          color: $white;
        }
      }

      &.disabled a,
	    &.disabled a:hover {
        opacity: .5;
        filter: none;
        background: $white;
        color: $primary;
      }
    }

    &:first-of-type {
      li:first-of-type a {
        border-top-left-radius: $base-radius!important;
        border-bottom-left-radius: $base-radius!important;
      }
    }

    &:last-of-type {
      li:last-of-type a {
        border-top-right-radius: $base-radius!important;
        border-bottom-right-radius: $base-radius!important;
      }
    }
  }
}


////////////
// MOBILE //
////////////

// All mobile and plus sizes
@media screen and (max-width: $viewport-s) {
  .pagination {
    width: 100%;
    margin: 0;
    margin-top: $base-margin*3;
    border-radius: 0;
    position: relative;
    ul {
      li {
        a,
        p {
          font-size: ($base-font-size)+1;
          display: flex;
          justify-content: center;
          height: 31px;
          width: 31px;
          color: $primary;
          text-align: center;
          line-height: 31px;
          padding: 0 6px;
          min-width: 31px;
          margin: 0;
          &:hover {
            background: rgba($primary, .8);
            color: $white;
          }
        }
      }
    }
    .pagination-gap {
      line-height: 25px;
    }
    span {
      display: block;
    }
  }
}

// Start justifying pagination around here
@media screen and (max-width: 500px) {
  .pagination {
    ul {
      li {
        a {
          &.page-left,
          &.page-right {
            padding: 0 $base-padding*0.5;
            text-align: center;
          }
        }
      }
    }
    .hide-for-viewport-m {
      display: none;
    }
  }
}

// Medium size (ex iPhone 6-12)
@media screen and (max-width: 410px) {
  .pagination {
    .pagination-label {
      top: 34px;
    }
    ul {
      li {
        a {
          font-size: ($base-font-size)-3;
          height: 27px;
          width: 27px;
          line-height: 27px;
          min-width: 27px;
        }
      }
    }
    .pagination-gap {
      line-height: 22px;
    }
  }
}

// Smallest size (ex. iPhone SE)
@media screen and (max-width: 374px) {
  .pagination {
    ul {
      li {
        margin-right: 4px;
        a {
          font-size: ($base-font-size)-6;
          height: 22px;
          width: 22px;
          line-height: 22px;
          min-width: 22px;
        }
      }
    }
    .pagination-gap {
      line-height: 15px;
    }
  }
}
