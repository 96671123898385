.breadcrumb {
  padding: $base-margin*2 0 $base-margin 0;
  background: none;
  margin: 0;

  * {
    @include fonts(normal, ($base-font-size)-2);
    padding-right: 3px;
  }

  .flex {
    justify-content: flex-start;
  }

  .current {
    color: $primary;
  }
}

// @TODO: Do we need any of this?
// Ported Finda styles for now

nav.category-nav {
  margin-bottom: $base-margin;

	h2.small-header {
    font-weight: 700;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: #505050;
    text-transform: uppercase;
    font-size: $base-font-size+2;
    margin: $base-margin 0;
  }

  h5.surface-deep {
    margin-left: 0;
  }

  ul {
    padding-left: $base-margin;
    margin: 0;
    display: block;
    li {
      margin-bottom: 0;
      position: relative;
      .count {
        color: $grey-medium;
        font-size: $base-font-size;
        position: absolute;
        right: 0;
        top: calc($base-margin / 2);
      }
      &:after {
        display: none;
      }
    }
  }

  h4 {
    margin: 0;
  }

  a {
    padding: calc($base-margin / 2) $base-margin*2 calc($base-margin / 2) 0;
    background: none;
    display: block;
    line-height: 1.3;
    font-weight: normal;
  }

  h5 {
    margin: 0;
  }

  h5 a {
    font-size: $base-font-size!important;
  }

  .selected-tag {
    padding: 0 $base-margin;
    font-weight: 400;
    border-radius: 30px;
    line-height: 26px;
    height: 26px;
    background-color: #eee;
    border: 1px solid $grey-medium;
    margin-bottom: calc($base-margin / 4);
    font-size: $base-font-size;
	  @include box-shadow();
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: $viewport-s) {
  nav.category-nav {
    margin-top: $base-margin;
  }
}
