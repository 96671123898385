.hero {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 550px;
  max-height: 50vw;
  width: 100%;
  min-height: $base-margin*19;

  a.button.secondary {
    bottom: $base-padding*2;
    right: $base-padding*2;
    top: auto!important;
    width: auto;
    float: right;
    white-space: nowrap;
    span {
      font-size: $base-font-size - 4;
      margin-left: $base-margin - 2;
    }
  }

  .pagination-dots{
    position: absolute;
    bottom: 160px;
    z-index: 9;
  }

  .search-form-wrapper {
    position: absolute;
    display: flex;
    z-index: 9;
    width: calc(100% - 48px);
    background-color: rgba($black,.8);
    border-radius: $base-radius;
    bottom: $base-margin*4;
    align-items: center;
    flex-direction: column;
    backface-visibility: hidden;
    max-width: $max-width;
    height: min-content;
    padding-top: 0;

    .bss-search-form {
      margin: 0;
      padding: $base-padding*2 $base-padding;

      .input-toggle-section {
        .form-submit {
          .submit-button {
            background-color: $primary;
            color: $black;
            border-radius: 0 $base-radius $base-radius 0;
            height: 100%;
            box-shadow: 0px 2px 0px 0px $primary-dark;
          }
        }
      }
    }
  }
  // Fixed top search bar on mobile bss
  @media screen and (max-width: $viewport-s){
    .search-form-wrapper{
      position: unset;
      padding:0;
      .bss-search-form{padding:0}
    }
    .pagination-dots{bottom:5px;font-size:1em;}
  }
}

// MYT
// @HACK: This should be cleaned up and split into bss/myt specific changes from a generic base
// Probably in seperate files in their respective project folders
@at-root {
  .mytributes #hero .hero .search-form-wrapper {
    margin-top: $base-margin*8;
    background-color: rgba($white,.2);
    bottom: initial;
    .bss-search-form {
      padding: $base-padding*2 0;
      .input-toggle-section {
        .form-submit {
          .submit-button {
            background-color: $primary;
            box-shadow: 0 2px 0 0 $primary-dark;
          }
        }
      }
    }
  }

  // Mobile
  @media screen and (max-width: $viewport-s) {
    .mytributes {
      #hero {
        .hero {
          height: auto;
          max-height: 100%;
          .search-form-wrapper {
            max-height: 100%;
            margin-top: 0;
            .bss-search-form {
              padding:0;
              .input-toggle-section {
                .form-submit {
                  margin-bottom: $base-margin;
                  .submit-button {
                    background-color: $primary;
                    color: $black;
                    border-radius: $base-radius;
                    height: auto;
                    box-shadow: 0px 2px 0px 0px darken($primary, 25%);
                  }
                }
              }
            }
          }
        }
      }
    }

    .hero a.button {
      bottom: 0;
      right: 0;
      font-size: $base-font-size - 4;
      padding: $base-padding*0.5 $base-padding;
      span {
        font-size: $base-font-size - 6;
      }
    }
  }
}
