@mixin button-bg($bg) {
  background: $bg;

  &:hover {
    background: rgba($bg,8%);
    transition: all .3s ease;
  }

  &:active {
    background: rgba($bg,25%);
  }
}

.btn, .btn:active, button, .button {
  cursor: pointer;
  text-shadow: none; /* Fix RNN styles */
  box-shadow: none;
}

button:not([class*="vjs"]), .button, .btn {
  display: block;
  padding: $base-padding $base-padding*2;
  border-radius: $base-radius;
  text-align: center;
  background: none;
  width: 100%;
  @include fonts(normal, ($base-font-size));
  font-family: $font;
  @include transition;

  * {
   position: relative;
  }

  &.base-padding {
    padding: $base-padding;
  }

  &[type="submit"],
  &[type="button"] {
    -webkit-appearance: button;
  }

  &.primary,
  &.btn-primary {
    @include button-bg($primary);
    color: $black;
    box-shadow: 0px 2px 0px 0px darken($primary, 25%);
    * {
      color: $black;
    }

    &:hover {
      background-color: lighten($primary, 4%);
    }

    &:active {
      background-color: darken($primary, 2%);
      box-shadow: none;
      top: 1px;
    }
  }

  &.secondary,
  &.desktop-primary {
    @include button-bg($background-header);
    color: $black;
    box-shadow: 0px 2px 0px 0px darken($background-header, 25%);
    * {
      color: $black;
    }

    &:hover {
      background-color: lighten($background-header, 4%);
    }

    &:active {
      background-color: darken($background-header, 2%);
      box-shadow: none;
      top: 1px;
    }
  }

  &.grey-dark {
    color: $grey-dark;
    border: solid 1px $grey-dark;
    box-shadow: none;
    &:hover {
      border-color: $black;
      box-shadow: inset 0px 0 3px rgba($black, .2);
    }

    &:active {
      border-color: #000;
      box-shadow: inset 0px 0 10px rgba($black, .25);
      top: 0;
    }
  }

  &.white {
    color: $white;
    border: solid 1px $white;
    box-shadow: none;
    &:hover {
      border-color: rgba($white, .5);
      box-shadow: inset 0px 0 3px rgba($white, .2);
    }

    &:active {
      border-color: rgba($white, .9);
      box-shadow: inset 0px 0 10px rgba($white, .25);
      top: 0;
    }
  }

  &.grey {
    color: $secondary;
    border: solid 1px $grey-lighter;
    &:hover {
      border: solid 1px $grey-light;
    }
  }

  &.ghost{
    background: rgba($primary-alt, .1);
    color: $primary-alt;
    &:hover{
      color: $white;
      background: rgba($primary-alt, 1);
    }
  }

  &.tertiary{
    background: rgba($primary, 0);
    border: solid 1px $black;
    &:hover{
      background: rgba($primary-alt, .1);
    }
  }

  &.width-auto {
    width: auto;
  }

  &.fb-button {
    width: 250px;
    height: auto;
    padding: 8px 7px 6px;
    background-color: $facebook;
    color: $white;
    font: $font;
    font-weight: normal;
    line-height: 2;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .larger {
    position: relative;
    top: -8px;
    padding-right: calc($base-padding / 2);
    display: inline-block;
    transform: scale(1.2);
    line-height: 0;
  }

  &.button-sort {
    background-color: $grey-lightest;
    padding: calc($base-padding / 2) $base-padding;
    border: solid 1px $grey-light;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }
    &:nth-of-type(2) {
      border-radius: 0;
    }

    i { margin-left: calc($base-margin / 2) }

    &.selected {
        background-color: $primary;
        border-color: darken($primary, 10%);
    }
  }
}

@media screen and (min-width: $viewport-s) and (max-width: $viewport-m) {
  button:not([class*="vjs"]),
	.button,
	.btn {
    font-size: $base-font-size - 2;
  }
}

@media screen and (max-width: $viewport-s) {
  button,
  .button {
    width: 100%;

    &.desktop-primary,
    &.desktop-primary * {
      @include button-bg($primary);
      color: $black;
      box-shadow: 0px 2px 0px 0px darken($primary, 25%);
      border-radius: $base-radius;
      height: auto;

      &:hover {
        background-color: lighten($primary, 4%);
      }

      &:active {
        background-color: darken($primary, 2%);
        box-shadow: none;
        top: 1px;
      }
    }
    &.button-sort {
      background-color: transparent;
      color: $grey-lightest;
      * { color: $grey-lightest;}
      i {
        display: none;
      }
      &.selected {
        background-color: $grey-lightest;
        color: $grey-dark;
        border-color: $grey-lightest;
        * {
          color: $grey-dark;
        }
      }
    }
  }
  .flex-buttons-2 {
    button,
    .button {
      width: 50%;
    }
  }
  .flex-buttons-3 {
    button,
    .button {
      width: 33.333%;
    }
  }
  .flex-buttons-4 {
    button,
    .button {
      width: 25%;
    }
  }
}

// Icon Integration
// @TODO: Should be a better way for this with mixins prehaps or somehow adjusting the icon sheet

.button {
  &.mail, &.notice, &.call {
    &:before {
      font: normal normal normal 18px/1 'icomoonbss';
      speak: none;
      vertical-align: middle;
      margin-right: calc($base-margin / 2);
    }
  }
  &.call:before{
    vertical-align: -3px;
  }
}
