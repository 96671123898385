

.twitter-typeahead {
  width: 100%;
  height: $base-margin + ($base-margin * 0.5);
  margin-right: $base-margin;

  #location {
    padding-left: $base-padding * 3;
  }
}

.Typeahead-selectable {
  background: $grey-lighter;
  color: $grey-dark;
}

.radius-dropdown {
  // the width for value +200km to look good
  width: 350px;
}


.tt-menu {
  right: 0;
  background-color: $white;
  overflow: hidden;

  .tt-dataset {
    .tt-suggestion, .Typeahead-selectable {
      padding: 2px $base-padding;
      background-color: transparent;

      .tt-highlight {
        color: $secondary;
        font-weight: bold;
      }
    }

    .tt-suggestion {
      margin-top: 2px;
    }

    .tt-suggestion {
      margin-bottom: 2px;
    }

    .Typeahead-selectable:hover {
      background-color: $medium-blue;
      cursor: default;
      color : $white;

      .tt-highlight {
        color: $white;
      }
    }
  }
}

.tt-highlight {
  color: $secondary;
  font-weight: bold;
}


@media screen and (max-width: $viewport-s) {
  .radius-dropdown {
    width: 100%;
  }

  .typeahead.Typeahead-hint {
    display: none;
  }

  .form-control.form-section-locations {
    .twitter-typeahead {
      .tt-menu {
        padding-top: 4px;
        padding-bottom: 4px;

        .tt-dataset {
          padding-top: 0;
          padding-bottom: 0;

          .tt-suggestion, .Typeahead-selectable:first-child {
            margin-top: 0;
          }

          .tt-suggestion, .Typeahead-selectable:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.typeahead.dropdown-menu {
  display: grid;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.2);
  padding: 5px 0;
  border-radius: 4px;
  margin-top: -30px;
  @include overlay;
  background-color: white;
  li {
    padding: 5px;
  }
}
