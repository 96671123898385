@media screen and (max-width: $viewport-s) {
  // menu toggled
  body.refinement-showing {
    overflow: hidden;
    height: 100vh;
    // we dont want the sorting to be shown while refining
    .mobile-sort, .category-nav {
      display: none;
    }

    .refinement-filter {
      display: block;
    }

    @include transition;

    .hero .search-form-wrapper form,
    .search-form-wrapper form {
      .mobile-search-title {
        display: flex;
      }
    }
    & > :not(.inline-search):not(.main-header):not(.search-form-wrapper):not(.no-blur) {
      &:not(.search-result) {
        @include transition;
        //filter: blur(.8rem);
        //opacity: .5;
        pointer-events: none;
      }

      &.search-result {
        .form-control, .refinement-form {
          margin: 0;
          padding: 0;
        }

        .refinement-form {
          display: block;
        }

        .notice-board {
          display: none;
        }

      }
    }
  }
}


@media screen and (min-width: $viewport-s+1) {
  // menu toggled
  .refinement-filter {
    transition: all .2s ease-out 0;
    // overlay
    position: absolute;
    padding: 0 $base-padding;
    width: 75%!important;
    right: 88px;
    z-index: 10;
    background: $grey-lightest;
    box-shadow: 0px 0px 0px rgba(0,0,0,.0);
    border: 1px solid $grey-lightest;
    border-bottom-left-radius: $base-radius;
    border-bottom-right-radius: $base-radius;
    pointer-events: none;
    &[aria-hidden="true"] {
      max-height: 0;
    }

    &[aria-hidden="false"] {
      // TODO: Got to find a workaround for setting height after Vue has loaded
      // max-height: var(--containerHeight, 1000px);

      padding: $base-padding;
      max-height: 1000px;
      box-shadow: 0px 10px 10px rgba(0,0,0,.5);
      border: 1px solid $grey-medium;
      transition: all .2s ease-out .5s;
      pointer-events: all;
    }
  }
}
