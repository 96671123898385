.dialog-overlay {
  z-index: 99999999998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.4);
  transition: all .4s ease-out;
  opacity: 0;
  padding: 0 $base-margin;
  &[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0;
  }
}

[role="dialog"] {
  z-index: 99999999999;
  position: relative;
  transition: all .6s ease-out;
  display: flex;
  max-width: 780px;
  background: $white;
  border-radius: 20px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
  }

  .redesign-modal-hero, .modal-hero {
    flex-basis: min-content;
    min-width: 352px;
  }

  .redesign-modal-content, .modal-content {
    position: relative;
    padding: $base-margin*2;
    text-align: center;

    p, h1 {
      margin-bottom: $base-margin*1.5;
    }

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    a.button {
      font-weight: bold;
    }

    /* Tablet and up */

    @media only screen and (min-width:$viewport-s) {
      text-align: left;
      padding: $base-margin * 4;
      p, h1 {
        margin-bottom: $base-margin*2;
        line-height: 1.3em;
      }
    }
  }

  .redesign-modal-close, .modal-close {
    position: absolute;
    top: 0;
    right: $base-margin*2;
    font-family: "Mulish Light", "Mulish", Mulish;
    font-size: 40px;
    line-height: 48px;
    color: #9F9F9F;
  }

  .redesign-modal-logo, .modal-logo {
    max-width: 200px;
    margin: $base-margin 0;
  }

  .redesign-modal-hero, .modal-hero {
    display: none;
    /* Tablet and Up */

    @media only screen and (min-width:$viewport-s) {
      display: block;
    }
  }
}

.sr-only {
  opacity: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.dialog-overlay:not([aria-hidden="true"]),
#redesign-modal:not([aria-hidden="true"]),
.generic-modal:not([aria-hidden="true"]) {
  opacity: 1;
  pointer-events: all;
}
