/* Hacks to fix responsiveness */
@media screen and (min-width: $viewport-s) and (max-width: $viewport-m) {
  .pillars .pillar-container ul.main-list li.pillar-item h2 a {
    font-size: 12px;
  }

  .banner-text-left .banner-wrapper .message {
    font-size: 36px;
    line-height: 48px;
    width: 300px;
  }
}
