.hero-carousel{
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  @media screen and (max-width: $viewport-s){ padding-top: 52px; }

  // Carousel Scrolling
  contain: layout;
  will-change: scroll-position;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  // Carousel Frames and inner styles
  &>div{
    scroll-snap-align: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;

    &>img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    &:empty{display:block;}
    .hero-carousel-content{
      position: absolute;
      width: calc(100% - 48px);
      max-width: 1000px;
      margin: 40px auto 0 auto;
      top: 0;
      left: 0;
      right: 0;

      a{
        @extend .button;
        @extend .primary;
        color: $black;
        display: inline-block;
        width: initial;
        margin-top: $base-margin;
      }
      p{ font-size: 20px; color: $white; }
      strong{
        font-size:40px;
        color: $white;
      }
      @media screen and (max-width: $viewport-s){
        margin-top: $base-margin;
        p{font-size:16px;}
        strong{font-size: 30px;}
      }
    }
  }
}

// Left/Right Scrolling Buttons for desktop
button.hero-left, button.hero-right{
  position: sticky;
  width: 80px;
  padding: 0;
  z-index:1;
  &:before{font: 500 50px / 40px 'icomoonbss';}
  color: $white;
  text-shadow: rgba($black, 0.7) 0px 0px 3px;

  &:hover{
    text-shadow: $black 0 0 10px;
    transition: text-shadow 0.3s ease-in-out;
  }
  @media screen and (max-width: $viewport-m){
    display: none;
  }
}
button.hero-left{
  @extend .bss-icon-arrow-left;
  margin-right: auto;
}
button.hero-right{
  @extend .bss-icon-arrow-right;
}
