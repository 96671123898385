.pillars-accordion {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 5px 5px rgba(0,0,0,.1);
  min-height: 44px;
  .pillar-container {
    position: relative;
    width: 100%;
    max-width: calc(#{$max-width} - #{$base-margin*4});
    margin: 0 auto;
    .menu-toggle {
      display: none;
    }

    .menu {
      list-style: none;
      perspective: 900;
      padding: 0;
      margin: 0;
      background: transparent;
      overflow: visible;
      display: flex;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      transform: rotate(0deg);
      &.flex-between {
        justify-content: space-between;
      }
    }

    .menu-item {
      /* Can't have animation because it resets the stacking context which we need to set for the grandparent so the submenus align. CSS is FUN!
      animation: flipdown .2s ease both; */
      position: relative;
      border-radius: $base-radius $base-radius 0 0;
      background-color: transparent;
      padding-left: $base-padding;
      margin-left: -$base-padding;
      padding-top: $base-padding * 0.5;
      margin-top: -$base-padding * 0.5;
      padding-bottom: $base-padding * 0.5;
      margin-bottom: -$base-padding * 0.5;
      margin-right: $base-padding*0.5;
      cursor: pointer;
      @include overlay(9999);

      h2 {
        position: relative;
        background-repeat: no-repeat;
        margin: 0;
        font-size: $base-font-size - 2;
        font-weight: 500;
        padding: 0 43px 0 0;
        line-height: 43px !important;
        @include transition;

        a {
          font-size: $base-font-size - 2;
          font-weight: 500;
        }

        span {
          font-size: $base-font-size - 1;
          position: relative;
          top: -1px;
        }
      }

      i.bss-icon-chevron-thin-down {
        position: absolute;
        @include transition;
        top: 50%;
        right: $base-padding*1.5;
        padding-right: 0;
        font-size: $base-font-size - 2;
        transform: translate(2px,-50%);
        &:before, &:after {
          @include transition;
        }
      }
    }

    .menu-item-top {
      cursor: pointer;
      @include overlay(9999);

      h2 {
        margin: 0;
        font-size: $base-font-size - 2;
        font-weight: 500;
        padding: 0;
        line-height: 43px !important;
        @include transition;

        &:hover {
          color: $primary;
        }
      }

      &.bereavement-thanks {
        h2 {
          visibility: hidden;
          width: 7ex;
          overflow: hidden;
          &::before {
            content: "Thanks";
            visibility: visible;
          }
        }
      }
    }

    .submenu {
      width: 100vw;
      max-width: calc(#{$max-width} - #{$base-margin*2});
      /* visibility: hidden;  hides sub-menu */
      opacity: 0;
      position: fixed;
      top: 44px;
      transform: translateY(-1em);
      z-index: -1;
      transition: all .1s ease-out .3s; /* mouse out delay */
      left: -($base-margin);
      text-align: left;
      background: $white;
      padding: $base-padding;
      box-shadow: 0 5px 5px hsla(0,0%,60.8%,.4);
      border-radius: 0 0 $base-radius $base-radius;

      .submenu-list {
        column-count: 3;
        column-gap: $base-padding;
      }

      &.col-1 .submenu-list,
      &.col-2 .submenu-list {
        column-count: 1;
      }

      &.col-4 .submenu-list,
      &.col-5 .submenu-list {
        column-count: 2;
      }

      ul {
        padding: 0;
        width: 100%;
      }

      i {
        display: none;
      }

      .submenu-item {
        position: relative;
        width: 100%;

        li {
          div.submenu-title, a {
            font-size: $base-font-size - 2;
            line-height: 40px;
            display: block;
            width: 100%;
            border-radius: $base-radius;
            padding-left: $base-padding;
            &.submenu-title {
              font-size: $base-font-size;
              font-weight: 500;
            }
            &:hover {
              color: $white;
              font-weight: 500;
              background-color: $primary;
              .badge-pill {
                color: $black;
                background: $white;
              }
            }
          }

          .badge-pill {
            position: absolute;
            right: $base-padding;
            top: 12px!important;
            border-radius: 500px;
            pointer-events: none;
          }

          li {
            position: relative;
            border-radius: $base-radius;
            a {
              width: calc(100% - 40px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &:hover {
              background-color: $primary;
              a {
                font-weight: 500;
                color: $white;
              }
              .badge-pill {
                color: $black;
                background: $white;
              }
            }
          }
        }

        li.featured, li.featured * {
          font-weight: bold;
          font-family: $font-bold;
          color: $secondary-dark;
          &:hover {
            color: $white;
          }
        }
      }

      .submenu-top {
        width: 100%;
      }

      .submenu-slider {
        display: none;
      }
    }

    .menu-all {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      transform: rotate(0deg);
      height: 43px;
      &.overflow-only {
        display: none;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -$base-padding;
        width: $base-padding;
        @include overlay(999);
        height: 100%;
        background: linear-gradient(to right, rgba($white, .001), $white);
      }

      .menu-item {
        padding-left: $base-padding;
        padding-right: $base-padding;
        margin-right: -$base-padding;
        margin-left: 0;
        h2 {
          padding-right: $base-padding*2;
        }
        i.bss-icon-chevron-thin-down {
          right: 0;
        }
      }

      .submenu {
        left: unset;
        right: -$base-padding;
        column-count: 3;
        column-gap: $base-padding;
        .submenu-list {
          display: inline-block;
          column-count: 1;
          column-gap: initial;
          .submenu-item {
            display: inline-block;
            margin: 0 0 $base-padding;
            width: 100%;
            i {
              display: none;
            }
          }
        }
      }
    }

    .no-select, h2 {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      user-select: none;
    }
  }
}

/* Max width */

@media screen and (max-width:$max-width) {
  .pillars.pillars-accordion {
    .pillar-container {
      max-width: 100vw;
      padding: 0 $base-padding*2;
      .menu {
        &.flex-between {
          justify-content: flex-start;
        }
        .menu-item {
          .submenu {
            width: 100vw;
            left: -($base-margin*2);
            &.buy-sell .submenu-list {
              column-count: 2;
            }
          }
        }
        .menu-item-top {
          h2 {
            padding: 0 $base-padding*2 0 0;
          }
          &.bereavement-thanks {
            h2 {
              width: 10.5ex;
            }
          }
        }
      }

      .menu-all {
        .menu-item {
          h2 {
            padding-right: $base-padding*4;
            i.bss-icon-chevron-thin-down {
              right: $base-padding*2;
            }
          }

          .submenu {
            left: unset;
            right: 0;
          }
        }
        &.overflow-only {
          display: block;
        }
      }
    }
  }
}

/* Smaller than max-width */

@media screen and (max-width:$viewport-s) {
  .pillars.pillars-accordion {
    border-top: 1px solid rgba(0, 0, 0, .1);
    position: relative;

    .pillar-container {
      .main-list {
        margin-right: 0!important;
        .pillar-item {
          padding: 0;
          margin: 0;
          i.bss-icon-chevron-thin-down {
            top: 4px;
            font-size: $base-chevron-size*0.8;
            color: $grey-dark;
          }
        }
      }

      .menu-all {
        .menu-item {
          .submenu {
            column-count: 2;
            .submenu-item {
              padding: 0;
            }

            .submenu-list {
              display: none;
            }
          }
        }
      }
    }
  }
}


/* Mobile */

@media screen and (max-width:$viewport-xs) {
  .pillars.pillars-accordion {
    border: none;
    .pillar-container {
      padding: 0;
      .menu {
        display: none;
      }

      .menu-all {
        width: 100vw;
        background-color: $black;
        position: relative;
        border: none;
        .menu-item {
          background-color: initial;
          padding: 0;
          right: 0;
          margin-top: 0;
          margin-bottom: 0;

          h2 {
            width: calc(100vw - #{$base-padding*2});
            line-height: 43px!important;
            padding: 0 $base-padding*2;

            i.bss-icon-chevron-thin-down {
              right: 0;
            }
          }

          * {
            color: white;
          }

          .submenu {
            background-color: $black;
            column-count: 1;
            overflow-x: hidden;
            position: absolute;
            top: 100%;
            left: 0;
            visibility: hidden;
            .submenu-item {
              padding: 0;

              li {
                .submenu-title {
                  display: block;
                  color: $white;
                  padding-left: $base-padding;
                  border-radius: $base-radius;
                  font-size: $base-font-size;

                  // Toggle heading, turn off nav and use a toggle instead
                  // @TODO: Consider only applying this to empty href? or a more explict class than nowrap
                  a.nowrap {
                    padding-left: 28px;
                    pointer-events: none;
                  }
                  a.fake-pillar {
                    pointer-events: auto;
                    &:hover {
                      background: transparent;
                    }
                  }
                  &:before {
                    padding-right: $base-padding;
                    display: inline-block;
                    position: absolute;
                    top: $base-padding;
                    text-align: right;
                    width: $base-padding + 4px;
                  }

                  &.buy-sell:before {
                    content: "\e917";
                  }

                  &.trades-services:before {
                    content: "\e92a";
                  }

                  &.jobs:before {
                    content: "\e926";
                  }

                  &.health-beauty:before {
                    content: "\e924";
                  }

                  &.businesses-for-sale:before {
                    content: "\e9ae";
                  }

                  &.real-estate:before {
                    content: "\e925";
                  }

                  &.motoring:before {
                    content: "\e923";
                  }

                  &.notices:before {
                    content: "\e927";
                  }

                  &.travel-entertainment:before {
                    content: "\e92b";
                  }

                  &.personals:before, &.adult-services:before {
                    content: "\e928";
                    // This icon is on a bit of a diet so we will "emBOLDen" him
                    // up a bit to match the weight of the other icons
                    font-weight: bold;
                  }

                  &.pets:before {
                    content: "\e929";
                  }

                  &.garage-sales:before {
                    content: "\e922";
                  }
                }
              }

              .badge-pill {
                color: $white;
                top: 11px!important;
                background-color: #000;
                border-radius: 500px;
                right: $base-padding*3;
                line-height: 1.2em;
                &:hover {
                  color: #000;
                }
              }

              i {
                display: block;
                position: absolute;
                right: 3px;
                top: 12px;
                transform-origin: 33% center;
              }

              * {
                @include transition;
              }

              &.fake-pillar * {
                pointer-events: auto;
              }

              &:hover {
                i {
                  color: $white!important;
                }
              }
            }
          }
        }
      }

      /*-- The Magic --*/

      .menu-toggle:checked ~ .submenu-slider,
      .menu-toggle:checked ~ .submenu {
        visibility: visible;
        opacity: 1;
        transform: translateY(0%);
        @include overlay(999);
        display: block!important;
        .submenu-list {
          display: block!important;
        }
      }

      .menu-toggle:checked ~ label i {
        transform: rotateZ(90deg);
      }
    }
  }
}

@media (max-width: $viewport-xs) and (hover: none) {
    .pillars-accordion .pillar-container .menu-toggle .menu .menu-item .submenu-item li a { transition: unset }
}

/* Desktop */

@media screen and (min-width: $viewport-xs+1) {
  .pillars.pillars-accordion {
    .pillar-container {
      .menu-item {
        cursor: default;
        transition: all .1s ease-out .3s;
        h2 {
          a {
            cursor: default;
            pointer-events: none;
            &:active {
              pointer-events: none;
            }
          }
        }

        .submenu {
          /* pointer-events: none; not set by animation??
          opacity: 0; */
          visibility: hidden;
          animation: delay-pointer-events-out .1s linear .3s; /* mouse in delay */
          animation-fill-mode: forwards;
        }
        /* Hovermenu with delay */

        &:hover,
        &:focus {
          box-shadow: 0 0 5px hsla(0,0%,60.8%,.4);
          background: $white;

          .submenu {
            opacity: 1;
            animation: delay-pointer-events-in .1s linear .2s; /* mouse out delay */
            animation-fill-mode: forwards;
            visibility: visible;
            transform: translateY(0%);
            @include overlay(9);
          }

          i {
            color: $secondary!important;
          }
        }
      }

      .menu-all {
        box-shadow: inset 48px 0 0 0 $white;
        .submenu-item:nth-of-type(1),
        .submenu-item:nth-of-type(2),
        .submenu-item:nth-of-type(3),
        .submenu-item:nth-of-type(4),
        .submenu-item:nth-of-type(5),
        .submenu-item:nth-of-type(6),
        .submenu-item:nth-of-type(7) {
          display: none;
        }
      }
    }
  }
}


/* Dynamically show and hide menu items as they disappear based on viewport width to "move them" into the overflow More menu. The max-width breakpoints may be adjusted in the future to accomodate changes to category name lengths */

// BSS widths
.bss {
  @media screen and (max-width: 958px) {

    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(7) {
            display: inline-block;
          }
          .menu-item {
            h2 {
              padding-right: $base-padding*4;
            }
          }
        }

        .menu {
          .menu-item:nth-of-type(7) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 811px) {
    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(6) {
            display: inline-block;
          }
        }

        .menu {
          .menu-item:nth-of-type(6) {
            display: none;
          }
        }
      }
    }
  }



  @media screen and (max-width: 770px) {
    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(5) {
            display: inline-block;
          }
        }

        .menu {
          .menu-item:nth-of-type(5) {
            display: none;
          }
        }
      }
    }
  }
}

// MyTributes widths
.mytributes {
    @media screen and (max-width: $max-width) {
    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(8) {
            display: inline-block;
          }
        }

        .menu {
          .menu-item-top:nth-of-type(8) {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 988px) {
    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(7) {
            display: inline-block;
          }
        }

        .menu {
          .menu-item-top:nth-of-type(7) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 808px) {
    .pillars.pillars-accordion {
      .pillar-container {
        .menu-all {
          .submenu-item:nth-of-type(6) {
            display: inline-block;
          }
        }

        .menu {
          .menu-item-top:nth-of-type(6) {
            display: none;
          }
        }
      }
    }
  }
}

/* Delay pointer events */

@keyframes delay-pointer-events-in {
  0% {
    z-index: -1;
    pointer-events: none;
  }

  99% {
    z-index: -1;
  }

  100% {
    z-index: 1;
    pointer-events: auto;
  }
}

@keyframes delay-pointer-events-out {
  0% {
    z-index: 1;
    pointer-events: auto;
  }

  99% {
    z-index: 1;
  }

  100% {
    z-index: -1;
    pointer-events: none;
  }
}

@media all and (-ms-high-contrast:none) {
  .pillars-accordion .pillar-container .submenu,
  .pillars-accordion .pillar-container .menu-all .submenu {
    width: auto;
    min-width: 400px;
    position: absolute;
    top: 50px;
  }

  .pillars-accordion .pillar-container .menu-all .submenu {
    top: 0;
    left: -290px;
  }

  .pillars-accordion .pillar-container .submenu .submenu-list,
  .pillars-accordion .pillar-container .menu-all .submenu {
    column-count: 1;
  }
}
