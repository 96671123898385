.filter-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &[aria-hidden="true"] {
    pointer-events: none;
  }
  &[aria-hidden="false"] {
    pointer-events: all;
    display: block;
  }
}
#refinement-search {
  position: relative;
  width: 100%;
  max-width: calc(#{$max-width} - #{$base-margin * 4});
  z-index: 2;
  font-size: $base-font-size; // needed to reset WL

  .refinement-filter {
    .ico-bg:before {
        transform: translateY(32%);
        z-index: 9;
      }

    .refinement-form {
      background: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      input:not([type=checkbox]):not([type=submit]):not([type=radio]) {
        &::placeholder {
          color: $grey-medium;
        }
      }

      label {
        color: inherit;
      }

      form {
        width: 100%;
        max-width: $max-width;
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        .refinement-form__wrapper {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          width: 100%;
          .form-control {
            position: relative;
            width: auto;
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: $base-padding;
            input, select {
              margin-bottom: 0; // reset WL
            }
            select {
              background-color: $white;
              padding: 0;
              font-size: $base-font-size - 2;
              color: $grey-dark;
              height: 52px;
              font-family: $font;
              padding-left: $base-padding;
              padding-right: $base-padding * 3;
            }

            .pika-select-year {
              height: initial;
            }

            input {
              background-color: $white;
              padding: 0;
              font-size: $base-font-size - 2;
              color: $grey-dark;
              height: 52px;
              font-family: $font;
              padding-left: $base-padding;
              text-align: left;
            }

            select::placeholder {
              background-color: $white;
              padding: 0;
              font-size: $base-font-size - 2;
              color: $grey-dark;
              font-family: $font;
              padding-left: $base-padding;
              line-height: 52px;
            }

            input::placeholder {
              background-color: $white;
              padding: 0;
              font-size: $base-font-size - 2;
              color: $grey-dark;
              font-family: $font;
              line-height: 52px;
            }

            label {
              font-size: 11px;
              font-weight: normal;
              letter-spacing: 1px;
              line-height: 2.73;
              padding-bottom: 4px;
            }

            .bss-icon-chevron-down {
              padding-right: 0;
              &.drop-down-arrow {
                top: 61%;
                z-index: 1;
              }
            }

            .ranges {
              flex-wrap: nowrap;
              input {
                width: calc(50% - #{$base-margin * 0.125});
              }

              div {
                margin: 0;
                padding-left: 0;
                padding-right: $base-margin * 0.25;
                width: auto;
                line-height: 52px;
                font-weight: bold;
              }
            }

            // Vue overrides

            .vue-treeselect {
              background: $white;
              height: 52px;
              .vue-treeselect__input-container {
                input {
                  background: transparent;
                }
              }

              .vue-treeselect__menu-container {
                z-index: 9999!important; // vue is stupid and adds its own inline z-index
              }

              .vue-treeselect__control {
                background: $white;
                height: 52px;
                border-radius: 0;
              }

              .vue-treeselect__single-value {
                font-size: $base-font-size - 2;
                line-height: 52px;
                padding-left: $base-padding;
              }

              .vue-treeselect__input::placeholder,
              .vue-treeselect__placeholder {
                background-color: transparent;
                font-size: $base-font-size - 2;
                color: $grey-dark;
                font-family: $font;
                line-height: 52px;
              }

              .vue-treeselect__placeholder {
                padding-left: $base-padding;
              }

              .vue-treeselect__x-container {
                width: 36px;
                animation: none;
                .vue-treeselect__x {
                  width: 16px;
                  height: 16px;
                  background: $white;
                  padding: 2px;
                  z-index: 9;
                }
              }
            }

            &.error {
              p {
                color: $red;
                font-size: $base-font-size - 2;
                padding-top: $base-margin * 0.5;
              }
            }
          }

          .col-grid-layout {
            flex: 1 0 33.33%;
            padding-right: $base-margin * 0.25;
            margin: 0;
          }

          .col-grid-layout-half {
            flex: 1 0 16.665%;
            padding-right: $base-margin * 0.25;
            margin: 0;
          }


          .col-grid-layout:nth-child(3), .col-grid-layout:nth-child(6), .col-grid-layout:nth-child(9) {
            padding-right: 0;
          }

          .col-grid-layout:last-child {
            padding-right: 0;
          }

          &::after {
            content: '';
            flex-grow: 1000000000;
          }
        }

        .form-footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: base-margin;
          button {
            margin-right: 0;
          }
        }

        .form-search-results {
          margin-left: auto;
          margin-right: $base-margin * 2;
          font-size: $base-font-size - 2;
        }
      }

      &.desktop {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

      input:not([type=checkbox]):not([type=submit]):not([type=radio]),
      textarea,
      select,
      label {
        border: 0;
        box-sizing: border-box;
        @include fonts(normal, $base-font-size);
      }

      .vue-treeselect__control {
        border: none;
      }

      .vue-treeselect__control-arrow-container {
        display: none;
      }

      .vue-treeselect__placeholder,
      .vue-treeselect__control,
      .vue-treeselect--searchable .vue-treeselect__input-container {
        padding: 0;
      }

      .vue-treeselect__placeholder {
        line-height: 46px;
      }

      .form-control {
        margin-bottom: $base-margin*2;
        border-bottom: 1px solid $grey-light;
      }

      .form-section-submit {
        * {
          margin-bottom: $base-margin*2;
        }

        .clear {
          color: $grey-medium;
        }
      }

      .form-section-location {
        .scroll-content {
          margin-top: $base-margin;
          padding: $base-margin 0;
          border-top: 1px solid $grey-light;
        }
      }

      .scroll-content {
        ul {
          max-height: 209px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 14px;
          }

          &::-webkit-scrollbar-track {
            background: rgba($grey-medium, .3);
          }

          &::-webkit-scrollbar-thumb {
            background: rgba($grey-medium, .56);
          }

          &::-webkit-scrollbar-thumb:hover {
            background: $grey-medium;
          }
        }

        * {
          @include fonts(normal, 12px);
        }

        li {
          margin-bottom: $base-margin*0.5;

          a {
            justify-content: space-between;
          }
        }

        .bubble {
          color: $primary;
          padding-right: $base-margin;
        }
      }

      .ranges {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
          width: 20%;
          margin: auto;
        }

        input[type="text"] {
          width: 40%;
          margin: auto;
        }
      }
    }
  }
}

// DESKTOP
@media screen and (min-width: ($viewport-s)+1) {

  [type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  #refinement-search {
    top: -6px;
    .refinement-form__close-mobile,
    .mobile,
    .sm-filter {
      display: none;
    }
    .refinement-filter {
      &::before {
        content: '';
        border: 5px solid transparent;
        @include overlay(9999);
        top: -5px;
        border-top-width: 0;
        border-bottom-color: $grey-medium;
        right: 37px;
        line-height: 1;
        user-select: none;
        pointer-events: none;
        position: absolute;
        display: block;
      }

      .refinement-form {
        form {
          .form-control {
            label {
              color: $grey-dark;
            }
          }

          .form-footer {
            button {
              width: auto;
              text-transform: uppercase;
              margin-right: $base-margin * 0.25;
              &.primary {
                color: $white;
                background-color: $black;
                box-shadow: none;
                font-weight: 500;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

// DESKTOP LESS THAN MAX-WIDTH
@media only screen and (min-width: $viewport-s+1) and (max-width: $max-width) {
  #refinement-search {
    .refinement-filter {
      width: calc(100% - #{$base-padding *2})!important;
      right: $base-padding;
      &::before {
        right: auto;
        left: calc(100% - 118px);
      }
    }
  }
}

// MOBILE
@media screen and (max-width: $viewport-s) {
  #refinement-search {
    background-color: $black;
    width: 100%;
    padding: $base-padding;
    justify-content: space-between;
    border-bottom: solid 1px $grey-light;

    .bss-icon-chevron-down.drop-down-arrow {
      @include overlay(99);
    }

    #show-refinement-btn {
      display: none;
    }

    .sm-filter {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;

      * {
        @include fonts(normal, ($base-font-size)-2);
      }

      .refine-button, .mobile-sort {
        background-color: $black;
        color: $white;
        align-items: center;
        line-height: 1;
        margin-top: 0;
        top: 0;
      }

      .refine-button {
        border: 1px solid rgba(255,255,255,.4);
        border-radius: $base-radius;
        cursor: pointer;
        padding: $base-padding 8px $base-padding 13px;
        display: block;
        i {
          font-size: 18px;
          margin-right: $base-margin;
          top: 3px;
          width: 16px;
          height: 16px;
          color: $white;
        }
        &:before {
          right: $base-padding*0.5;
        }
      }

      .mobile-sort {
        padding-top: $base-padding;
        display: flex;
      }
    }
    .refinement-filter {
      margin-top: 0;
      padding: 0;
      .refinement-form {
        form {
          width: 100vw;
          position: fixed;
          @include overlay(9999);
          top: 0;
          min-height: 100vh;
          height: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          transform: translateX(100%);
          margin-bottom: 0;

          .refinement-form__wrapper {
            padding: $base-padding $base-padding 0 $base-padding;

              .col-grid-layout {
                flex: 1 0 50%;
              }

              .col-grid-layout-half {
                flex: 1 0 25%;
              }

              .col-grid-layout:nth-child(odd) {
                padding-right: $base-margin * 0.25;
              }

              .col-grid-layout:nth-child(even) {
                padding-right: 0;
              }

              .vue-treeselect label {
                color: $grey-dark;
              }
            }

          .form-control {
            border-radius: 0;
            label {
              color: $white;
              font-size: ($base-font-size)-2;
            }

          }

          .form-footer {
            flex-direction: column;
            margin-bottom: auto;
            padding: $base-padding;

            button.grey {
              color: $white;
            }

            .form-search-results {
              color: $white;
              margin-right: auto;
              padding-top: $base-padding * 0.5;
            }
          }

          &.refinement-form__show {
            animation-name: showForm;
            animation-duration: .3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            overflow-y: scroll;
            background-color: $black;
          }

          &.refinement-form__hide {
            animation-name: hideForm;
            animation-duration: .3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
          }
        }
      }

      .form-section-submit {
        width: 100%;
      }
    }
  }

  .search-form-wrapper {
    background-color: $black;
    .refinement-search {
      max-width: 100%;
      padding-left: $base-padding * 2;
      padding-right: $base-padding * 2;
      background-color: $black;
    }
  }

  .refinement-form__close-mobile {
    @include overlay(1000);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    margin-top: auto;
    padding-right: 12px;

    .bss-icon-close {
      color: $white;
      font-weight: bold;
      font-size: 24px;
      padding-right: 0;
      padding-left: 6px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .refinement-showing #bss-search-form,
  .refinement-showing #tributes-search-form,
  .refinement-showing .pillars {
      opacity: 0;
      pointer-events: none;
  }
}

// @HACK: This should work for WL now but should be in myt only code
@at-root{
  @media screen and (max-width: $viewport-s) {
    .tributes {
      #refinement-search {
        .refinement-filter {
          .form-control {
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid $white;
          }
        }
      }
    }
  }

  // @HACK: To override !important on .desktop
  // Still shows offscreen and has some weird effects when/showing hiding that should
  // probably be sorted together with the other hacks for this module
  #refinement-search .refinement-form.desktop{ display: flex !important; }
}


// MOBILE SMALL HEIGHT
@media screen and (max-width: $viewport-s) and (max-height: 721px) {
  #refinement-search {
    .refinement-filter {
      .refinement-form {
        form {
          .refinement-form__wrapper {
            padding-top: 0;
          .form-control {
            padding-bottom: 0;
              label {
                padding-bottom: 0;
                margin-bottom: 0;
                margin-top: 2px;
              }
              input,
              select,
              .vue-treeselect .vue-treeselect__control,
              .vue-treeselect {
                height: 33px;
                &::placeholder, .vue-treeselect__placeholder {
                  line-height: 33px;
                }
              }
              .vue-treeselect .vue-treeselect__single-value {
                line-height: 33px;
              }
              .bss-icon-chevron-down.drop-down-arrow {
               top: 73%;
              }

            }
          }
          .mobile-search-title {
           padding: $base-padding*0.5 0;
          }
          .form-footer button {
            padding: $base-padding*0.5;
            margin: $base-margin*0.5 0;
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $viewport-s) and (max-height: 600px) {
  #refinement-search {
    .refinement-filter {
      .refinement-form {
        form {
          .refinement-form__wrapper {
            padding-top: 0;
            .col-grid-layout:nth-of-type(6) {
            display: none;
           }
          }
        }
        .form-footer {
          padding: 0 $base-margin!important;
          button {
            margin-bottom: 0!important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $viewport-s) and (max-height: 670px) {
  #refinement-search {
    .refinement-filter {
      .refinement-form {
        form {
          .refinement-form__wrapper {
            padding-top: 0;
            .col-grid-layout:nth-of-type(7) {
            display: none;
           }
          }
        }
      }
    }
  }
}

// SMALL SCREEN MOBILE
@media screen and (max-width: 400px) {
  #refinement-search {
    .sm-filter {
      flex-direction: column;
      .refine-button, .mobile-sort, .shortlist {
        margin: $base-margin 0 0 0;
        width: 100%;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .refinement-filter {
      .refinement-form {
        form {
          .refinement-form__wrapper {
            .col-grid-layout {
              flex: 1 0 100%;
              &:nth-child(odd) {
                padding-right: 0;
              }
            }
            .col-grid-layout-half {
              flex: 1 0 50%;
            }

          }
        }
      }
    }
  }
}


@keyframes showForm {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes hideForm {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
