// Modern animated tooltips
/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .95em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 9000; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: $base-radius;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, .35);
  background: rgba($white, .95);
  color: $black;
  z-index: 9001; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after,
[tooltip].flash::before,
[tooltip].flash::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgba($white, .95);
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: rgba($white, .95);
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: rgba($white, .95);
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: rgba($white, .95);
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .95;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .95;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip].flash::before,
[tooltip].flash::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out 1.5s forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out 1.5s forwards;
}


// Copied from the compiled less from bootstrap 3.
// modified min-width from 50 to 250
.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  min-width: 250px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tooltip.top {
  margin-top: -3px;
}
.tooltip.right {
  margin-left: 3px;
}
.tooltip.bottom {
  margin-top: 3px;
}
.tooltip.left {
  margin-left: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #d9edf7;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #d9edf7;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #d9edf7;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #d9edf7;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #3a87ad;
  text-align: left;
  text-decoration: none;
  background-color: #d9edf7;
  border-radius: $base-radius;
}
.tooltip-inner ul {
  margin: 0;
}
.tooltip-inner ul li {
  margin: 0;
  list-style: inherit;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.insideTooltip,
*[rel="tooltip"][data-placement~="in"] {
  position: relative;
}
.insideTooltip .tooltip,
*[rel="tooltip"][data-placement~="in"] .tooltip {
  margin-top: -5px;
  min-width: 215px;
}
.error .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #f2dede;
}
.error .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #f2dede;
}
.error .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #f2dede;
}
.error .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #f2dede;
}
.error .tooltip-inner {
  background-color: #f2dede;
  color: #b94a48;
}
.tooltip-alt-primary .tooltip.top .tooltip-arrow,
.tooltip-alt-danger .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #d4d4d4;
}
.tooltip-alt-primary .tooltip.top .tooltip-arrow::before,
.tooltip-alt-danger .tooltip.top .tooltip-arrow::before {
  content: '';
  z-index: 5;
  top: -8px;
  bottom: 0;
  left: 50%;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #eeeeee;
}
.tooltip-alt-primary .tooltip.left .tooltip-arrow,
.tooltip-alt-danger .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #d4d4d4;
}
.tooltip-alt-primary .tooltip.left .tooltip-arrow::before,
.tooltip-alt-danger .tooltip.left .tooltip-arrow::before {
  content: '';
  z-index: 5;
  top: -8px;
  top: 50%;
  right: 0;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #eeeeee;
}
.tooltip-alt-primary .tooltip.bottom .tooltip-arrow,
.tooltip-alt-danger .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d4d4d4;
}
.tooltip-alt-primary .tooltip.bottom .tooltip-arrow::before,
.tooltip-alt-danger .tooltip.bottom .tooltip-arrow::before {
  content: '';
  z-index: 5;
  top: -8px;
  top: 0;
  left: 50%;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #eeeeee;
}
.tooltip-alt-primary .tooltip.right .tooltip-arrow,
.tooltip-alt-danger .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #d4d4d4;
}
.tooltip-alt-primary .tooltip.right .tooltip-arrow::before,
.tooltip-alt-danger .tooltip.right .tooltip-arrow::before {
  content: '';
  z-index: 5;
  top: -8px;
  top: 50%;
  left: 0;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #eeeeee;
}
.tooltip-alt-primary .tooltip-arrow,
.tooltip-alt-danger .tooltip-arrow,
.tooltip-alt-primary .tooltip-arrow::before,
.tooltip-alt-danger .tooltip-arrow::before {
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip-alt-primary .tooltip-inner,
.tooltip-alt-danger .tooltip-inner {
  border: 1px solid #d4d4d4;
  background-color: #eeeeee;
  color: #3e9acd;
}
.tooltip-alt-danger .tooltip-inner {
  color: #E4532B;
}


// TOOLTIP
// ------=

.tooltip-inner {
    position: absolute;
    padding: $base-padding*0.25;
    color: $error-colour;
    text-align: left;
    text-decoration: none;
    font-size: ($base-font-size)-6;
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
}
