.cta-promo-section {
  background-color: $white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-promo-image {
  position: absolute;
  bottom: -55px;
  left: -93px;
  z-index: 1;
  width: 300px;
  height: auto;
}

.promo-outer-container {
  width: 50%;
  max-width: $max-width * 0.5;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: $base-padding*2;
  padding-top: $base-padding*14;
  padding-bottom: $base-padding*14;

  .promo-inner-container {
    width: 100%;
    margin-right: auto;

    .promo-text {
      font-size: 34px;
      font-weight: 200;
      padding-bottom: $base-padding;
    }

    .promo-text-bold {
      font-weight: 700;
    }
  }
}

.cta-promo-outer-container {
  width: 50%;
  max-width: $max-width * 0.5;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: $base-padding*14;
  padding-bottom: $base-padding*14;

  .cta-promo-inner-container {
    width: 80%;
    margin-left: auto;
    padding-right: $base-padding*2;

    .cta-promo-list {
      margin: 0;
      padding: 0;
      font-weight: 600;

      .cta-promo-href {
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: $base-font-size - 2px;

        .cta-list-icon {
          top: 0;
          padding-right: 0;
        }

        .cta-icon {
          margin-right: $base-margin*2;
          font-weight: 600;
          max-width: $base-font-size;
          font-size: $base-font-size + 2px;
        }

        .bss-icon-house {
          color: $pink;
        }

        .bss-icon-car {
          color: $blue;
        }

        .bss-icon-clothes {
          color: $yellow;
        }

        .cta-promo-chevron {
          margin-left: auto;
          font-weight: 600;
        }
      }
    }

    .cta-promo-list > li:not(:last-child) {
      border-bottom: 1px solid $grey-lighter;
    }

    .cta-promo-list > li {
      padding-top: $base-padding * 2;
      padding-bottom: $base-padding * 2;
    }
    .cta-promo-list > li:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1420px) {
  .cta-promo-image {
      display: none;
    }
}

@media only screen and (max-width: 960px) {
  .promo-outer-container {
    .promo-inner-container {
      .promo-text {
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 824px) {
  .promo-outer-container {
    .promo-inner-container {
      .promo-text {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (max-width: $viewport-s) {
  .cta-promo-section {
    flex-direction: column;
    margin-bottom: $base-margin * 2;
  }

  .promo-outer-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 0;
    padding-top: $base-padding;
    padding-bottom: 0;

    .promo-inner-container {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 48px);
      margin-left: auto;

      .promo-text {
        padding-right: 0;
        font-size: $base-font-size + 8px;
        width: 100%;
      }
    }
  }

  .cta-promo-list > li {
    padding-top: $base-padding;
    padding-bottom: $base-padding;
  }

  .cta-promo-outer-container {
    width: 100%;
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;

    .cta-promo-inner-container {
      margin-right: auto;
      padding-right: 0;
      width: calc(100% - 48px);
    }
  }
}

@media only screen and (max-width: 360px) {
  .promo-outer-container {
    .promo-inner-container {
      .promo-text {
        font-size: $base-font-size + 4px;
      }
    }
  }
}
