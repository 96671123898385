@font-face {
  font-family: 'galleriaicons';
  src: url('fonts/galleria/galleriaicons.eot?43225224');
  src: url('fonts/galleria/galleriaicons.eot?43225224#iefix') format('embedded-opentype'), url('./fonts/galleria/galleriaicons.woff?43225224') format('woff'), url('./fonts/galleria/galleriaicons.ttf?43225224') format('truetype'), url('fonts/galleria/galleriaicons.svg?43225224#galleria-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  background-color: $background-secondary;
  scroll-behavior: smooth;
}

html, body {
  min-height: 100%;
  overflow-x: hidden;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  // @TODO this is causing issues on local when we don't have the top picks seciton visible on MYT
  background-color: $background!important;
  line-height: normal;
  margin: 0;
  padding: 0;
}

// These are required to be out of scope to fix fonts and fullscreen for the slider
.galleria-icon {
  font-family: "galleriaicons"!important;
}

.galleria-container.fullscreen {
  z-index: 9999999!important;
}

.galleria-theme-classic.fullscreen {
  background: #222;
}
.galleria-theme-classic.fullscreen .galleria-stage {
  top: 0;
}
.galleria-theme-classic.fullscreen .galleria-thumbnails-container {
  padding: 10px 0!important;
  background-color: transparent !important;
  height: 60px;
  .galleria-thumbnails-list {
    margin: 0 64px;
    &:before,
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      height: 100%;
      width: $base-margin*2;
      @include overlay(2);
    }
    &:before {
      background: linear-gradient(to right, rgba(34, 34, 34, 1), rgba(34, 34, 34, .001));
      left: 0;
      @include transition;
    }

    &:after {
      background: linear-gradient(to left, rgba(34, 34, 34, 1), rgba(34, 34, 34, .001));
      right: 0;
    }
  }
}

.galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-left.disabled + div:before {
  opacity: 0;
}

.galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-right i {
  right: $base-margin*2;
}
.galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-left i {
  left: $base-margin*2;
}

.galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-left i, .galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-right i {
  line-height: 44px;
}

.galleria-theme-classic.fullscreen .galleria-details {
  display: none;
}

.galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-left, .galleria-theme-classic.fullscreen .galleria-thumbnails-container .galleria-thumb-nav-right {
  width: 57px;
  height: 50px;
  color: $grey-medium;
  background-color: transparent !important;
  border: none;
  background-image: none;
  margin-top: 10px;
}

.no-focus-outline a:focus,
.no-focus-outline a:active,
.no-focus-outline button:focus,
.no-focus-outline button:active,
.no-focus-outline input:focus,
.no-focus-outline input:active,
.no-focus-outline search:focus,
.no-focus-outline search:active,
.no-focus-outline select:focus,
.no-focus-outline select:active,
.no-focus-outline textarea:focus,
.no-focus-outline textarea:active,
.no-focus-outline :focus,
.no-focus-outline input:focus:required:invalid:focus,
.no-focus-outline search:focus:required:invalid:focus,
.no-focus-outline select:focus:required:invalid:focus,
.no-focus-outline textarea:focus:required:invalid:focus {
  outline: none;
  box-shadow: none!important;
}

@-moz-document url-prefix() {
  .no-focus-outline input::-moz-focus-inner,
  .no-focus-outline button::-moz-focus-inner,
  .no-focus-outline select::-moz-focus-inner,
  .no-focus-outline search::-moz-focus-inner,
  .no-focus-outline textarea::-moz-focus-inner {
    outline: none;
    border: 0;
    box-shadow: none!important;
  }
  ::-moz-focus-inner {
    border: none
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0px 0px 0px #000;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.mytributes .listing-description .listing .listing-description strong {
  font-weight: bold;
}

// Added for where tc/lc isn't loaded
.offscreen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.modal {
  display: none;
}

@media screen and (min-width: $viewport-s+1) {
  .refinement-showing {
    .search-form-wrapper {
      form {
        .refine-button {
          background: $primary!important;
          i {
            color: $grey-dark!important;
          }
        }
      }
    }
  }
}
