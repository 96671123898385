section {
  &.search-result {
    > .flex {
      justify-content: space-between;
    }
  }

  &.pillars {
    border: 0;
  }

  &.bottom-section {
      border: 1px solid $grey-light;
      padding-top: $base-padding*2;
  }
}



@media screen and (max-width: $viewport-s) {
  section {
    &.search-result {
      > .content-wrapper {
        padding: 0 $base-margin*2;

        // @NOTE: Move the sidebar on search results under the footer
        .flex > .fixed-column.sm-100 {
          margin-top: $base-margin;
          order: 2;
        }
      }
    }
    &.bottom-section {
        border: none;
        display: none;
    }
  }
}
