// These classes should override previous classes without the need for !important

.hidden {
  visibility: hidden;
}

.white {
  color: $white;
}

.grey-light {
  color: $grey-light !important;
}

.bold {
  font-family: $font-bold;
  font-weight: 600;
}

div.centered {
  margin: 0 $base-margin*2;
}

.full-width {
  width: 100%;
}

.centered-block {
  display: block;
  margin: 0 auto;
}

.center {
  display: block;
  text-align: center;
  position: absolute;
  left: $base-padding*10;
  right: $base-padding*10;
}

.align-center,
.center-align {
  text-align: center;
}

.bottom {
  position: absolute;
  bottom: 0;
}

/* Mostly used to control ad heights in noticeboard */
.max-height,
.max-height-third {
  position: relative;
  display: block;
}

.max-height-scroller {
  max-height: $fixed-width;
  margin-bottom: $base-margin;
  padding-bottom: $base-margin*2;
  padding-top: $base-margin*0.5;
  overflow: auto;
}

.max-height-static {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: calc(19px * 5);
  overflow: hidden;

  p {
    display: none;
    text-align: left !important;

    br {
      display: none;
    }
  }

  p:nth-of-type(1),
  p:nth-of-type(2),
  p:nth-of-type(3),
  p:nth-of-type(4),
  p:nth-of-type(5) {
    display: block
  }

  ul {
    margin-top: 0;

    li {
      margin-bottom: 0;
    }
  }
}

.max-height-third .max-height-static {
  -webkit-line-clamp: 3;
  max-height: calc(19px * 3);
  overflow: hidden;

  p {
    display: none;
  }

  p:nth-of-type(1),
  p:nth-of-type(2),
  p:nth-of-type(3) {
    display: block
  }
}

.md-25 {
  width: 25%;
}

.md-30 {
  width: 30%;
}

.md-35 {
  width: 35%;
}

.md-45 {
  width: 45%;
}

.md-50 {
  width: 50%;
}

.md-55 {
  width: 55%;
}

.md-65 {
  width: 65%;
}

.md-70 {
  width: 70%;
}

.md-60 {
  width: 60%;
}

.float {
  float: left;
}

.margin-bottom-20 {
  margin-bottom: $base-margin*2;
}

.margin-top {
  margin-top: $base-margin;
}

.margin-left {
  margin-left: $base-margin*2;
}

.margin-bottom {
  margin-bottom: $base-margin;
}

.padding-left {
  padding-left: $base-padding;
}

.padding-right {
  padding-right: $base-padding;
}

.border-bottom {
  border-bottom: 1px solid $grey-dark;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix {
  clear: both;
}

.nowrap {
  white-space: nowrap;
}

.smaller,
.smaller * {
  font-size: ($base-font-size)-2 !important;
}

.smallest,
.smallest * {
  font-size: ($base-font-size)-4 !important;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.flex-column-centered {
  @include flex-column-centered();
}

.flex-parent {
  display: flex;
}

.flex-child {
  flex: 1 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-even-spacing,
.flex-vertical-align-top-bottom,
.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-align {
  justify-content: space-between;
  align-content: center;
  margin: auto 0;
}

.fixed-column {
  width: $fixed-width;
}

.flex-column {
  flex-shrink: 1;
  width: calc(100% - (#{$fixed-width} + #{$base-margin*2}));
}

.flex-top-center {
  display: flex;
  justify-content: center;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-direction-toggle {
  flex-direction: row;
}

// Toggling content
.hide,
.display-none {
  display: none !important;
}

.show {
  display: block !important;
}

// MOBILE
@media screen and (max-width: $viewport-s) {
  .mobile {
    display: block !important;
  }

  .mobile-inline {
    display: inline-block !important;
  }

  .desktop,
  .desktop-inline {
    display: none !important;
  }

  .sm-100 {
    width: 100%;
  }

  .sm-50 {
    width: 50%;
    padding: 0 $base-padding*0.5 0 $base-padding;

    a {
      padding: $base-padding;
      font-size: $base-font-size - 2;
    }

    &:last-of-type {
      padding: 0 $base-padding 0 $base-padding*0.5;
    }
  }

  .padding-left,
  .padding-right {
    padding: 0;
  }

  .margin-left {
    margin-left: 0;
  }

  .sm-flex-column-reversed-centered {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .sm-flex-column-reversed {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
  }

  .sm-flex {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .pull-left {
    float: none;
  }

  .pull-right {
    float: none;
  }
}

// DESKTOP
@media screen and (min-width: ($viewport-s) + 1) {

  .mobile,
  .mobile-inline {
    display: none !important;
  }

  .desktop {
    display: block !important;
  }

  .desktop-inline {
    display: inline-block !important;
  }
}

// Bigger than smallest screen mobile
@media screen and (min-width: ($viewport-xs) + 1) {
  .show-for-viewport-xs {
    display: none !important;
  }
}

// DESKTOP MEDIUM
@media screen and (max-width: $viewport-m) {
  .show-for-viewport-m {
    display: inline-block !important;
  }

  .hide-for-viewport-m {
    display: none !important;
  }
}

// Animations

@keyframes flash {
  0% {
    background: rgba($primary, 0);
    box-shadow: 0 0 0 rgba($primary, 0);
  }

  50% {
    background: rgba($primary, 1);
    box-shadow: 0 0 10px rgba($primary, .9);
  }

  0% {
    background: rgba($primary, 0);
    box-shadow: 0 0 0 rgba($primary, 0);
  }
}

// SMALL SCREEN MOBILE
@media screen and (max-width: $viewport-xs) {
  .show-for-viewport-xs {
    display: inline-block !important;
  }

  .hide-for-viewport-xs {
    display: none !important;
  }
}

@media screen and (max-width: 453px) {

  .sm-50,
  .sm-50:last-of-type {
    width: 100%;
    padding: 0 $base-padding;
  }
}
