#admin-ui {
	position: fixed;
	bottom: 40px;
	left: -180px;
	width: 180px;
	min-width: 140px;
	min-height: 30px;
	border-right: 2px solid #111;
	border-bottom: 2px solid #111;
	background-color: #fefefe;
	box-shadow: 0 0 5px #111;
	z-index: 2147483647; // @NOTE: I think we are over 9000 :thinking:
  transition: ease-in-out 0.5s;

  &.open{left: 0}

  p, ul, li {
	  margin: 0 0 6px 0;
	  padding: 0;
	  font-size: 13px;
	  line-height: 1;
	  list-style: none;
  }
  a {
	  text-decoration: none;
	  color: #36c;
  }
  a:hover {
	  border: 0 none;
	  text-decoration: underline;
  }

  a.external{
    float:right;
    font-size:16px;
  }
}

#admin-ui-toggle {
	position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
	right: -37px;
	bottom: 8px;
	width: 36px;
	height: 30px;
	border-top: 1px solid #111;
	border-right: 1px solid #111;
	border-bottom: 1px solid #111;
	border-left: 0;
  border-radius: 0 10px 10px 0;
	background-color: #333;
	box-shadow: 0 0 5px #111;
	color: #efefef;
	font-size: 36px;
	font-weight: bold;
  padding: 0;
  &:focus, &:hover{
    outline: none;
    box-shadow:none;
  }
  &:after{
    display: block;
    content: '»';
    margin-top: -4px; // @HACK: alignment is being annoying
  }
}
#admin-ui.open #admin-ui-toggle:after{
  content:'«'
}

.admin-ui-widget{
  border-top: 2px solid #111;
  .admin-header{
    background-color: #111;
	  color: #fefefe;
	  padding: 2px 8px 5px 10px;
	  font-size: 13px;
	  font-weight: bold;
	  line-height: 1.5;
    label{
      color: #fefefe;
	    font-size: 13px;
	    font-weight: bold;
	    line-height: 1.5;
	    margin-bottom: 0;
    }
  }
  .admin-body{
    margin: 10px 10px 12px;
  }

  // UserWidget
  &.user-widget{
    a{display:block;}
  }

  // OverlayWidget
  &.overlay-widget{
    .admin-body label {
      display: block;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
    }
    input[type="checkbox"]{
      display: inline;
      margin:0 10px 0 0;
      vertical-align: middle;
    }
  }
}

#admin-ui-wrapper{
  position: absolute;
  top: 0;
  left: 0;
}

#admin-overlays{
  div[data-overlay]{
    position: absolute;
    min-height: 18px;
    z-index: 20000;
    background: rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,0.7);
    display: none;

    &.visible{
      display: block;
      transition: 0.2s ease;
    }

    a{
      background: rgba(0,0,0,0.7);
      display:          block;
      font-size:        13px;
      line-height:      20px;
      color:            #FFF;
      padding:          0 4px 2px;
      transition: 0.3s ease;
      &:hover{
        background: rgba(0,0,0,0.9)
      }
      span{
        float: right;
        color: #CCC;
        font-size: 11px;
        line-height: 22px;
      }
    }
  }
}
