.notice-board {
  justify-content: space-between;
  .secondary {
    color: $secondary;
  }

  .show-map,
  .hide-map {
    background: $white !important;
    line-height: 1;
    height: auto;
    width: auto;
    top: 0;
    border-radius: 0;
    box-shadow: none;
    padding: $base-padding;
    font-size: $base-font-size - 4;
    position: absolute;
    right: 0;
  }

  .hide-map {
    border-top-right-radius: $base-radius;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  }

  .notice-board-header {
    align-items: baseline;
    margin-bottom: 0;
    h1 {
      font-size: $base-font-size + 6;
    }
  }

  .notice-block {
    &:hover {
      opacity: 0.9;
    }
    .notice-image {
      background: $grey-lightest;
      img {
        top: $base-margin;
        left: $base-margin;
        right: $base-margin;
        bottom: $base-margin;
      }
    }

    .notice-message {
      margin: 0;
      padding: $base-padding $base-padding 0;
      width: calc(55% + #{$base-margin});

      &.notice-no-image {
        width: 100%;
        .moonbar {
          border-top-left-radius: $base-radius;
        }
      }

      &.notice-no-logo {
        padding: $base-padding $base-padding 0;
      }

      .as-seen-on {
        display: flex;
        align-items: center;
        height: 30px;
        span {
          display: flex;
          align-items: center;
          height: 30px;
          color: $white;
          background-color: $secondary;
          padding: 0 $base-padding;
          margin-right: $base-margin;
          font-size: ($base-font-size)-2;
          border-radius: $base-radius;
        }

        img {
          height: 30px;
          border-radius: $base-radius;
        }
      }

      .location {
        font-size: ($base-font-size)-2;
        color: $secondary-dark;
        padding-bottom: $base-padding;
        margin-top: 0;
      }

      .listing-header,
      .listing-description p:first-child {
        border-bottom: 0 none;
      }

      .listing-description {
        font-size: ($base-font-size)-2;

        p {
          padding: 0;
        }

        table{display:none;}
      }

      .call-to-action {
        position: relative;
        margin: $base-margin 0 ($base-margin * 0.5) 0;
        font-size: ($base-font-size)-4;
        bottom: 0;
        * {
          color: inherit;
        }
      }

      .button {
        font-family: $font;
        font-weight: normal;
        font-size: $base-font-size;
        box-shadow: none;
      }
    }

    &.premium-plus {
      border: 1px solid $secondary;
      background: $secondary;
      border-radius: 3px;
      overflow: hidden;

      .main-interaction {
        color: $white;
      }

      .notice-image {
        background-color: $white;
      }

      .listing-header {
        a {
          transition: all 0.3s;
        }

        a:hover {
          letter-spacing: 1.1;
          color: $white;
        }
      }

      .notice-message {
        * {
          color: $white;
        }

        h2 {
          border: none;
          padding-bottom: $base-margin;
        }

        .call-to-action {
          font-family: $font;
        }

        .moonbar {
          [class^='bss-icon-']:before,
          [class*=' bss-icon-']:before {
            color: $white;
          }
        }

        .contact-bar {
          .only-child {
            transition: all 0.2s;
          }

          & > .button {
            background-color: rgba(255, 255, 255, 0.2);
            &:hover {
              background-color: $white;
              color: $secondary;

              & > i:before {
                color: $secondary;
              }
            }
          }
        }
      }

      .desktop .only-child {
        transition: all 0.2s;
      }
    }

    &.premium {
      border: 1px solid $secondary;
      .notice-message {
        h2 {
          border: none;
          margin-bottom: $base-margin;
          padding-bottom: 0;
        }
      }
    }

    &.enhanced {
      filter: none;
    }

    &.premium-plus,
    &.premium,
    &.enhanced,
    &.standard {
      .notice-message:not(.tributes) {
        padding: $base-padding $base-padding 0;
      }
    }
  }

  /* Special CSS for Real Estate ads (regardless of pillar) */
  .notice-block[data-map-marker*='real-estate'] {
    .notice-image {
      background: $background-secondary;
      text-align: center;
      padding: 0;
      max-height: calc(415px + #{$base-margin * 2});
      overflow: hidden;
      width: 100%;
      order: 1;
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: unset; /* Fixes resizing bug in Chrome */
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: auto;
        height: auto;
        display: inline-block;
        margin: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .notice-message {
      padding: $base-margin $base-margin 0;
      width: 100%;

      h3.price {
        font-size: $base-font-size + 2;
      }

      [class^='bss-icon-'],
      [class*=' bss-icon-'] {
        font-size: $base-font-size + 2;
      }

      .listing-header * {
        word-break: break-word;
      }

      .listing-detail-icons {
        margin-bottom: $base-margin;
        padding-bottom: $base-padding * 0.5;
        border-bottom: 1px solid $grey-light;
        * {
          font-size: ($base-font-size)-2;
        }
      }

      .listing-detail-icons:empty {
        display: none;
      }
    }

    .sm-flex-column-reversed-centered {
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-around;
      align-items: center;
    }

    &.premium-plus {
      .notice-image {
        background: $secondary;
      }
    }
  }
}

@media screen and (max-width: 864px) and (min-width: ($viewport-s)+1) {
  .notice-board {
    .notice-block {
      .notice-image {
        width: 100% !important;
        height: 200px;
        background: none;
      }

      .notice-message {
        width: 100% !important;
      }
    }
  }
}

////////////
// MOBILE //
////////////

@media screen and (max-width: $viewport-s) {
  .notice-board {
    margin-top: $base-margin * 2;
    .notice-board-filter {
      display: none;
    }

    .notice-board-header {
      margin-bottom: $base-margin * 2;
    }

    .notice-block {
      height: auto;
      width: 100%;
      max-width: 100%;
      border: 0;
      .notice-image {
        text-align: center;
        padding: 0;
        max-height: calc(415px + #{$base-margin * 2});
        overflow: hidden;
        order: 1;
        background: none;
        img {
          max-width: 100%;
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
          display: block;
          margin: 0 auto;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .notice-message {
        h1,
        h1 * {
          font-size: $base-font-size + 2;
        }

        .location {
          height: $base-margin * 2;
          background-size: 12px 12px;
          padding-top: 0;
          margin-bottom: $base-margin;
          margin-top: $base-margin * 0.5;
        }

        .listing-header,
        .date {
          font-size: $base-font-size + 2;
          line-height: $base-margin * 2;
        }

        .listing-header {
          margin: $base-margin 0;
        }

        .date {
          margin-top: $base-margin * 0.5;
        }

        .secondary-header {
          margin-bottom: $base-margin;
        }

        .listing-detail-icons,
        .secondary-header {
          margin-right: calc(119px + #{$base-margin});
        }

        .as-seen-on {
          justify-content: flex-start;
          margin-bottom: $base-margin;
        }

        .contact-bar {
          position: relative;
          order: 5;
          padding: 0 !important;
          width: calc(100% + #{$base-margin * 2});
          left: -$base-margin;
          margin: $base-margin 0 0;
          .button {
            border-radius: 0;
            margin: 0;
            padding: $base-padding 0;
            text-align: center !important;
            &:first-child {
              border-bottom-left-radius: $base-radius;
            }

            &:last-of-type {
              border-bottom-right-radius: $base-radius;
            }

            &:hover {
              background-color: $secondary;
            }
          }
        }
      }

      &.premium-plus,
      &.premium,
      &.enhanced,
      &.standard {
        .notice-image,
        .notice-message {
          width: 100%;
          margin-right: 0;
        }

        .notice-message {
          padding: $base-padding $base-padding 0;

          .listing-header,
          .listing-header * {
            margin-top: 0;
            margin-bottom: $base-margin;
            padding-bottom: 0;
          }

          .listing-description,
          .call-to-action {
            display: none;
          }

          &.notice-no-image {
            .listing-description,
            .call-to-action {
              display: block;
              margin-top: $base-margin;
            }
          }

          &.notice-no-logo {
            padding: $base-padding $base-padding 0;
            .listing-description {
              padding-bottom: 0;
            }
          }

          &.notice-no-logo {
            .listing-detail-icons,
            .secondary-header {
              margin-right: 0;
            }
          }

          &.cf-ad {
            .button:last-of-type.desktop {
              display: block;
              width: 100%;
            }

            .listing-header {
              padding-right: 0 !important;
            }
          }
        }
      }

      &.standard {
        border: 1px solid $grey-medium;
      }

      &.premium-plus {
        i:before {
          color: $white;
        }
      }
    }
  }

  .notice-board
    .notice-board-list:not(.real-estate):not(.motoring)
    .notice-image
    img {
    max-height: 200px;
  }

  .notice-board .notice-block.premium-plus .notice-image img {
    left: auto;
    right: auto;
  }

  .notice-board .notice-block.premium-plus .notice-image {
    padding-top: $base-margin;
    padding-bottom: $base-margin;
  }

  .notice-board .notice-block .contact-bar .button:last-of-type.mobile,
  .notice-board .notice-block .contact-bar .button:only-child.email {
    border-left: none;
  }
}

@media screen and (min-width: 349px) and (max-width: $viewport-s) {
  .notice-board-list:not(.real-estate),
  .notice-board-list:not(.motoring) {
    .notice-image {
      padding: $base-padding * 2 $base-padding * 2 0;
      width: auto;
      img {
        border-radius: $base-radius;
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: $viewport-s) {
  .notice-board-list.real-estate,
  .notice-board-list.motoring {
    .notice-image {
      padding: $base-padding * 2 $base-padding * 2 0;
      width: auto;
      img {
        border-radius: $base-radius;
      }
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .notice-board {
    .show-map,
    .hide-map {
      width: 100%;
      margin-bottom: $base-margin;
      position: relative;
    }
  }
}
