.right-arrow::after {
  position: absolute;
  top: calc(50% - 4px);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid $blue-dark;
}

.tabs {
  ul {
    padding-left: 0;
  }
  li {
    border-bottom: 1px solid $grey-light!important;
    a {
      display: block;
      margin: 1px 0 0;
      padding: $base-margin*1.5 $base-margin*2 $base-margin*1.5 0;
      border: none;
      color: $grey-medium;
      &:hover {
        color: $grey-dark;
        &:after {
          border-left-color: $grey-dark;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 22px;
        right: 10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $grey-light;
        clear: both;
      }
    }
    &.active {
      a {
        color: $grey-dark;
        &:after {
          border-left-color: $grey-dark;
        }
      }
    }
  }
}

.tab-pane {
  display: none;
  &:target, &.active {
    display: block;
  }
}