
.location-selector, .form-control.dropdown-outer {
  position: relative;

  input[type=text]{
    cursor: pointer;
    // @HACK: An ID style is overriting us for the default input style!
    font-size: 14px !important;
  }

  ul.dropdown{
    display: none;
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 10;
    background: $white;
    max-height: 250px;
    overflow-y: auto;
    // @HACK: Overriding a generic style? Should remove that !important
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    margin-top: 0;
    padding: 6px 0 0 0;

    // List elements
    li, span {
      display: block;
      width: 100%;
      margin: 0;
      cursor: pointer;
      padding: 0;

      // List variants
      &.record, &.label-toggle{
        padding: 4px 12px;
        user-select: none;
      }
      &.record:hover, &.label-toggle:hover {
        background: $primary-lighter;
      }
      &.record.active {
        background: $primary-alt;
        color: $white;
        b{ color: $white; }
      }
      &.no-select{
        padding: 4px 12px;
        text-align: center;
      }

      // Toggle Arrow
      &.label-toggle:before{
        display: inline-block;
        content: "";
        font-family: "icomoonbss" !important;
        line-height: 1em;
        margin: 0 6px 0 -6px;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        vertical-align: -2px;
      }
      &.open .label-toggle:before{
        transform: rotate(0);
        -webkit-transform: rotate(0);
        vertical-align: -3px;
      }

      // Nested (2nd level)
      ul {
        padding: 0;
        margin: 0;
        display: none;
        li.record, .label-toggle{
          padding-left: 28px;
          font-size: 14px;
        }
      }
      &.open ul{display:block;}
    }
  }

  &:focus-within .dropdown{
    display: block;
  }
}
