// menu toggled
[data-interaction-search-close] {
  display: none;
}

body.search-showing {
  height: 100vh;
  overflow: hidden;

  .mobile-menu .interaction-icon {
    &:after {
      content: "close";
    }
  }

  .inline-search .mobile {
    display: none;
  }

  .inline-search-form {
    display: block;
  }

  .form-group {
    @include flex-column-centered;
  }

  .pillars.pillars-accordion{
    z-index: 9;
  }

  @include transition;
  & > :not(.inline-search):not(.main-header):not(.hero):not(.search-form-wrapper):not(.no-blur) {
    @include transition;
    filter: blur(.8rem);
    opacity: .5;
    pointer-events: none;
  }

  .hero .search-form-wrapper form,
  .search-form-wrapper form {
    background-color: $black!important;
    position: fixed!important;
    top: 0!important;
    z-index: 99999999!important;
    height: 100vh!important;
    .mobile-search-title {
      display: flex;
    }
    * {
      outline: none;
    }
    input:focus:required:invalid:focus, textarea:focus:required:invalid:focus, select:focus:required:invalid:focus {
      border: none;
      box-shadow: none;
    }
  }
}
