.advertiser-details {
  img {
    max-height: 100%;
  }

  .full-address {
    .bss-icon-location {
      font-size: 20px;
      position: relative;
      top: 4px;
    }
  }

  p,
  p * {
      margin-bottom: $base-margin;
      @include fonts(normal, ($base-font-size)-2);
      min-height: 14px;
  }
  .big-phone {
    font-size: $base-font-size + 8;
  }
  .advertiser-logo {
    margin: $base-margin*2 0;
    padding: 0 $base-margin*2;
    text-align: center;
  }

  .schedule {
    ul {
      border-bottom: 1px solid $grey-light;
      border-top: 1px solid $grey-light;
    }

    li {
      border-top: 1px solid $grey-light;
      padding: $base-margin 0;

      &:first-child {
        border-top: 0;
      }

      * {
        @include fonts(normal, ($base-font-size)-2);
        line-height: 20px;
      }
    }
  }
}

.advertiser-details {
  .add-details:not(.detailMap) {
    background: $background-secondary;
    border-radius: $base-radius;
    @include drop-shadow();
    .map-wrapper aside {
      margin-bottom: 0;
    }
    .full-address {
      padding: $base-padding;
    }
    .inspection-times {
      border-bottom: 1px solid $grey-light;
      padding: $base-padding;
      h4 {
        font-size: $base-font-size+2;
        border-bottom: 1px solid $grey-light;
        line-height: $base-margin*2;
        font-family: $font-bold;
        font-weight: bold;
        margin-bottom: $base-margin;
      }
      .inspection-field {
        font-size: $base-font-size - 2;
        margin-bottom: $base-margin;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          font-size: $base-font-size - 2;
          color: $secondary;
        }
      }
    }
    .ad-text-title {
      padding: $base-padding;
    }
    .ad-text-title * {
      font-size: $base-font-size - 2;
      line-height: $base-margin*1.5;
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .advertiser-details {
    margin-left: 0;
    margin-top: $base-margin*2;
    .phone {
      text-align: left;
    }
    .schedule {
      margin-bottom: $base-margin*2;
    }
    .advertiser-logo {
      margin: 0;
      a {
        width: 130px;
        margin: 0 auto;
      }
      button,
      .button {
        margin: $base-margin*2 0;
      }
    }
  }
}
