.redirecting { 
 /* to center the content vertically and horizontally */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;

  .wrapper {
    max-width: 1200px;
    padding: 0 50px;
  }

  h1 {
      display: block;
      text-align: center;
      font-weight: bold;
  }

  .redirecting-grid {
      margin-top: 100px;
      display: grid;
      display:-ms-grid;

      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;

  }
  .redirecting-grid > * {
      /* grid gap not working on ie11 */
      margin: 20px;
  }

  /* IE and edge needs specific positioning - unbelievable */
  .item1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
  }
  .item2 {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
  }
  .item3 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
  }
  .item4 {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
  }
  .item5 {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
  }
  .item6 {
      -ms-grid-row: 2;
      -ms-grid-column: 3;
  }

  img {
      display: block;
      max-height: 100px;
      /* stupid ie stretches */

      width: auto;
      margin: 0 auto;
  }

  .arrow {
      max-height: 50px;
      max-width: 85px;
      margin: auto;
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 16px;
    }
    .arrow {
      max-height: 15px;
    }
    .redirecting-grid {
      margin-top: 50px;
    }
    .redirecting-grid > * {
      font-size: 12px;
      margin: 10px;
    }
  }
}


