@mixin flex-column-centered() {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
}

@mixin fonts($font-weight, $size: $base-font-size) {
  font-size: $size;
  font-weight: $font-weight;
  line-height: 1.3;
}

@mixin drop-shadow($top: 0, $bottom: 0, $radius: 1px, $color: rgba($grey-medium, .6)) {
  filter: drop-shadow($top $bottom $radius $color);
}

@mixin box-shadow($top: 0, $bottom: 0, $radius: 1px, $color: rgba($grey-medium, .6)) {
  box-shadow: $top $bottom $radius $color;
  // @NOTE: This effectivly applies position:relative
  transform: translateZ(0); /*for older browsers*/
  will-change: transform;
}

@mixin headings {
    h1, h2, h3,
    h4, h5, h6 {
        @content;
    }
}

@mixin dont-break-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hover($important: true) {
  &:hover {
    background-color: $secondary;
    @if $important {
      color: $white !important;
    }
    @else {
      color: $white;
    }
    * {
      @if $important {
        color: $white !important;
      }
      @else {
        color: $white;
      }
    }
  }
}

@mixin transition($attr: all, $timing: .1s) {
  transition: $attr $timing ease-out;
}

@mixin overlay($index: 999999) {
  z-index: $index;
}
