.pillars {
  @include overlay(8888);
  background-color: $white;

  .pillar-container {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .pillar {
    width: 50%;
    @include fonts(300, $base-font-size+4);
    margin: 0 $base-margin*2;
    padding: 0;
    
    &:first-child {
      margin: 0 $base-margin*4 0 $base-margin*8;
    }
    
    &:last-child {
      margin: 0 $base-margin*8 0 $base-margin*4;
    }

    i {
      @include fonts(300, $base-font-size);
      position: absolute;
      right: $base-margin;
      top: 50%;
      transform: translateY(-50%);
      padding-right: 0;
    }

    .pillar-item {
      width: 100%;
      min-height: $base-margin*5;
      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        justify-content: space-between;
        align-items: center;
        @include fonts(normal, $base-font-size*2);
        line-height: $base-margin*5;
      }
    }
  }
}

@media screen and (min-width: $viewport-s) and (max-width: $viewport-m) {
  .pillars {
    .pillar-container ul.main-list li.pillar-item h2 a {
         font-size: 12px;
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .pillars {
    .pillar-container {
      flex-flow: column;
      justify-content: flex-start;
    }

    .pillar {
      width: 100%;
      margin: 0!important;
      padding: 0 $base-margin;

      .pillar-item {
        min-height: $base-margin*4;
        a {
          @include fonts(normal, $base-font-size);
          line-height: $base-margin*4;
        }
      }
    } 
  }
  
  .content-wrapper .pillars .pillar {
    width: 100%;
    margin: 0!important;
  }
}
