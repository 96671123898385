.sectionTop {
  background: none;
}
h1 {
  display: block;
  margin: .67em 0;
  background: none;
}

h2 {
  display: block;
  margin: .83em 0;
}

h3 {
  display: block;
  margin: 1em 0;
}

h4 {
  display: block;
  margin: 1.33em 0;
}

h5 {
  display: block;
  margin: 1.67em 0;
}

h6 {
  display: block;
  margin: 2.33em 0;
}

p {
  display: block;
  margin: 1em 0;
}

ul {
  display: block;
  list-style-type: disc;
  margin: 1em 0;
  padding-left: $base-margin*4;
}

ol	 {
  display: block;
  list-style-type: decimal;
  margin: 1em 0;
  padding-left: $base-margin*4;
}

li {
  display: list-item;
}

li li {
  list-style-type: circle;
}

li li li {
  list-style-type: square;
}

table {
  margin: $base-margin auto;
  border: none;
  border-collapse: collapse;
}

td {
  border: none;
  padding: $base-margin*0.5;
}

a {
  color: $primary;
}

.Statedropbtn {
  width: 100%;
}
#purpose {
	appearance: none;
	color: black;
  background-color: gray;
	border: none;
	font-family: inherit;
	outline: none;
  -webkit-appearance: none;
}


img {
  max-width: 100%;
}
