.pagination-dots{

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  padding-top: $base-margin;

  a, button {
    width: 18px;
    height: 18px;
    background: $black;
    padding: 0;
    margin: 0 0 0 $base-margin*2;
    font-size: 3em;
    line-height: .6em;
    border-radius: 50%;

    &:first-child { margin-left: 0; }

    &:active, &.active{
      background: $primary-alt;
      transform: scale(1.5)
    }
  }

  // Large Variant
  &.large{
    font-size: 1.5em;
  }

  // Monocolor
  &.mono{
    a, button{
      color: rgba(255,255,255,0.9);
      &:hover{
        color: $white;
      }
      &:active, &.active{
        color: $black;
        transform: none;
      }
    }
  }

  // Tablet and Desktop
  &.mobile-only{
    @media screen and (min-width: $viewport-s){
      display:none;
    }
  }
}
