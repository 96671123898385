/*
Buy Search Sell Theme v2.0
Last updated: 7 Jan 2020
*/

$font: 'Work Sans', sans-serif;
$font-bold: 'Work Sans', sans-serif;
$font-italic: 'Work Sans', sans-serif;

$base-font-size: 16px;
$base-margin: 12px;
$base-padding: $base-margin;
$base-radius: 3px;
$base-chevron-size: 30px;
$fixed-width: 312px;
$max-width: 1000px;
$feature-carousel-height: 175px;
$form-input-height: 60px;

$black: #2d3033 !default;
$white: #fff !default;
$red: #b94a48 !default;
$pink: #E650A0 !default;
$dark-yellow: #c5820c !default;
$yellow: #F6A81D !default; /* Sunflower */
$yellow-light: #F7E3BF !default;
$yellow-lighter: #FBF1DF !default;
$green-light: #D4EDDA !default;
$blue: #48B4C9 !default; /* Azure */
$blue-darker: #238ea3 !default;
$medium-blue: #297dea !default;
$blue-dark: #32499d !default;
$blue-lighter: #D7EFF3 !default;
$grey-dark: #414042 !default;
$grey-medium: #9B9B9B !default;
$grey-light: #CACABD !default;
$grey-lighter: #F8F8F8 !default;
$grey-lightest: #efefef !default;
$grey-lighter-alpha: rgba(0,0,0,.1) !default;
$facebook: #3b5998 !default;
$twitter: #00aced !default;

$primary: $yellow;
$primary-dark: adjust-color($primary, $lightness: -24%, $saturation: 18%);
$secondary: $blue;
$secondary-dark: adjust-color($secondary, $lightness: -24%, $saturation: 18%);
$tertiary: $grey-dark;
$quaternary: $white;
// @HACK: When reusing things between BSS/MYT, primary seems to be off for BSS
$primary-alt: $blue;
$primary-lighter: $blue-lighter;
$action: $pink;

$background: $grey-lighter;
$background-secondary: $white;
$background-header: $secondary;

$error-colour: $red;
$mobile-search-color: $white;
$section-border-color: transparent !default;
$button-border-color: $white;
$link-color: $secondary;

$viewport-xs: 668px !default;
$viewport-s: 767px !default;
$viewport-m: 929px !default;
$viewport-l: 1744px !default;

// Footer Background Color
$footer-bg-color: $black;

// Quick colours
//

.primary-color {
  color: $primary!important;
}
.secondary-color {
  color: $secondary!important;
}
.pink {
  color: $pink!important;
}
.red {
  color: $red!important;
}
.blue {
  color: $blue!important;
}
.yellow {
  color: $yellow!important;
}

.main-interaction {
    color: $secondary !important;
}

/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
       url('fonts/work-sans-v9-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/work-sans-v9-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('fonts/work-sans-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/work-sans-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('fonts/work-sans-v9-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/work-sans-v9-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('fonts/work-sans-v9-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/work-sans-v9-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// @TODO: Um, we don't even use this font??????
/* Webfont: ModernEra-Regular */
@font-face {
    font-family: 'ModernEra';
    src: url('fonts/modernera/ModernEra-Regular.eot'); /* IE9 Compat Modes */
    src: url('fonts/modernera/ModernEra-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/modernera/ModernEra-Regular.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ModernEra-Bold */
@font-face {
    font-family: 'ModernEraBold';
    src: url('fonts/modernera/ModernEra-Bold.eot'); /* IE9 Compat Modes */
    src: url('fonts/modernera/ModernEra-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/modernera/ModernEra-Bold.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ModernEra-Italic */
@font-face {
    font-family: 'ModernEraItalic';
    src: url('fonts/modernera/ModernEra-Italic.eot'); /* IE9 Compat Modes */
    src: url('fonts/modernera/ModernEra-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/modernera/ModernEra-Italic.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ModernEra-Light */
@font-face {
    font-family: 'ModernEraLight';
    src: url('fonts/modernera/ModernEra-Light.eot'); /* IE9 Compat Modes */
    src: url('fonts/modernera/ModernEra-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/modernera/ModernEra-Light.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Icomoon - BSS variant */
@font-face {
  font-family: 'icomoonbss';
  src: url('~/common/fonts/icomoon_bss.woff2') format('woff2'),
  url('~/common/fonts/icomoon_bss.ttf') format('truetype'),
  url('~/common/fonts/icomoon_bss.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
