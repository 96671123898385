.galleria-theme-classic,
.galleria-theme-classic .galleria-stage {
  background: transparent;
  border-radius: $base-radius;
  top: 0;
  left: 0;
  right: 0;
}

.galleria-icon {
  font-family: "galleriaicons"!important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px!important;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 32px;
  margin: 0!important;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.galleria-theme-classic .galleria-stage .galleria-details, .galleria-errors {
  display: none;
}

.galleria-theme-classic .galleria-stage .galleria-image-nav-left i,
.galleria-theme-classic .galleria-stage .galleria-image-nav-right i {
  display: none;
}

.galleria-theme-classic .galleria-stage .galleria-image-nav-left,
.galleria-theme-classic .galleria-stage .galleria-image-nav-right {
  top:  -31px;
}

.galleria-theme-classic .galleria-stage .galleria-fullScrBtn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 32px;
  @include overlay;
  text-shadow: 0 0 1px #000000;
  color: #ffffff;
  padding: 0 16px;
  cursor: pointer;
  z-index: 9;
}

.galleria-fullScrBtn i:before {
  display: block;
  content: '';
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: $white;
  position: absolute;
  z-index: -1;
  opacity: .8;
  transition: opacity .1s ease-out 0s;
}

.galleria-fullScrBtn:hover i:before {
  opacity: 1;
}

.galleria-fullScrBtn i {
  left: -4px;
}

.galleria-theme-classic .galleria-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent url('./images/gallery-loader.svg') no-repeat 50% 50%;
}

.galleria-theme-classic .galleria-thumbnails-container {
  left: 0;
  right: 0;
  background-color: transparent !important;
  padding: 0;
  .galleria-thumbnails-list {
    margin: 0 $base-margin*2;
    .galleria-thumbnails {
      margin: 0 auto;
    }
    &:before,
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      height: 100%;
      width: $base-margin;
      @include overlay(2);
    }
    &:before {
      background: linear-gradient(to right, $background, rgba($background, .001));
      left: 0;
      @include transition;
    }

    &:after {
      background: linear-gradient(to left, $background, rgba($background, .001));
      right: 0;
    }
  }
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-left.disabled + div:before {
  opacity: 0;
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-left, .galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-right {
  width: 57px;
  height: 50px;
  color: $grey-medium;
  background-color: rgba(0,0,0,.2);
  border-radius: $base-radius;
  border: none;
  background-image: none;
  margin-top: 0;
}
.galleria-theme-classic .galleria-thumbnails-container .galleria-thumbnails .galleria-image {
  height: 45px!important;
  width: 60px!important;
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumbnails .galleria-image img:not([style*="position"]) {
  display: none!important;
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-left {
  text-align: left;
  padding-left: 0;
  background-position: 21px 46px;
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-right {
  text-align: right;
  padding-right: 0;
  background-position: -276px 46px;
}

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-left i, .galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-right i {
  line-height: 47px;
 }

.galleria-theme-classic .galleria-thumbnails-container .galleria-thumb-nav-right i {
  right: 3px;
}

.galleria-theme-classic img {
  border-radius: $base-radius;
}
.galleria-theme-classic .galleria-thumbnails .galleria-image {
  border: 1px solid $grey-medium;
  border-radius: $base-radius;
  filter: drop-shadow(inset 0 0 1px rgba(155, 155, 155, .5));
  transform: translateZ(0); /*for older browsers*/
  will-change: transform;
  background: transparent!important;
}

.slider {
  width: 100%;
  margin-right: $base-margin;

  .large-image {
    text-align: center;

     img {
      max-width: 100%;
      height: auto;
      width: auto;
      margin-bottom: $base-margin*2;
      display: inline-block;
    }
  }

  .thumbnails {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: $base-margin;

    a {
      margin-right: $base-margin;
      width: 25.75%;
    }
  }

  button,
  .button {
    width: $base-margin*1.5;
    padding: $base-margin $base-margin*1.5;

    img {
      position: absolute;
      height: 35px;
    }
  }

  .prev {
    margin-right: $base-margin*1.5;

    img {
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  .next {
    margin-left: $base-margin;

    img {
      top: 50%;
      right: 0;
      transform: translate(0, -50%) rotate(180deg);
    }
  }
}

@media screen and (min-width: ($viewport-s)+1) and (max-width: $viewport-m) {
  .slider {
    .thumbnails {
      a {
        margin-right: $base-margin;
        width: 22.6%;
      }
    }

    button,
    .button {
      width: $base-margin*1.5;
      padding: $base-margin $base-margin*2;

      img {
        position: absolute;
        height: 35px;
      }
    }

    .prev {
      margin-right: $base-margin;
    }

    .next {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .slider {
    button,
    .button {
      width: 10%;
      padding: $base-margin*0.5 $base-margin;
    }

    .thumbnails {
      a {
        margin-right: $base-margin;
        width: 19%;
      }
    }

    .next {
      margin-left: $base-margin;
      margin-right: 0;

      img {
        width: 30%;
      }
    }

    .prev {
      margin-right: $base-margin;

          img {
        width: 30%;
      }
    }
  }

  .galleria-theme-classic .galleria-stage {
    width: 100%;
    min-height: 350px;
  }
}

@media screen and (min-width: $viewport-s+1) {
  .galleria-theme-classic .galleria-stage {
    width: 100%;
    min-height: 450px;
  }
}

@media screen and (min-width: $viewport-l) {
  .slider {
    .next {
      margin-left: 0;
    }
  }
}
