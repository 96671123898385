input:not([type=checkbox]):not([type=submit]):not([type=radio]), textarea, select {
  @include fonts(normal, $base-font-size);
  @include transition();
  box-sizing: border-box;
  width: 100%;
  height: initial;
  border-radius: 0;
  border-bottom: 1px solid $tertiary;
  background: none;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 0 8px;
  box-shadow: none; // Direfox
  outline: none;
  &:focus,
  &:active { border-bottom-color: $primary!important; }
}

input[type=text], input[type=search] {
  &::placeholder {
    color: $tertiary;
  }
}

textarea[data-autosize-on] {
  max-height: 6.4em;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;

  &::-ms-expand {
    display: none;
  }
}
