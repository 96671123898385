.advertiser-details {
  .as-seen-on {
    justify-content: center;
    height: 114px;
    width: 100%;
    background: rgba($primary, .2);
    margin-bottom: $base-margin;
    padding: $base-margin $base-margin*2;
    border-radius: $base-radius;
    font-weight: bold;

    .as-seen-on-header {
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: ($base-font-size)-2;
      margin-bottom: $base-margin;
      width: 100%;
      background-color: rgba($white, .9);
      overflow: hidden;
      border-radius: $base-radius;

      span { margin-right: $base-margin*0.8; }
      div {
        margin-right: $base-margin*0.8;
        text-align: center;
        width: 50%;
        flex-grow: 1;
      }

      img {
        height: 30px;
        margin: 0;
        margin-left: auto;
      }
    }

    .as-seen-on-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: $primary;
      cursor: pointer;

      i {
        margin-right: 5px;
        margin-bottom: 2px;
        color: $white;
      }

      span {
        font-size: ($base-font-size)-2;
        color: $white;
      }
    }
  }
}


@media screen and (max-width: $viewport-s) {
  .listing-details {
    .center-align img {
      max-height: 300px;
    }
    // @HACK: Some fruitloop adds width:86% and some margin crap to tables (ad id: 5705278)
    #sanitised-text > table{width:100% !important;margin-right:0 !important;}
  }
}
