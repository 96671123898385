.accordion {
  .accordion-title {
    @include fonts(normal, $base-font-size);
    &:before {
      @include transition(.3s);
      display: none;
      transform-origin: 36% 50%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .accordion {
    .accordion-title {
      &:before {
        display: block;
      }
      &.show:before {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
    
    .accordion-body {
      height: 0;
      max-height: 0;
      @include transition(.3s);
      overflow: hidden;
      
      .container {
        flex: 0;
        transition: flex .3s ease-out;
      }
      
      &.show {
        height: auto;
        max-height: 600px;
        margin-top: $base-margin*2;
        
        .container {
          flex: 1;
        }
      }
    }
  }
}
