html {
  font-size: $base-font-size;
}

* {
  font-family: $font;
  color: $tertiary;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  line-height: 1.3em;
}

hr {
  border-top: 1px solid $primary;
  margin: $base-margin 0;
  &.white {
    border-top: 1px solid $white;
  }
  &.dim {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    clear: both;
  }
}

.preload * {
  transition: none !important;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
  font-size: 10px;
  margin: 0 !important;
}

section,
figure {
  margin: 0;
  padding: 0;
}

div:empty,
p:empty,
span:not([class*='vjs']):not([class*='bss-icon']):empty {
  display: none;
}

p{font-size:16px;}

.wrapper {
  width: initial !important;
}

.mapArea .map-wrapper .map {
  height: 200px;
  margin-bottom: $base-margin * 2;
}

.mapArea.map-hidden .map-wrapper .map {
  height: 0px;
  margin-bottom: 0px;
}

// these 2 next styles are to align the header on the result page to the left sidebar
// on no map + map scenario
.searchResultsMap {
  margin-bottom: $base-margin * 2;
}

.map-hidden .searchResultsMap {
  margin-bottom: 0;
}

.hideMap.active {
  display: none;
}

.showMap.active {
  display: none;
}

.alert-block {
  padding: $base-margin;
}

.alert-block ul {
  margin-top: calc($base-margin / 2);
}

.alert .close {
  top: -$base-margin;
  right: -$base-margin;
  font-size: 20px;
}

.content-wrapper {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $base-margin * 2;
  position: relative;
}

.content-wrapper-absolute {
  width: calc(100% - #{$base-margin * 4});
  position: absolute;
  bottom: 20px;
  max-width: $max-width;
}

ul {
  list-style-type: none;
  margin-left: 0; // Reset WL
}

ul.list,
ol.list,
.listing-description ul,
.listing-description ol {
  display: block;
  margin-top: calc($base-margin / 2);
  margin-bottom: calc($base-margin / 2);
  margin-left: 0;
  margin-right: 0;
  padding-left: $base-margin * 3;
  li {
    font-size: 14px;
    margin-bottom: calc($base-margin / 4);
  }
}

ul.list,
.listing-description ul {
  list-style-type: disc;
  li{
    list-style-type: disc;
  }
}



img {
  max-width: 100%;
  display: inline-block;
  -moz-force-broken-image-icon: 1;
}

.banner-img {
  width: 100%;
  height: 306px;
  margin-bottom: $base-margin * 2;
  overflow: hidden;
  display: block;
  @include transition;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.hiddenPhone,
[data-click-b64] {
  cursor: help;
}

.main-header {
  .cta-header {
    display: none;
  }
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  margin-left: 0.2em;
  font-size: $base-font-size - 4;
  font-weight: lighter !important;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $base-radius;
  @include transition();
  background-color: $grey-lighter-alpha;
  top: -0.15em !important;
  position: relative;
  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge,
button .badge {
  position: relative;
  top: -0.2em;
}

// Pill badges
//

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

a .badge:hover {
  background-color: $primary;
  color: $white !important;
  cursor: pointer;
}

.authenticated {
  display: none !important;
}

@media screen and (max-width: $viewport-m) {
  .banner-img {
    height: 250px;
  }
}

@media screen and (max-width: $viewport-s) {
  .banner-img {
    height: 200px;
  }

  .call-to-action {
    width: calc(100% - #{$base-margin * 2});
  }

  .light-grey-bg {
    padding: $base-margin ($base-margin + 2);
    margin-bottom: 0;
  }

  .detail-header {
    align-items: center;
  }

  .listing-details img {
    margin-left: auto;
    margin-right: auto;
  }

  .advertiser-logo img {
    max-width: 50%;
  }

  .content-wrapper {
    padding: 0 $base-margin * 2;
  }
}
