.search-form-wrapper {
  border-radius: 0px;
  background-color: $grey-lightest;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  backface-visibility: hidden;
  transition: all .2s;
  &.white-bg {
    background-color: rgba(255, 255, 255, .3);
  }
  *:focus {
    outline: none;
  }
  .desktop-search-header {
    width: calc(100% - 48px);
    text-align: left;
    margin-top: auto;
    margin-bottom: $base-margin;
    font-size: $base-font-size * 2;
    color: $white;
    font-weight: 300;
    padding-top: $base-padding * 3;
    strong {
      color: $white;
      font-family: $font-bold;
      font-weight: bold;
      font-size: $base-font-size * 2;
      margin: 0;
    }
  }
  form,
  .bss-search-form {
    margin-bottom: 0;
    width: calc(1000px - 48px);
    max-width: calc(100% - 48px);
    border: none;
    background-color: transparent;
    padding: 0;
    margin-top: $base-margin;
    margin-bottom: $base-margin;

    .form-submit {
      min-width: 85px;
      margin-left: 3px;
      backface-visibility: hidden;
      height: 52px;

      .submit-button {
        background-color: $black;
        color: $white;
        width: 100%;
        height: 52px;
        overflow: hidden;
        border: none;
        z-index: 10;
        border-radius: 0 $base-radius $base-radius 0;
        font-size: $base-font-size + 4px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        box-shadow: none;
      }

      .submit-button:hover {
        cursor: pointer;
      }
    }
    .mobile-search-title {
      display: none;
    }

    .input-always-show {
      width: 100%;
      max-width: 301px;

      .form-input {
        border: none;
        padding-right: $base-padding;
        font-size: $base-font-size - 2px;
        width: 100%;
      }
    }

    .search-form-field-wrapper {
      background-color: $white;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: $base-padding + 4px;
      padding-bottom: $base-padding + 4px;
      height: 52px;

      .form-select-item {
        background-color: $white;
        border: none;
        width: 100%;
        font-size: $base-font-size - 2px;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: $base-padding * 3;
      }

      .form-select-item.radius-options {
        padding-left: $base-padding;
      }
    }

    .input-toggle-section {
      width: 100%;
      backface-visibility: hidden;
    }

    .input-icon {
      padding-left: $base-padding;
      font-size: $base-font-size;
      color: $grey-light;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 10;
    }

    .select-options {
      margin-left: $base-margin * 0.25;
      padding-right: 0;
      width: 100%;
      min-width: 205px;
      position: relative;
    }

    .refine-button {
      background: $white;
      padding: $base-padding $base-padding $base-padding $base-padding*3;
      display: flex;
      align-items: center;
      margin-left: $base-margin * 0.25;
      height: 52px;
      @include transition;
      cursor: pointer;
      font-size: $base-font-size - 2;
      position: relative;
      &:hover {
        background: rgba($primary, .9);
        i {
          color: $grey-dark;
        }
      }
      &.flash {
        animation: flash 1s 4 ease-out;
        i {
          color: $grey-dark;
        }
      }
    }

    .form-distance {
      max-width: $base-margin * 8;
      min-width: $base-margin * 8;
    }

    .drop-down-arrow {
      position: absolute;
      color: $black;
      z-index: 999;
      top: 51%;
      right: 3px;
      transform: translate(-50%, -50%);
      padding-right: 0;
      pointer-events: none;
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
}

input#bss-search-keywords,
input#locations {
  font-size: $base-font-size - 2px;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  margin-bottom: 0;
  transition: none;
  padding: 0 ($base-margin * 0.5) 0 ($base-margin * 3);
}

input#bss-search-keywords {
  font-size: 14px;
}

.inner-form-content-wrapper {
  display: flex;
  align-items: center;
}

.form-search-keywords::placeholder,
.typeahead::placeholder {
  color: $black;
  opacity: 1;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

.select-typeahead {
  min-width: 230px;
  padding-right: 0;
}

.typeahead {
  border: none;
  font-size: $base-font-size -2px;
  width: calc(100% - 12px);
  margin-top: 0;
}

.tt-menu {
  background-color: $white;
}

.tt-open {
  margin-left: 0;
}

.tt-suggestion {
  font-size: $base-font-size - 2px;
  padding-left: $base-padding;
  padding-right: $base-padding;
}

.Typeahead-input {
  padding-left: ($base-margin * 0.5);
}

@media only screen and (max-width: 950px) {
  .hero .search-form-wrapper,
  .search-form-wrapper {
    form,
    .bss-search-form {
      width: calc(100% - 24px);

      .input-always-show {
        width: 100%;
      }

      .select-typeahead {
        min-width: 145px;
      }
    }
  }

  .desktop-search-header {
    width: calc(100% - 24px);
  }
}

@media only screen and (max-width: 875px) {
  .hero .search-form-wrapper,
  .search-form-wrapper {
    form,
    .bss-search-form {
      .select-typeahead {
        min-width: 120px;
      }

      .select-categories {
        min-width: 170px;
      }
    }

    .input-always-show {
      width: 100%;
      min-width: 160px;
    }
  }
}

@media only screen and (max-width: $viewport-s) {
  .hero .search-form-wrapper,
  .search-form-wrapper {
    margin-top: 0;
    padding-top: 0;
    border-radius: 0;
    width: 100%;
    justify-content: flex-start;
    background-color: transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);

    .desktop-search-header {
      display: none;
    }

    .flex-direction-toggle {
      flex-direction: column;
      z-index: 10;
    }

    form,
    .bss-search-form {
      width: 100%;
      background-color: transparent;
      min-width: 100%;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, .2);
      @include transition;

      &.min-form-height {
        background-color: $black;
        position: fixed;
        top: 0;
        @overlay;
        height: 100vh;
      }

      .refine-button {
        display: none;
      }

      .input-toggle-section {
        .form-submit {
          width: 100%;
          margin-left: 0;
          padding-bottom: 0;
          margin-bottom: $base-margin;

          .submit-button {
            background-color: $primary;
            color: $black;
            border-radius: $base-radius;
            height: auto;
            box-shadow: 0px 2px 0px 0px darken($primary, 25%);
          }
        }
      }

      .input-always-show {
        max-width: 100%;
      }

      .input-toggle-section {
        display: none;
      }

      .display-form {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 24px);
        margin-top: $base-margin;
        margin-bottom: 0;
      }

      .search-form-field-wrapper {
        z-index: 20;
        width: 100%;
        padding: $base-padding;

        div.form-select-item.radius-options {
          padding-left: 0;
        }

        input#bss-search-keywords,
        .form-categories,
        input#locations,
        select#location {
          padding-left: $base-padding * 2;
        }

        input#bss-search-keywords.js-toggle-search-style {
          padding-left: $base-padding * 3;
        }

        .js-toggle-search-style::placeholder {
          color: $black;
          font-weight: 600;
        }

        .js-search-icon {
          padding-left: $base-padding * 2;
          color: $black;
          font-weight: 600;
        }

        .form-select-item,
        input#locations,
        .form-search-keywords::placeholder {
          color: $black;
          font-size: $base-font-size;
        }
      }

      .select-options {
        padding-right: 0;
        margin-left: 0;
        margin-bottom: $base-margin;
        position: relative;
      }

      .select-options.form-distance {
        margin-left: ($base-margin * 0.5);
        max-width: 108px;
        min-width: 108px;
      }

      .mobile-search-title {
        width: 100%;
        padding-top: $base-padding;
        padding-bottom: $base-padding;
      }

      .mobile-form-title {
        color: $white;
        font-weight: 200;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: auto;
        margin-left: $base-margin;
        font-size: $base-font-size + 2;
      }

      .close-mobile-form-icon {
        color: $white;
        margin-right: $base-margin;
        font-size: $base-font-size + 4px;
        padding-right: 0;
      }

      .close-mobile-form-icon:hover {
        cursor: pointer;
      }

      .input-always-show-open {
        width: calc(100% - 24px);
        margin-top: 123px;
      }

      .form-input {
        width: 100%;
        font-size: $base-font-size;
      }

      .form-search-keywords::placeholder {
        color: $black;
      }

      .form-search-keywords {
        padding-left: $base-padding * 3;
      }

      .select-typeahead {
        max-width: 100%;
      }
    }

    .inner-form-content-wrapper {
      width: 100%;
    }
  }
  .hero .search-form-wrapper,
  .search-form-wrapper {
    margin-top: 0;
    padding-top: 0;
    border-radius: 0;
    width: 100%;
    justify-content: flex-start;
    background-color: transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
  }

  input#bss-search-keywords {
    font-size: $base-font-size;
  }

  .mytributes {
    .hero .search-form-wrapper,
    .search-form-wrapper {
      form,
      .bss-search-form {
        .input-always-show-open {
          margin-top: 85px;
        }
      }
    }
  }
}

.clearable-input {
  position: relative;
  display: inline-block;
}
.clearable-input input {
  padding-right: 1.4em;
}
.clearable-input [data-clear-input] {
  display: none;
  position: absolute;
  right: 0;
  font-weight: 100;
  color: $white;
  background: $grey-light;
  border-radius: 50%;
  font-size: 1em;
  padding: 0 .2em;
  line-height: 1em;
  cursor: pointer;
  transform: translate(-50%, 0);
  z-index: 999;
  user-select: none;
}
.clearable-input input::-ms-clear,
.clearable-input input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

@media only screen and (min-width: $max-width) {
  .search-form-field-wrapper {
    min-width: 250px;
  }
}
