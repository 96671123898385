[class^="bss-icon-"], [class*=" bss-icon-"], .ico-bg:before , .date-ico-bg:before, .submenu-title:before, .button.icon:before {
    background: none!important;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoonbss' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: $base-margin*0.5;
    position: relative;
  }
  [class^="bss-icon-"], [class*=" bss-icon-"] {
    top: 1px;
    &.large:before {
      font-size: $base-font-size+2;
    }
    &.larger:before {
      font-size: $base-font-size*1.5;
    }
    &.white:before {
      color: $white;
    }
  }
  .ico-bg,
  .ico-bg * {
    font-family: $font!important;
    -webkit-font-smoothing: unset;
    -moz-osx-font-smoothing: unset;
  }
  /* Menu and general */
  .bss-icon-menu:before {
    content: "\e918";
  }
  .bss-icon-close:before, .menu-showing .bss-icon-menu:before, .search-showing .bss-icon-menu:before {
    content: "\ea0d";
  }
  .bss-icon-ads:before {
    content: "\e915";
  }
  .bss-icon-bookmark:before {
    content: "\e916";
  }
  .bss-icon-profile:before {
    content: "\e917";
  }
  .bss-icon-user-fill:before {
    content: "\e971";
  } /* Filled */
  .bss-icon-help-circle:before {
    content: "\e91a";
  }
  /* Chevrons */
  .bss-icon-chevron-thin-down:before {
    content: "\e903";
  }
  .bss-icon-arrow-right:before {
    content: "\e903";
    transform: rotate(-90deg);
    display: inline-block;
  }
  .bss-icon-arrow-left:before {
    content: "\e903";
    transform: rotate(90deg);
    display: inline-block;
  }
  .bss-icon-chevron-down:before {
    content: "\e905";
  }
  .bss-icon-arrow-up:before {
    content: "\e908";
  }
  .bss-icon-arrow:before {
    content: "\e909";
  }
  /* Search */
  .bss-icon-eye-glass:before {
    content: "\e90c";
  }
  .bss-icon-save-search:before, .bss-icon-saved-search:before {
    content: "\e914";
  }
  .bss-icon-calendar:before {
    content: "\e953";
  }
  .bss-icon-tag:before {
    content: "\e91b";
  }
  .bss-icon-search:before {
    content: "\e91c";
  }
  /* Shortlist */
  .bss-icon-star:before {
    content: "\e90a";
  }
  .bss-icon-star-fill:before {
    content: "\e90b";
  }
  .bss-icon-heart:before {
    content: "\e921";
  }
  /* Filters */
  .bss-icon-refine:before {
    content: "\e910";
  }
  .bss-icon-sort:before {
    content: "\e911";
  }
  /* Real Estate */
  .bss-icon-car:before {
    content: "\e923";
  }
  .bss-icon-bed:before {
    content: "\e901";
  }
  .bss-icon-bath:before {
    content: "\e904";
  }
  /* Jobs */
  .bss-icon-briefcase:before {
    content: "\e9ae";
  }
  .bss-icon-cash:before {
    content: "\e906";
  }
  /* Motoring */
  .bss-icon-cogs:before {
    content: "\e995";
  }
  .bss-icon-meter:before {
    content: "\e9a6";
  }
  /* Categories */
  .bss-icon-buy-sell:before {
    content: "\e917";
  }
  .bss-icon-box:before {
    content: "\e922";
  }
  .bss-icon-car:before {
    content: "\e923";
  }
  .bss-icon-clothes:before {
    content: "\e92c";
  }
  .bss-icon-health:before {
    content: "\e924";
  }
  .bss-icon-house:before,
  .bss-icon-home:before {
    content: "\e925";
  }
  .bss-icon-jobs:before {
    content: "\e926";
  }
  .bss-icon-notices:before, .button.notice:before {
    content: "\e927";
  }
  .bss-icon-personals:before, .bss-icon-adult-services:before {
    content: "\e928";
  }
  .bss-icon-pets:before {
    content: "\e929";
  }
  .bss-icon-trade:before {
    content: "\e92a";
  }
  .bss-icon-travel:before {
    content: "\e92b";
  }
  /* Social Media */
  .bss-icon-twitter:before {
    content: "\e912";
  }
  .bss-icon-facebook:before {
    content: "\e907";
  } /* Facebook circle icon */
  .bss-icon-fb:before {
    content: "\e90d";
  } /* FB filled logo */
  .bss-icon-facebook-footer:before {
    content: "\e919"; /* Outline f */
  }
  .bss-icon-instagram:before {
    content: "\ea92";
  }
  .bss-icon-mail-share:before {
    content: "\e90e";
  }
  /* Contact */
  .bss-icon-user:before {
    content: "\e920";
  }
  .bss-icon-email:before, .bss-icon-mail:before, .button.mail:before {
    content: "\e902";
    position: relative;
    top: 2px;
  }
  .bss-icon-flag:before {
    content: "\e9cc";
  }
  .bss-icon-phone:before, .button.call:before {
    content: "\e942";
    position: relative;
    top: 1px;
  }
  .bss-icon-earth:before {
    content: "\e9ca";
  }
  .bss-icon-cart:before {
    content: "\e93a";
  }
  /* Accounts */
  .bss-icon-fb:before {
    content: "\e90d";
  }
  .bss-icon-edit:before {
    content: "\e913";
  }
  /* Maps */
  .bss-icon-location:before, .bss-icon-map-black:before {
    content: "\e947";
  }
  .bss-icon-show-map:before {
    content: "\e90f";
  }
  .bss-icon-map-pin:before {
    content: "\e91e";
  }
  .bss-icon-map:before {
    content: "\e91f";
  }
  .ico-bg:before {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .form-control.ico-bg:before {
    transform: translateY(-77%);
  }
  @media screen and (max-width: 453px) {
      .form-control.ico-bg:before {
        transform: translateY(-43%);
      }
  }
  .bss-icon-arrow-right.ico-bg:before {
    transform: rotate(-90deg) translateX(25%);
  }
