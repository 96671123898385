.main-header {
  background-color: $background-header;
  margin: 0;
  padding: 0;

  * {
    color: $quaternary;
  }

  i {
    font-size: ($base-font-size)+2;
  }

  .masthead-wrapper {
    width: 100%;
    height: 45px;
    background-color: $secondary-dark;

    .masthead {
      width: 100%;
      max-width: $max-width;
      height: 100%;
      margin: 0 auto;
      padding: 0 $base-padding*2 0 $base-padding*2;

      .my-profile-wrapper {
        position: relative;
        padding-left: $base-padding * 2;
        padding-right: $base-padding *2;
        margin-left: auto;
        height: 100%;
        border-left: 1px solid rgba(255, 255, 255, 0.15);
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          cursor: pointer;

          .my-profile-menu {
            pointer-events: auto;
            transform: scale(1);
          }
        }

        .my-profile {
          line-height: 1.88;

          .profile-greeting {
            width: 210px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .avatar {
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: $base-margin *0.5;
            }
          }

          .bss-icon-user {
            margin-right: 0;
            margin-left: 6px;
            padding-right: 0;
          }

          .profile-space {
            margin-right: 6px;
          }

          .authenticated-user-avatar {
            margin-left: 6px;
          }
        }

        .my-profile-menu {
          width: 258px;
          min-height: 78px;
          position: absolute;
          top: 45px;
          right: 0;
          padding: 0 calc($base-padding + 2px);
          background-color: $secondary-dark;
          pointer-events: none;
          @include overlay(9999999);
          @include box-shadow(1px, 3px, 1px, rgba(72, 117, 135, .5));
          @include drop-shadow(0, 0, 1px, rgba(72, 117, 135, .5));
          border-bottom-left-radius: $base-radius;
          border-bottom-right-radius: $base-radius;
          transition: all .1s ease-out;
          transform-origin: top right;
          transform: scale(0);

          display: flex;

          ul {
            width: 100%;
            margin-top: 0;
            padding: 0;

            li {
              a {
                border-radius: $base-radius;
                margin: $base-margin*0.5 0;
                padding: $base-padding;

                display: flex;
                justify-content: center;

                i {
                  display: inline-block;
                  margin-bottom: unset;
                  color: $white;
                }

                @include hover;
              }

              &:last-of-type {
                border-bottom: none;
              }
            }

            .button {
              background: transparent;
              border: 1px solid $white;
              color: $white;
              margin-top: $base-margin;
              @include hover;
              width: 100%;
              margin: auto;
              white-space: nowrap;
            }
          }

          .button {
            width: 105px;
            line-height: 1;
            margin: $base-margin*0.5;
            padding-left: $base-padding;
            padding-right: $base-padding;
            font-weight: 500;
          }
        }
      }
    }
  }

  .content-wrapper {
    @include transition(top, .3s);
    height: 110px;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 $base-padding*2 0 $base-padding*2;

    .logo {
      margin-right: auto;
      height: 50px;

      a {
        .site-logo {
          height: 100%;
          width: auto;
        }
      }
    }

    .call-to-action {
      width: auto;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $viewport-s) {
  .main-header {
    .masthead-wrapper {
      height: 38px;

      .masthead .my-profile-wrapper {
        border-right: none;
        height: ($base-margin*2);
        padding-left: $base-padding;
        padding-right: 0;

        .my-profile .profile-greeting {
          width: 85px;
        }

        .my-profile-menu {
          width: 178px;
          top: 30px;
        }

        .bss-icon-user {
          margin: 0;
        }

        .hide-for-viewport-s {
          display: none;
        }
      }
    }

    .content-wrapper {
      height: 88px;

      .logo {
        height: 40px;
        margin-top: 1px;

        a .site-logo {
          margin-right: auto;
        }
      }
    }

    .call-to-action {

      a.button.primary {
        font-size: $base-font-size - 3;
        margin: 0;
        padding: $base-padding - 2px;
      }
    }
  }
}