// Refinement Filter
// When no prices are available, we will adjust the first row on the desktop
// grid to 2 column from 3?
// @TODO: Consider moving .no-price to a better element to target with WL or
// create a mixin for properly escaping the WL wrapper
//
// @HACK: This actually doesn't even fully address the actual problem since you
// can have have a price filter but not a location filter, leaving you with two
// facets at 1/3 width.
// Example: https://www.buysearchsell.com.au/all-locations/buy-sell/bbq-accessories/
.bss.no-price #refinement-search .refinement-filter .refinement-form form {
  @media screen and (min-width: ($viewport-s)+1) {
    .refinement-form__wrapper {
      .col-grid-layout:nth-child(1), .col-grid-layout:nth-child(2) {
        flex: 1 0 50%;
      }
      .col-grid-layout:nth-child(2), .col-grid-layout:nth-child(5), .col-grid-layout:nth-child(8) {
        padding-right: 0;
      }
      .col-grid-layout:nth-child(3), .col-grid-layout:nth-child(6), .col-grid-layout:nth-child(9) {
        padding-right: $base-padding * 0.25;
      }
    }
  }
}
