@use "sass:math";

.banner-centered-text {
  position: absolute;

  .banner-wrapper {
    max-width: $max-width;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 820px;
    max-height: 332px;
    height: 332px;
    position: absolute;
    left: 50%;
    top: 96px;
    transform: translate(-50%, 25%);
  }
}

.banner-text-left {
  height: 370px;

  .banner-wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 $base-margin*2;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    .message, .message span {
      font-family: $font-bold;
      font-size: 42px;
      font-weight: bold;
      line-height: 1.1;
      span {
        color: $secondary;
      }
    }

    .message {
      width: 500px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: $base-font-size*4;
      margin-left: 0;
    }

    img,
    a {
      width: 520px;
      max-height: 359px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      max-width: none;
    }
  }
}

// @HACK: This is only for MYT but is being used in BSS
.mytributes .banner-404, #tributes .banner-404{
  background: no-repeat center / cover url('./images/404.jpg');
}

.banner-404 {
  width: 100%;
  min-height: 580px;

  h2 {
    padding: $base-margin 0;
  }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    text-align: center;
  }

  // @NOTE: BSS only
  img {
    display: block;
    max-width: 820px;
    max-height: 332px;
    height: 332px;
    @media screen and (max-width: $viewport-s){
      height: 130px;
    }
  }
}

.banner-select-state {
  $banner-select-max-width: math.div($max-width, 3);
  padding: $base-padding*2 0;

  .select-state-image, .page-title, form, select {
    display: block;
    max-width: $banner-select-max-width;
    margin: $base-padding * 1.5 auto;
    text-align: center;
  }

  .page-title {
    font-size: $base-font-size * 2;
    background-color: initial;
  }

  button {
    margin: 0 $base-margin * 0.5;
    padding: $base-padding * 0.5;
    font-size: $base-font-size - 2;
  }

  select {
    max-width: $banner-select-max-width - $base-padding;
    background-color: white;
    padding: $base-padding * 0.5;
    border: 1px solid $grey-medium;
    border-radius: 5px;

    &.error {
      color: $error-colour;
      border-color: $error-colour;
    }
  }
}

@media screen and (min-width: $viewport-s) and (max-width: $viewport-m) {
  .banner-text-left {

    .banner-wrapper {
      img,
      a {
        width: 450px;
      }

	  .message,
	  .message * {
	  font-size: 36px;
	  line-height: 48px;
	  width: 300px;
	  }
    }
  }
  .banner-centered-text {
    img {
      max-width: 450px;
      top: 48px;
    }
  }
}


@media screen and (max-width: $viewport-s) {
  .banner-centered-text {
    height: 242px;
    top: 26px;

    .banner-wrapper {
      margin: 0 $base-margin;

      .message {
        width: calc(100% - #{$base-margin*2});
        margin: auto;  /* Magic! */
        font-size: 35px;
        span {
          font-size: 35px;
        }
      }
    }

    img {
      max-height: 113px;
      max-width: 278px;
      height: 113px;
      top: 0;
    }
  }

  .banner-select-state {
    .page-title {
      font-size: $base-font-size * 1.5;
    }
  }

  .banner-centered-text {
    .banner-wrapper img {
      transform: translate(-50%, 50%);
    }
  }

  .banner-404 {
    min-height: 300px;
    width: 100%

    img {
      max-height: 163px;
      max-width: 320px;
      height: 163px;
    }
  }

  .banner-text-left {
    height: 406px;
    .banner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;

      .message {
        line-height: $base-font-size*1.5;
        font-size: $base-font-size*1.4;
        margin: $base-margin 0 0;
        text-align: center;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;

        span {
          font-size: $base-font-size*1.4;
        }
      }

      img,
      a {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
      }
      img {
        top: 60px;
        width: auto;
        height: auto;
        margin: 0 auto;
        padding-bottom: 60px;
      }
    }
  }
}

@media screen and (max-width: 453px) {
  .banner-text-left {
    height: 305px;
  }
}
