// @TODO: Break into an object
.notice-board-filter {
  width: 100%;
  justify-content: space-between;
  flex-flow: row;
  margin: $base-margin 0;

  * {
    @include fonts(normal, ($base-font-size)-2);
    line-height: $base-font-size + 2;
  }

  .show-map,
  .hide-map,
  .sort,
  .results-no {
    padding-left: $base-margin * 1.5;
    padding-top: $base-margin*0.5;
    height: ($base-margin * 2)-6;
  }

  .results-no {
    position: relative;
    height: 32px;
    justify-content: flex-start;
    padding: 0;
    margin-left: $base-margin;
    width: calc(25% - #{$base-margin});
    &.ico-bg::before {
      padding-right: 0;
      right: $base-margin*0.5;
    }
    &.tributes-sort::before {
      right: $base-margin;
    }
    &.tributes-sort:last-of-type::before {
      right: 0;
    }
  }

  .show-map,
  .hide-map {
    line-height: 9px;
  }

  select {
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  > a {
    width: 25%;
  }
}

.notice-board {
  margin-bottom: $base-margin * 2;
  transform: translateZ(0); /*for older browsers*/
  will-change: transform;
  .notice-block-inner {
    width: 100%;
  }

  .notice-board-header {
    h1 {
      font-size: $base-font-size + 6;
      min-height: 37px; // to align with map button
      .badge {
        font-size: 55% !important;
      }
    }
  }

  .notice-board-description:not(:empty) {
    text-align: center;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    padding: $base-padding;
    margin: $base-margin 0;
  }

  .searchResultsMap-wrapper {
    border-radius: $base-radius;
    @include box-shadow();
    @include drop-shadow(0, 1px, 1px);
  }

  .searchResultsMap {
    border-radius: $base-radius;
    @include transition;
  }

  .mapArea.map-hidden .searchResultsMap {
    height: 0 !important;
  }

  /* notice-list-item */

  .notice-block {
    background: $white;
    // margin-top: $base-margin * 2; // why was this working? :o
    margin-bottom: $base-margin * 2;
    max-width: 100%;
    width: 100%;
    border-radius: $base-radius;
    @include drop-shadow();
    transition: color 0.1s ease-out 0s;
    * {
      color: $grey-dark;
    }

    a {
      font-size: inherit;
      color: inherit;
      &.location-link:hover *,
      &.classification-link:hover * {
        color: $primary;
      }
    }

    .primary a {
      color: $primary;

      &:hover {
        color: darken($primary, 10%);
      }
    }
    .moonbar {
      background-color: $grey-lightest;
      margin: (-$base-margin) (-$base-margin) $base-margin (-$base-margin);
      padding: $base-padding $base-padding 0 $base-padding;
      border-top-right-radius: $base-radius;
      a {
        @include fonts(normal, ($base-font-size)-5);
        line-height: 1;
        margin-top: -7px;
        margin-bottom: -4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(50% - #{$base-margin});
        &:hover {
          color: $primary-dark;
          * {
            color: $primary-dark !important;
          }
          i:before {
            color: $primary-dark !important;
          }
        }

        h3 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        [class^='bss-icon-'],
        [class*=' bss-icon-'] {
          position: relative;
          top: 4px;
          font-size: ($base-font-size)+2;
        }
        &:first-of-type {
          margin-right: $base-margin*0.5;
        }
        &:last-of-type {
          margin-left: $base-margin*0.5;
        }
        &:only-of-type {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    .listing-header,
    .listing-header *,
    .notice-no-title .listing-description p:first-child {
      font-family: $font-bold;
      font-weight: bold;
      font-size: $base-font-size + 4;
      line-height: $base-margin * 2;
      width: 100%;
      display: block;
      padding-bottom: $base-margin*0.5;
      transition: color 0.1s ease-out 0s;
    }

    .listing-header a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: calc(24px * 3);
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      &:hover {
        color: $black;
      }
    }

    .secondary-header {
      font-size: ($base-font-size)-2;
      margin-bottom: $base-margin * 2;
    }

    .notice-no-image .listing-header,
    .notice-no-title .listing-description p:first-child {
      border-bottom: 1px solid $grey-light;
      margin-bottom: $base-margin;
    }

    .call-to-action,
    .listing-description {
      @include fonts(normal, ($base-font-size)-2);
      line-height: $base-font-size + 3;
    }

    .listing-description {
      overflow: hidden;
      * {
        font-family: $font;
        font-size: ($base-font-size)-2;
        line-height: $base-font-size + 3;
        text-align: left;
        font-style: normal;
      }

      p {
        text-align: left !important;
      }
      p,
      ul {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      p {
        padding-bottom: 0;
      }
    }

    .call-to-action {
      margin-top: $base-margin;
      a {
        color: $primary;
      }
    }

    .notice-no-title .listing-description p:first-child {
      margin-top: 0;
      strong {
        display: inline;
        font-weight: normal;
      }
    }

    .notice-message {
      padding: $base-padding * 2 0 $base-margin * 2 $base-padding * 2;
      margin-right: $base-margin * 2;
      width: calc(55% - #{$base-margin});

      .as-seen-on {
        display: flex;
        align-items: center;
        height: 30px;
        span {
          display: flex;
          align-items: center;
          height: 30px;
          background-color: $primary;
          padding: 0 $base-padding;
          margin-right: $base-margin;
          font-size: ($base-font-size)-2;
          border-radius: $base-radius;
        }

        img {
          height: 30px;
          border-radius: $base-radius;
        }
      }

      .metadata {
        display: flex;
        padding-top: $base-padding;
        min-width: 75px;
        span {
          float: left;
          display: block;
          padding-right: $base-padding;
          font-weight: 100;
          strong {
            display: block;
          }
        }

        .listing-date {
          padding-right: 0;
          margin-bottom: 0;
          float: right;
        }
      }

      .primary {
        color: $primary;
      }

      .advertiser-logo {
        padding: 0; /* These styles are needed to reset tc styles */
        padding-left: $base-padding;
        margin: 0;
        border: none;
        @include overlay(9999); /* Avoid fade crossing over image */
        text-align: right;

        img {
          width: auto;
          max-width: 119px;
          max-height: 32px;
          margin-top: $base-margin*0.5;
        }
      }

      &.notice-no-image {
        padding: $base-padding * 2;
        margin-right: 0;
        width: 100%;
        .metadata {
          span {
            strong {
              display: inline;
            }
          }
        }
      }

      &.notice-no-logo {
        padding: $base-padding * 2 0 $base-padding * 2 $base-padding * 2;
      }

      &:not(.notice-no-logo) {
        .metadata {
          max-width: 100%;
        }
      }

      &:not(.notice-no-image) {
        .metadata {
          span {
            padding-right: 0;
          }

          .listing-date {
            float: none;
            clear: both;
          }
        }
      }
    }

    .notice-image {
      display: block;
      position: relative;
      transition: opacity 0.1s ease-out 0s;
      img {
        max-width: calc(100% - #{$base-margin * 2});
        max-height: calc(100% - #{$base-margin * 2});
        position: absolute;
        top: $base-margin * 2;
        left: $base-margin * 2;
        right: 0;
        bottom: $base-margin * 2;
        width: auto;
        height: auto;
        margin: auto;
        @include drop-shadow();
      }
    }

    .contact-bar {
      overflow: hidden;
      padding: $base-margin*0.5 0 0;
      margin: $base-margin*0.5 0 $base-margin 0;
      font-size: ($base-font-size)-4;
      width: 100%;

      * {
        color: $secondary-dark;
      }

      .button {
        width: calc(50% - #{$base-margin*0.5});
        float: left;
        font-size: ($base-font-size)-2;
        background: rgba($secondary, 0.1);
        border-radius: $base-radius;
        padding: $base-padding*0.5;
        margin-right: $base-margin;
        @include transition;
        @include hover($important: false);
        line-height: 28px;
        i {
          transform: scale(1.3);
          font-size: ($base-font-size)+2;
          padding-left: $base-padding*0.5;
        }

        &:last-child {
          margin-right: 0;
        }

        &:only-child,
        &.only-child {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    &.premium-plus,
    &.premium,
    &.enhanced {
      .notice-image {
        width: calc(45% - #{$base-margin});
      }
    }

    &.premium-plus {
      border: 1px solid $primary;
      background: $primary;
      border-radius: 3px;
      overflow: hidden;

      * {
        color: $grey-dark;
      }

      .notice-image {
        background-color: $white;
        img {
          left: $base-margin;
          right: $base-margin;
        }
      }
      .notice-message {
        .moonbar {
          background-color: $secondary-dark;
          a {
            &:hover {
              color: $secondary !important;
              * {
                color: $secondary !important;
                &:before {
                  color: $secondary !important;
                }
              }
            }
          }
          [class^='bss-icon-']:before,
          [class*=' bss-icon-']:before {
            color: $grey-dark;
          }
          color: $grey-dark;
        }
      }

      .call-to-action * {
        color: $grey-dark;
      }
    }

    &:hover {
      background: darken($white, 1%);
    }

    &.premium {
      background: $background-secondary;
      &:hover {
        background: darken($background-secondary, 2%);
      }
    }

    &.enhanced {
      border: 1px solid $grey-light;
    }

    &.standard {
      border: none;
      background-color: $background;
      &:hover {
        background: $white;
      }

      .notice-image {
        width: 33.33%;
      }

      .notice-message {
        padding: $base-padding * 2 0 $base-padding * 4 $base-padding * 2;
        width: 66.66%;

        .metadata {
          span {
            strong {
              display: inline;
            }
          }
        }
      }

      .listing-header {
        margin: $base-margin 0;
      }
    }

    &.premium-plus,
    &.premium,
    &.enhanced {
      .notice-message {
        &.notice-no-image {
          padding: $base-padding * 2;
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }

  .no-results-found {
    p {
      line-height: 1.3em;
      margin-top: 0.2em;
    }

    a {
      display: inline;
      text-decoration: none;
      color: $primary;
      font-family: $font-bold;
      font-weight: 500;
    }
  }
}

// Temp MYT fixes until ad overhaul (SCRUM-12398)
.tributes .notice-board .notice-block.premium {
  border: solid 1px $primary;
  .max-height-static {
    p {
      display: none;
    }
    p:first-of-type {
      display: block;
      max-height: 26px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tributes .notice-board .notice-block.standard .notice-message {
  width: 100%;
  padding: $base-padding;
  margin: 0;
  .listing-header {
    border-bottom: 1px solid $grey-light;
  }
}

////////////
// MOBILE //
////////////

@media screen and (max-width: $viewport-s) {
  .notice-board {
    margin-top: $base-margin * 2;
    .notice-board-filter {
      display: none;
    }

    .notice-block {
      height: auto;
      width: 100%;
      max-width: 100%;
      border: 0;
      .notice-board-header {
        margin-bottom: $base-margin * 2;
      }

      .notice-image {
        text-align: center;
        padding: 0;
        max-height: calc(415px + #{$base-margin * 2});
        overflow: hidden;
        order: 1;
        img {
          max-width: 100%;
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
          display: block;
          margin: 0 auto;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      h1,
      h1 * {
        @include fonts(normal, $base-font-size + 2);
      }

      .secondary-header {
        margin-bottom: $base-margin;
      }

      .listing-detail-icons,
      .secondary-header {
        margin-right: calc(119px + #{$base-margin});
      }

      .notice-no-logo .listing-detail-icons,
      .notice-no-logo .secondary-header {
        margin-right: 0;
      }

      &.premium-plus,
      &.premium,
      &.enhanced,
      &.standard {
        .notice-image,
        .notice-message {
          width: 100%;
          margin-right: 0;
        }

        .notice-message {
          padding: 0 $base-padding $base-padding * 4 $base-padding;
          width: 100%;
          .moonbar {
            border-top-right-radius: 0;
            a:first-of-type {
              width: calc(40% - #{$base-margin});
            }
            a:last-of-type {
              width: calc(60% - #{$base-margin});
            }
            a:only-of-type {
              width: 100%;
            }
          }
          .listing-header,
          .listing-header * {
            margin-bottom: 0;
            padding-bottom: 0;
          }

          .listing-description,
          .call-to-action {
            display: none;
          }

          &.notice-no-image {
            .listing-description,
            .call-to-action {
              display: block;
              margin-top: $base-margin;
            }
            .moonbar {
              margin-top: -($base-margin);
              border-top-left-radius: $base-radius;
              border-top-right-radius: $base-radius;
            }
          }

          &.notice-no-logo {
            padding: $base-padding;
            .listing-description {
              padding-bottom: 0;
            }
          }
        }
      }

      &.standard {
        border: 1px solid $grey-medium;
        .notice-message {
          .listing-header {
            margin-top: 0;
          }
        }
      }

      .location {
        height: $base-margin * 2;
        background-size: 12px 12px;
        padding-top: 0;
        margin-bottom: $base-margin;
        margin-top: $base-margin*0.5;
      }

      .listing-header,
      .date {
        font-size: $base-font-size + 2;
        line-height: $base-margin * 2;
      }

      .listing-header {
        margin: $base-margin 0;
      }

      .date {
        margin-top: $base-margin*0.5;
      }

      &.premium-plus,
      &.premium,
      &.enhanced,
      &.standard {
        .notice-message {
          .advertiser-logo img {
            width: 90px;
          }
        }
      }

      .notice-message {
        &:not(.notice-no-logo) {
          .metadata {
            padding-right: 0;
          }
        }
        .metadata {
          span {
            strong {
              display: inline;
            }
          }
        }
      }

      .contact-bar {
        padding-top: 0;
        .button {
          margin: 0;
          width: 50%;
          text-align: center;
          float: left;
          border-radius: 0;
          border-bottom-left-radius: $base-radius;
          i {
            color: $secondary;
          }

          &:last-of-type {
            border-left: 1px solid $grey-lighter;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: $base-radius;
          }

          &:last-of-type.mobile,
          &:only-child {
            width: 100%;
            border-bottom-left-radius: $base-radius;
            border-bottom-right-radius: $base-radius;
            border-left: none;
          }

          &:hover {
            background-color: $grey-lighter;
          }

          &:active {
            background-color: $grey-light;
          }
        }
      }
    }
  }

  .notice-board
    .notice-board-list:not(.real-estate):not(.motoring)
    .notice-image
    img {
    max-height: 200px;
  }

  .notice-board .notice-block.premium-plus .notice-image,
  .notice-board .notice-block.premium-plus .notice-message,
  .notice-board .notice-block.premium .notice-image {
    width: 100%;
    margin-right: 0;
    padding-top: $base-padding;
    padding-bottom: $base-padding;
  }
  .notice-board .notice-block.premium-plus .notice-image img {
    left: auto;
    right: auto;
  }
}

@media screen and (min-width: 349px) and (max-width: $viewport-s) {
  .notice-board-list:not(.real-estate),
  .notice-board-list:not(.motoring) {
    .notice-image {
      padding: $base-padding * 2 $base-padding * 2 0;
      width: auto;
      img {
        border-radius: $base-radius;
      }
    }
  }
}
@media screen and (min-width: 865px) and (max-width: $viewport-m) {
  .notice-board {
    .advertiser-logo img {
      width: 80px;
    }
  }
}
@media screen and (min-width: $viewport-s) and (max-width: 998px) {
  .button.button-sort i {
    display: none;
  }
}

@media screen and (min-width: $viewport-s) and (max-width: 917px) {
  .progressive-text {
    display: none;
  }
  cap {
    text-transform: capitalize;
  }
  .notice-board-filter {
    > a {
      width: 20%;
    }
    .results-no {
      width: calc(40% - #{$base-margin});
    }
  }
}

@media screen and (max-width: 415px) {
  .progressive-text {
    display: none;
  }
  cap {
    text-transform: capitalize;
  }
}
