@each $header, $size in (h1: $base-font-size+14, h2: $base-font-size+12, h3: $base-font-size+10, h4: $base-font-size+8, h5: $base-font-size+4, h6: $base-font-size+2) {
  #{$header} {
    padding: 0;
    margin: 0;
    font-size: $size;
    font-weight: normal;
    * {
      font-size: $size;
    }

    .primary {
      color: $primary;
    }
  }
}

.primary {
  color: $primary;
}

a {
  text-decoration: none;
}

strong {
  font-family: $font-bold;
  font-weight: bold;
}

p {
  padding: 0;
  margin: 0;
  a, a * {
    color: $primary-dark;
    font-weight: 500;
    @include transition;
    &:hover {
      color: $primary;
    }
  }
}

.phone,
.email {
  @include fonts(300, ($base-font-size)-4);
  margin-right: $base-margin;

    i {
    @include fonts(700, ($base-font-size)-4);
    margin-right: 7px;
    //vertical-align: bottom;
  }
}

.title,
.title * {
  @include fonts(normal, 24px);
  margin-bottom: $base-margin;
}

.listing-details {
  .listing-description {
    p{
      @include fonts(400, ($base-font-size)-2);
      line-height: 18px;
    }
  }
}

@media screen and (max-width: $viewport-s) {
  .phone,
  .email {
    @include fonts(normal, ($base-font-size)-2);
    text-align: center;
    margin-bottom: $base-margin*2;
    margin-right: 0;
  }

  .mobile .phone {
    display: block;
  }

  .title {
    @include fonts(normal, 18px);
    line-height: $base-margin*2;
    margin-top: $base-margin;
    margin-bottom: $base-margin;
  }
}
