.top-deck{
  contain: layout;
  padding-top: $base-padding * 2;
  padding-bottom: $base-padding * 2;
  contain: layout;

  .deck-heading {
    position: relative;
    > h1, > h2, > h3 {
      font: 600 32px/40px $font;
      margin-bottom: $base-padding;
    }
    a.glider__view-all {
      position: absolute;
      right: 0;
      bottom: $base-padding;
      top: unset;
      margin-right: 0;
    }
  }

  .glider__view-all:empty{
    display: none;
  }

  .pagination-dots{display:none;}

  // Mobile Only
  @media screen and (max-width: $viewport-s){
    > h1, > h2, > h3{ margin-bottom: $base-padding * 0.5; }
    .pagination-dots{display:flex;}
    .top-deck-inner{
      display: flex;
      box-sizing: content-box;
      scroll-behavior: smooth;
      // Break out of content wrapper
      width: calc(100% + 24px);
      margin-left: -20px;
      padding: 6px 0 $base-padding 20px;
      overflow-y: auto;
      overflow-x: scroll;
      scrollbar-width:none; // FF
      &::-webkit-scrollbar{display:none;} // Blink/Webkit

      // @HACK: Chrome horizontal scrolling doesn't like how we have adjusted our viewport
      // This bar will push off the last cards margin to create the space we lost above
      &:after{
        display: block;
        content: '|';
        visibility: hidden;
        user-select: none;
        speak: none;
      }

      .card{
        flex-shrink: 0;
        margin-right: $base-padding;
        .card-buttons, .button { display:none; }

        // @NOTE: The min-height here is tied to the above width minus 1 percent
        // percent to allow for minimal cropping
        width: 35%;
        .card-img.cover img { min-height: 34vw; }
      }
    }
    @media (orientation:landscape) {
      .top-deck-inner{
        .card{
          width: 27.5%;
        }
      }
    }
  }
  // Tablet and up
  @media screen and (min-width: $viewport-s + 1){
    display: grid;
    padding: ($base-padding * 4) 0;
    margin: 0 $base-padding;

    &>.sidebar-area{
      grid-area: calc(1 * 0.5 / 4);
    }

    .top-deck-inner{
      display: grid;
      grid-column: 1;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 18px;

      // Setting min widths on cards with a grid resolves issues with one any one card
      // growing larger than the rest, eg: a very long word with no breaks
      .card{ min-width: 100%; }
    }
    .sidebar-area, .ad-area{
      width: 312px;
      grid-column: 4;
      // @NOTE: This value is overriden by JS to impliment "1 / $rows + 2"
      grid-row: 1/4;
      &.left{
        grid-column: 1;
      }
    }
    // MYT takes up a bit more space for its sidebar/recent notices
    .sidebar-area{
      width: 336px;
      margin-left: $base-padding * 1.5;
    }
  }

  @media screen and (min-width: 1020px){
    margin: 0 auto;
  }

}

// @HACK: Obits with a sidebar needs to be reduced down to 3 wide.
.pane-myt_obituaries .top-deck-inner{
  grid-template-columns: repeat(3, 1fr);
}

// @HACK: could maybe use a "with-ads" mixin with the @at-root
@at-root{
  body:not(.adcore) .ad-area{
    display: none;
  }
  @media screen and (min-width: $viewport-s + 1){
    body.adcore{
      // When ads are enabled, adjust the decks to better display
      .top-deck-inner{
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
      }
      .pane-mytributes_featured .top-deck-inner{
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .ad-block.ad-mrec[data-google-query-id], .ad-area{
        // grid-area: sidebar;
        margin: 0 auto auto auto;
      }
    }
  }
}
