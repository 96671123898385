.main-header {
  margin-bottom: 0;

  .mobile-dropdown-menu-item {
    padding-bottom: $base-padding*2;
  }
}

@media screen and (max-width: $viewport-s) {
  .main-header {
    .cta-header {
      display: none;
    }
  }
}

@media screen and (max-width: 415px) {
  .main-header {
    .mobile-dropdown-menu-item {
      .flex.sm-100 {
        .sm-47 {
          margin-bottom: $base-margin;
          width: 100% !important;
        }
      }
    }
  }
}
